import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	TextInput,
	ActivityIndicator,
	Dimensions,
	ScrollView,
	Modal,
	Alert,
} from 'react-native';
import Lottie from 'react-lottie';
import moment from 'moment';
import Fade from 'react-reveal/Fade';
import ImageUploader from './ImageUploader';
import PSDUploader from './PSDUploader';
import Font from 'react-font';

import { encode, decode } from 'html-entities';
import { CSVLink, CSVDownload } from 'react-csv';
import TextSelector from './components//text-selection-react/';
import MathFormula from './MathFormula';

import CSVUploader from './CSVUploader';
import CSVDragnDrop from './CSVDrag';

import { APP_COLOURS } from '../APP_VARS';
import {
	api_get_all_books,
	api_update_ebook,
	api_get_book_content,
	api_delete_book_content,
	api_create_ebook,
	api_book_line_uploader,
	api_initiate_test,
	api_get_all_tests_content,
	api_delete_test,
	api_create_test_contents,
	api_delete_line_contents,
	api_remove_line_content_chapter,
} from '../Api';

import logo from './../../assets/images/logo.png';
import downloadbook from './../../assets/images/downloadbook.png';
import tick from './../../assets/images/tick.png';
import delete_ from './../../assets/images/delete.png';

import up_arrow from './../../assets/images/up.png';
import down_arrow from './../../assets/images/down.png';
import close from './../../assets/images/close.png';
import pngUploader from './../../assets/images/pngUploader.png';
import psdUploader from './../../assets/images/psdUploader.png';
import addQuestions from './../../assets/images/addQuestions.png';
import exceluploader from './../../assets/images/exceluploader.png';
import tetriceLogo from './../../assets/images/logoTetrice.png';
import previewBook from './../../assets/images/previewBook.png';
import fetchingContent from './../../assets/images/fetchingContent.png';
import * as animationData from '../../assets/lottie/lf30_editor_t3bgbljb.json';

import SimpleEditor from './DraftJsSimple';
import DevicePreviewer from './DevicePreviewer';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

function currencyFormat(num) {
	return 'R ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function uuidv4() {
	return 'xxxxxxx'.replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function replaceAllText(rep, str, with_) {
	let result = str.replaceAll(rep, with_);
	return result;
}

function isNumeric(num) {
	return !isNaN(num);
}

// function replaceSomeText(rep, str, with_) {
// 	let result = str.replace(rep, with_);
// 	return result;
// }

function removeHTML(str) {
	if (str !== '' && typeof str != 'undefined') {
		var tmp = document.createElement('DIV');
		tmp.innerHTML = str;
		return tmp.textContent || tmp.innerText || '';
	}
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -2,
			fontSizeModalArea: -2,
			books: [],
			bundles: [],

			screenWidth: screenWidth,
			screenHeight: screenHeight,

			email: '',
			auth_token: '',
			refresh_token: '',

			authenticating: true,
			validated: true,
			fetching: true,

			selectedBookId: '',
			categories: [],
			selectedCategory: '',
			selectedChapter: '',
			selectedChapterTitle: '',

			currentBook: {},
			changesMade: false,
			updatingSuccess: false,
			errorUpdatingBook: false,
			updating: false,

			book_tabs: 'overview',

			gettingBookContent: false,
			creatingEbook: false,
			ebookCreated: false,
			ebookDeleted: false,
			bookContent: [],

			uniqueChapters: [],
			downloadedbook: '',

			newChapter: {},

			newContent: '',
			newContentArray: [],

			addItem: false,
			ebookUpdated: false,

			changesMade: false,

			selectedTest: '',
			selectedTestTitle: '',

			createAssessment: false,
			newAssessment: {},
			creatingAssessment: false,

			loadingTestContent: false,
			testContent: [],

			explanationType: '',

			addQuestionModal: false,
			newAssessmentQuestion: {
				question_no: 0,
				uuid_test: '',
				type: 'radio',
				question: '',
				total: 0,
				options: [],
				answer: 0,
			},
			selectedTestData: '',

			editAssessmentData: '',

			uploadCSV: false,
			devicePreview: false,
			contentPushedSuccessfully: false,

			deleteEbookConfirm: false,

			openBookLinker: false,
			currentBookLink: '',

			editLineNumber: false,

			addNewOption: false,
			addNewImage: -1,
		};
		this.DrafJSEditor = React.createRef();
		this.scrollView_ = React.createRef();
		this.currentTestScrollPosition = React.createRef();
	}

	async componentDidMount() {
		await this.validateSession();
		window.addEventListener('resize', this.resize.bind(this));
		this.resize();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem('user_data');
		let email = await sessionStorage.getItem('email');
		let auth_token = await sessionStorage.getItem('auth_token');
		let refresh_token = await sessionStorage.getItem('refresh_token');
		let books = await sessionStorage.getItem('books');
		let bundles = await sessionStorage.getItem('bundles');
		let book_categories = await sessionStorage.getItem('book_categories');

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			auth_token,
			refresh_token,
		});

		if (auth_token !== null && auth_token !== '') {
			// setTimeout(() => {

			if (books !== null && bundles !== null) {
				this.setState({
					books: JSON.parse(books),
					bundles: JSON.parse(bundles),
					categories: JSON.parse(book_categories),
					fetching: false,
				});
			} else {
				this.getAllBooks();
			}

			// }, 1000);
		} else {
			this.props.history.push('/');
			sessionStorage.clear();
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.resize.bind(this));
	}

	refreshBooks = () => {
		this.setState({
			books: [],
			bundles: [],

			email: '',
			auth_token: '',
			refresh_token: '',

			authenticating: true,
			validated: true,
			fetching: true,

			selectedBookId: '',
			categories: [],
			selectedCategory: '',
			selectedChapter: '',
			selectedChapterTitle: '',

			currentBook: {},
			changesMade: false,
			updatingSuccess: false,
			errorUpdatingBook: false,
			updating: false,

			book_tabs: 'overview',

			gettingBookContent: false,
			creatingEbook: false,
			ebookCreated: false,
			ebookDeleted: false,
			bookContent: [],

			uniqueChapters: [],
			downloadedbook: '',

			newChapter: {},

			newContent: {},
			newContentArray: [],

			addItem: false,
			ebookUpdated: false,

			changesMade: false,

			selectedTest: '',
			selectedTestTitle: '',

			createAssessment: false,
			newAssessment: {},
			creatingAssessment: false,

			loadingTestContent: false,
			testContent: [],

			addQuestionModal: false,
			newAssessmentQuestion: {
				question_no: 0,
				uuid_test: '',
				type: 'radio',
				question: '',
				total: 0,
				options: [],
				answer: 0,
			},
			selectedTestData: '',

			editAssessmentData: '',

			uploadCSV: false,
			openBookLinker: false,
			currentBookLink: '',
		});
		this.getAllBooks();
	};

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	getAllBooks = async () => {
		this.setState({
			fetching: true,
			currentBook: '',
			selectedBookId: '',
		});
		let url = api_get_all_books;

		let requestOptions = {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log('response', response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							// await console.log(
							// 	'=============================',
							// 	responseData,
							// );

							let books = await sessionStorage.setItem(
								'books',
								JSON.stringify(responseData.books),
							);

							let bundles = await sessionStorage.setItem(
								'bundles',
								JSON.stringify(responseData.bundles),
							);

							const uniqueCategory = [
								...new Set(
									responseData.books.map(
										(item) => item.category,
									),
								),
							];
							console.log(uniqueCategory);

							let book_categories = await sessionStorage.setItem(
								'book_categories',
								JSON.stringify(uniqueCategory),
							);

							this.setState({
								books: responseData.books,
								bundles: responseData.bundles,
								fetching: false,
								categories: uniqueCategory,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								checkingRVP: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert('User has not been found');
					this.setState({
						errorLogin: true,
						loading: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
				});
			});
	};

	saveChanges = async () => {
		let url = api_update_ebook;

		this.setState({
			errorUpdatingBook: false,
			loading: false,
			updating: true,
		});

		let obj = {
			...this.state.currentBook,
			uuid: this.state.currentBook.ebook_id,
		};

		console.log(this.state.currentBook.ebook_id);
		// return;

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'PUT',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		// console.log(raw, requestOptions);
		// return;
		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log('response', response);
				if (response.status == 200) {
					this.getAllBooks();

					this.setState({
						updating: false,
						updatingSuccess: true,
						createChapterModal: false,
						createChapter: false,
						selectedChapter: '',
					});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ errorUpdatingBook: true, loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
				});
			});
	};

	getBookContent = async () => {
		let url = api_get_book_content;

		await this.setState({
			gettingBookContent: true,
			bookContent: [],
			downloadedbook: '',
			selectedChapter: '',
			selectedChapterTitle: '',
			newContentArray: [],
			newContent: '',
		});

		let obj = {
			ebook_id: this.state.currentBook.ebook_id,
		};

		console.log(obj);

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log('response', response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log('responseData', responseData);
							// let book_categories = await sessionStorage.setItem(
							// 	'book_categories',
							// 	JSON.stringify(uniqueCategory),
							// );
							let data = responseData.data;
							const uniqueChapters = [];
							if (data.length > 0) {
								await [
									...new Set(
										data.map((item) =>
											parseFloat(item.chapter),
										),
									),
								];
								console.log('uniqueChapters', uniqueChapters);
							} else {
								data = [];
							}

							this.setState({
								gettingBookContent: false,
								bookContent: data,
								uniqueChapters: uniqueChapters,
								downloadedbook: this.state.currentBook.ebook_id,
							});

							// TEMP
							// this.setState({
							// 	gettingBookContent: false,
							// 	bookContent: [],
							// 	uniqueChapters: [],
							// 	downloadedbook: this.state.currentBook.ebook_id,
							// });
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								checkingRVP: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert('Book download error');
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						gettingBookContent: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
				});
			});
	};

	newBook = async (ebook_id) => {
		let books = this.state.books;
		let obj = {
			ebook_id: 'new',
			book_name: 'New book',
			about_book: 'Description',
			language: 'english',
			author: 'Avex',
			cover_photo_url: '0',
			category: this.state.selectedCategory,
			publisher: 'Avex',
			pages: 0,
			price: 0,
			chapters: [],
		};
		books.push(obj);

		this.setState({
			selectedBookId: 'new',
			currentBook: obj,
			books: books,
			book_tabs: 'edit',
		});
	};

	createdEbookModal = () => {
		this.getAllBooks();
		this.setState({
			ebookCreated: true,
		});
		setTimeout(() => {
			this.setState({
				ebookCreated: false,
			});
		}, 3000);
	};

	updatedEbookModal = async () => {
		let selectedChapter_temp = this.state.selectedChapter;
		let selectedChapterTitle_temp = this.state.selectedChapterTitle;
		await this.getBookContent();
		await this.setState({
			changesMade: false,
			ebookUpdated: true,
			updating: false,
			loading: false,
		});
		await setTimeout(() => {
			this.setState({
				ebookUpdated: false,
				selectedChapter: selectedChapter_temp,
				selectedChapterTitle: selectedChapterTitle_temp,
			});
		}, 2000);
	};

	createEbook = async () => {
		let url = api_create_ebook;

		this.setState({
			loading: true,
		});

		let obj = {
			...this.state.currentBook,
		};

		// console.log(obj);
		// return;

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log('response', response);
				if (response.status == 200) {
					this.createdEbookModal();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ errorUpdatingBook: true, loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
				});
			});
	};

	deleteBook = async () => {
		let url = api_delete_book_content;

		this.setState({
			errorUpdatingBook: false,
			loading: false,
			updating: true,
		});

		let obj = {
			ebook_id: this.state.currentBook.ebook_id,
		};

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'DELETE',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log('response', response);
				if (response.status == 200) {
					this.setState({
						updating: false,
						updatingSuccess: true,
						deleteEbookConfirm: false,
						deleteEbookModal: false,
					});

					this.ebookDeletedModal();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ errorUpdatingBook: true, loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
				});
			});
	};

	deleteChapterAndContent = async () => {
		let url = api_remove_line_content_chapter;

		this.setState({
			loading: true,
			updating: true,
		});

		let obj = {
			chapter_id: this.state.selectedChapter,
			ebook_id: this.state.currentBook.ebook_id,
		};

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'DELETE',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then(async (response) => {
				// console.log("response.status", response.status);
				console.log('response', response);
				if (response.status == 200) {
					this.deleteChapterInEBookChapters();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ errorUpdatingBook: true, loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
				});
			});
	};

	deleteChapterInEBookChapters = async () => {
		// The reason for this is that Ebooks have an overview of chapters which is separate from the content which is another table
		let selectedBookId = this.state.selectedBookId;
		let book_tabs = this.state.book_tabs;
		let chapters = await this.state.currentBook.chapters;
		let copyContent = [...this.state.bookContent];
		const bookContentFiltered = await copyContent.filter(
			(d) => parseInt(d.chapter) !== this.state.selectedChapter,
		);

		// console.log(bookContentFiltered);

		// return;

		const push = await chapters.filter(
			(d_) => d_.chapter_num !== this.state.selectedChapter,
		);
		const sortNumbers = await push.sort(
			(a, b) => b.chapter_num < a.chapter_num,
		);
		let newArray = [];
		let lineNumberCount = 1;
		for (const item of sortNumbers) {
			item.chapter_num = lineNumberCount;
			await newArray.push(item);
			await lineNumberCount++;
		}

		// console.log(selectedBookId, push);
		// return;

		//
		//
		//  MOVE CONTENT CHAPTERS UP ONE SPACE
		let chapterCount = this.state.selectedChapter;
		let adjustedBookContent = [];
		for (const ch of bookContentFiltered) {
			// Move every chapter thats above the current chapter down one space
			if (ch.chapter >= chapterCount) {
				ch.chapter = parseInt(ch.chapter) - 1;
			}
			await adjustedBookContent.push(ch);
		}

		// console.log(adjustedBookContent);
		// return;
		//
		//
		//

		await this.setState((prevState) => ({
			currentBook: {
				...prevState.currentBook,
				chapters: newArray,
			},

			deleteChapterConfirm: false,
			deleteChapterModal: false,
		}));

		let currentBook = this.state.currentBook;

		await this.saveChanges();

		await this.setState({
			selectedBookId: selectedBookId,
			downloadedbook: selectedBookId,
			book_tabs: book_tabs,
			currentBook: currentBook,
			bookContent: adjustedBookContent,
			changesMade: false,
		});
		await this.updateBookContent();
	};

	ebookDeletedModal = () => {
		this.getAllBooks();
		this.setState({
			ebookDeleted: true,
		});
		setTimeout(() => {
			this.setState({
				ebookDeleted: false,
			});
		}, 3000);
	};

	//
	//
	//
	updateBookContent = async () => {
		let url = api_book_line_uploader;

		await this.setState({
			loading: true,
			updating: true,
		});

		// await console.log(this.state.bookContent);

		let raw = await JSON.stringify(this.state.bookContent);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		// console.log(url, requestOptions);
		// return;

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log(response);
				// response
				// 	.json()
				// 	.then(async (responseData) => {
				// 		console.log('response', responseData);
				// 	})
				// 	.catch((error) => {
				// 		alert(error);
				// 	});

				// console.log('response', response);
				if (response.status == 200) {
					this.updatedEbookModal();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert('error updating book');
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						updating: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
					updating: false,
				});
			});
	};

	updateBookContentNoModal = async () => {
		let url = api_book_line_uploader;

		await this.setState({
			loading: true,
			updating: true,
		});

		let raw = await JSON.stringify(this.state.bookContent);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status == 200) {
					return;
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert('error updating book');
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						updating: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
					updating: false,
				});
			});
	};

	updateBookContentOrder = async (obj, direction) => {
		// let url = api_book_line_uploader;
		let bookContent = await this.state.bookContent.filter(
			(d) => obj.uuid !== d.uuid,
		);
		// await console.log('updateBookContentOrder', obj, direction);

		let newArray = [];

		if (direction == 'up') {
			let newLineNumber = (await parseFloat(obj.line_num)) - 1;
			if (newLineNumber < 1) {
				newLineNumber = 1;
			}
			await console.log(newLineNumber);
			obj.line_num = newLineNumber;
			//
			for (const item of bookContent) {
				if (parseFloat(item.line_num) == newLineNumber) {
					await console.log('shifting', item.line_num);
					item.line_num = (await parseFloat(item.line_num)) + 1;
				} else {
					item.line_num = await parseFloat(item.line_num);
				}

				await newArray.push(item);
			}
			//
		} else {
			let newLineNumber = (await parseFloat(obj.line_num)) + 1;
			if (newLineNumber > this.state.bookContent.length) {
				newLineNumber = this.state.bookContent.length;
			}
			await console.log(newLineNumber);
			obj.line_num = newLineNumber;
			//
			for (const item of bookContent) {
				if (parseFloat(item.line_num) == newLineNumber) {
					// await console.log('shifting', item.line_num);
					item.line_num = (await parseFloat(item.line_num)) - 1;
				} else {
					item.line_num = await parseFloat(item.line_num);
				}

				await newArray.push(item);
			}
		}

		let oldArray = await bookContent.filter((d) => obj.uuid !== d.uuid);
		let newContent = await [...newArray, obj];
		let newContentOrder = await newContent.sort(
			(a, b) => b.line_num < a.line_num,
		);
		await console.log('bookContent newContentOrder', newContentOrder);

		await this.setState({
			bookContent: newContentOrder,
			changesMade: true,
		});

		return;
	};

	moveTolineNumber = async (obj, toLine) => {
		// let url = api_book_line_uploader;
		let bookContent = await this.state.bookContent.filter(
			(d) => obj.uuid !== d.uuid,
		);

		await console.log('moveTolineNumber', obj.line_num, '->', toLine);

		let newArray = [];
	};

	//
	//

	pushnewContent = async () => {
		// this.setState({
		// 	isNewcontent: true,
		// });

		let newContentArray = this.state.newContentArray;
		let bookContent = this.state.bookContent;
		let max = this.state.bookContent.length;
		//
		//
		const uuid = await uuidv4();
		let newContent = this.state.newContent;
		newContent = {
			...newContent,
			uuid: uuid,
			line_num: max + 1,
			chapter: this.state.selectedChapter,
			page: '1',
			style: '',
			newLine: true,
			// ebook_id: this.state.currentBook.ebook_id,
			ebook_id: this.state.downloadedbook,
		};
		//
		//
		// bookContent.push(newContent);
		// newContentArray.push(newContent);
		//
		//
		// console.log('max', max);
		// console.log('newContent', newContent);
		// console.log('bookContent', bookContent);
		// console.log('newContentArray', newContentArray);

		// console.log(newContent);
		// return;

		await this.setState({
			newContent: '',
			changesMade: true,
			bookContent: [...this.state.bookContent, newContent],
		});

		// this.addcontentTextInput.clear();

		this.contentPushedSuccessfully();
	};

	pushEditedContent = async (obj) => {
		// await this.setState({
		// 	isNewcontent: true,
		// });

		let bookContent = await this.state.bookContent;
		let filteredArray = await bookContent.filter(
			(d) => obj.uuid !== d.uuid,
		);

		// console.log('content obj', obj);
		// return;

		let updatedContent = [...filteredArray, obj];
		let newContentOrder = await updatedContent.sort(
			(a, b) => parseFloat(b.line_num) < parseFloat(a.line_num),
		);

		await this.setState({
			editItem: false,
			changesMade: true,
			newContent: '',
			bookContent: newContentOrder,
		});
	};

	contentPushedSuccessfully = () => {
		this.DrafJSEditor.current.clearEditorField();
		this.setState({
			contentPushedSuccessfully: true,
		});
		setTimeout(() => {
			this.setState({
				contentPushedSuccessfully: false,
			});
		}, 1000);
	};

	deleteBookLineContent = async (data) => {
		const update = await this.updateBookContentNoModal();

		const deleteLineContent = await this.deleteLineContent(data, (bool) => {
			if (bool) {
				this.changeLinesAfterDelete(data, () => {
					this.updateBookContent();
				});
			}
		});
	};

	deleteLineContent = async (data, next) => {
		let url = api_delete_line_contents;

		let obj = {
			uuid: data.uuid,
			ebook_id: data.ebook_id,
		};

		await this.setState({
			loading: true,
			updating: true,
		});

		let raw = await JSON.stringify(obj);

		let requestOptions = {
			method: 'DELETE',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		// console.log(url, requestOptions);
		// return;

		await fetch(url, requestOptions)
			.then((response) => {
				console.log('response', response);
				if (response.status == 200) {
					next(true);
				} else if (response.status == 401) {
					return next(false);
					this.props.logOut();
				} else {
					alert('error deleting item');
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						updating: false,
					});
					next(false);
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
					updating: false,
				});
				next(false);
			});
	};

	changeLinesAfterDelete = async (data, next) => {
		let obj = {
			uuid: data.uuid,
			ebook_id: data.ebook_id,
		};

		console.log(obj);

		const bookContent = await [...this.state.bookContent];
		let filteredArray = await bookContent.filter(
			(d) => data.uuid !== d.uuid,
		);

		let updatedContent = [...filteredArray];
		let newContentOrder = await updatedContent.sort(
			(a, b) => parseFloat(b.line_num) < parseFloat(a.line_num),
		);

		let newArray = [];
		let lineNumberCount = 1;
		for (const item of newContentOrder) {
			item.line_num = lineNumberCount;
			await newArray.push(item);
			await lineNumberCount++;
		}

		await console.log('newArray', newArray);
		const updatesstate = await this.setState({
			bookContent: newContentOrder,
			editItem: false,
		});

		next();
	};

	RemoveLineandRearrange = () => {};

	// shuffleArray(array) {
	// 	let i = array.length - 1;
	// 	for (; i > 0; i--) {
	// 		const j = Math.floor(Math.random() * (i + 1));
	// 		const temp = array[i];
	// 		array[i] = array[j];
	// 		array[j] = temp;
	// 	}
	// 	return array;
	// }

	//
	//
	//

	// base64 = () => {
	// 	function getBase64(file) {
	// 		return new Promise((resolve, reject) => {
	// 			const reader = new FileReader();
	// 			reader.readAsDataURL(file);
	// 			reader.onload = () => resolve(reader.result);
	// 			reader.onerror = (error) => reject(error);
	// 		});
	// 	}

	// 	let file = document.querySelector('#files > input[type="file"]')
	// 		.files[0];
	// 	getBase64(file).then((data) => console.log(data));
	// };
	uploadImage = () => {};

	editItem = (obj) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
							padding: 10,
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										editItem: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 15,
								height: '100%',
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: 'row',
								}}>
								<View style={{ flex: 1 }}>
									<View
										style={{
											flexDirection: 'row',
											padding: 2,
											alignItems: 'center',
											justifyContent: 'space-between',
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 +
													this.state
														.fontSizeModalArea,
												fontFamily: 'Avenir Next',
												margin: 5,
												paddingLeft: 10,
												fontWeight: '700',
											}}>
											Edit line:{' '}
											{this.state.editItemsData.line_num}
										</Text>
										<View
											style={{
												flexDirection: 'row',
												padding: 2,
												alignItems: 'center',
											}}>
											<TouchableOpacity
												onPress={() =>
													this.setState({
														fontSizeModalArea:
															this.state
																.fontSizeModalArea -
															1,
													})
												}>
												<View
													style={{
														width:
															20 +
															this.state
																.fontSizeModalArea,
														height:
															20 +
															this.state
																.fontSizeModalArea,
														backgroundColor:
															APP_COLOURS.TEXTINPUT,
														// padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
													}}>
													<Text
														style={{
															color:
																APP_COLOURS.WHITE,
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
														}}>
														-
													</Text>
												</View>
											</TouchableOpacity>

											<View
												style={{
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
												}}>
												<Text
													style={{
														color:
															APP_COLOURS.TEXTINPUT,
														fontSize:
															17 +
															this.state
																.fontSizeModalArea,
														fontWeight: '500',
													}}>
													Aa
												</Text>
											</View>
											<TouchableOpacity
												onPress={() =>
													this.setState({
														fontSizeModalArea:
															this.state
																.fontSizeModalArea +
															1,
													})
												}>
												<View
													style={{
														width:
															20 +
															this.state
																.fontSizeModalArea,
														height:
															20 +
															this.state
																.fontSizeModalArea,
														backgroundColor:
															APP_COLOURS.TEXTINPUT,
														// padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
													}}>
													<Text
														style={{
															color:
																APP_COLOURS.WHITE,
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
														}}>
														+
													</Text>
												</View>
											</TouchableOpacity>
										</View>

										{this.state.editLineNumber ? (
											<View
												style={{
													flexDirection: 'row',
													padding: 2,
													alignItems: 'center',
												}}>
												<TouchableOpacity
													onPress={() =>
														this.updateBookContentOrder(
															obj,
															'up',
														)
													}>
													<Image
														source={up_arrow}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																'contain',
														}}
													/>
												</TouchableOpacity>

												<View
													style={{
														padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
													}}>
													<Text
														style={{
															color:
																APP_COLOURS.TEXTINPUT,
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '500',
														}}>
														Line:{' '}
														{
															this.state
																.editItemsData
																.line_num
														}
													</Text>
												</View>
												<TouchableOpacity
													onPress={() =>
														this.updateBookContentOrder(
															obj,
															'down',
														)
													}>
													<Image
														source={down_arrow}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																'contain',
														}}
													/>
												</TouchableOpacity>
											</View>
										) : (
											<View
												style={{
													flexDirection: 'row',
													padding: 2,
													alignItems: 'center',
												}}>
												<TouchableOpacity
													onPress={() =>
														this.setState({
															editLineNumber: true,
														})
													}>
													<View
														style={{
															padding: 9,
															justifyContent:
																'center',
															alignItems:
																'center',
															borderRadius: 10,
														}}>
														<Text
															style={{
																color:
																	APP_COLOURS.TEXTINPUT,
																fontSize:
																	17 +
																	this.state
																		.fontSizeModalArea,
																fontWeight:
																	'500',
															}}>
															Edit line number
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										)}

										<View
											style={{
												flexDirection: 'row',
												padding: 2,
												alignItems: 'center',
											}}>
											<TouchableOpacity
												onPress={() =>
													this.deleteBookLineContent(
														this.state
															.editItemsData,
													)
												}>
												<View
													style={{
														backgroundColor:
															APP_COLOURS.RED,
														padding: 4,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 5,
													}}>
													<Text
														style={{
															color:
																APP_COLOURS.WHITE,
															fontSize:
																11 +
																this.state
																	.fontSizeModalArea,
														}}>
														Delete Line
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>

									<View
										style={{
											// width: '100%',
											height: 60,
											borderRadius: 5,
											borderWidth: 0.3,
											borderColor: '#C5C5C5',
										}}>
										<ScrollView
											horizontal
											showsHorizontalScrollIndicator={
												true
											}>
											{[
												'H1',
												'H2',
												'H3',
												'P',

												'UL',
												'OL',
												'LIST_P',
												'IMG',
												'IMG_TAG',
												'MATH',
												'OPEN_BOOK',
												'CLOSED_BOOK',
											].map((d, i) => (
												<TouchableOpacity
													key={i}
													onPress={() =>
														this.setState(
															(prevState) => ({
																editItemsData: {
																	...prevState.editItemsData,
																	object: d,
																},
																changeContentMade: true,
															}),
														)
													}
													style={{
														width: 'auto',
														padding: 10,
														margin: 3,
														borderRadius: 5,
														borderWidth: 0.3,
														backgroundColor:
															this.state
																.editItemsData
																.object == d
																? APP_COLOURS.BLUE
																: APP_COLOURS.OFFWHITE,
														alignItems: 'center',
														justifyContent:
															'center',
													}}>
													<Text
														style={{
															color:
																this.state
																	.editItemsData
																	.object == d
																	? APP_COLOURS.WHITE
																	: APP_COLOURS.BLUE,
															fontSize:
																16 +
																this.state
																	.fontSizeModalArea,
														}}>
														{d}
													</Text>
												</TouchableOpacity>
											))}
										</ScrollView>
									</View>
									{this.state.editItemsData.object ==
									'IMG' ? (
										<View
											style={{
												flex: 1,
												marginBottom: 20,
												flexDirection: 'row',
												padding: 10,
												maxHeight: 500,
												justifyContent: 'space-evenly',
												backgroundColor:
													APP_COLOURS.OFFWHITE,
											}}>
											<View
												style={{
													// flex: 1,
													// borderWidth: 1,
													// borderColor:
													// 	APP_COLOURS.ORANGE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
													// maxHeight: 60,
													marginRight: 10,
												}}>
												<Image
													source={pngUploader}
													style={{
														width: 220,
														height: 220,
														resizeMode: 'contain',
														zIndex: 80,
														position: 'absolute',
													}}
												/>
												<ImageUploader
													base64String={(
														base64String,
													) =>
														this.setState(
															(prevState) => ({
																editItemsData: {
																	...prevState.editItemsData,
																	data: base64String,
																},
																changeContentMade: true,
															}),
														)
													}
												/>
											</View>

											<View
												style={{
													// flex: 1,
													// backgroundColor:
													// 	APP_COLOURS.BLUE,
													// borderWidth: 1,
													// borderColor:
													// 	APP_COLOURS.ORANGE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
													// maxHeight: 60,
												}}>
												<Image
													source={psdUploader}
													style={{
														width: 220,
														height: 220,
														resizeMode: 'contain',
														zIndex: 80,
														position: 'absolute',
													}}
												/>
												<PSDUploader
													base64String={(
														base64String,
													) =>
														this.setState(
															(prevState) => ({
																editItemsData: {
																	...prevState.editItemsData,
																	data: base64String,
																},
																changeContentMade: true,
															}),
														)
													}
												/>
											</View>
										</View>
									) : (
										<View
											style={{
												flex: 1,
											}}>
											{/* <TextInput
										style={{
											flex: 1,
											padding: 5,
											marginBottom: 5,
											marginTop: 5,
											fontSize:
												16 +
												this.state.fontSizeModalArea,
											borderRadius: 8,
											backgroundColor: APP_COLOURS.WHITE,
											// textAlign: 'center',
											color: '#000',
											fontWeight: '200',
											fontFamily: 'Avenir',
											borderWidth: 0.3,
										}}
										placeholderTextColor={'#000'}
										multiline
										// numberOfLines={10}
										autoCapitalize='sentences'
										clearButtonMode='while-editing'
										autoCompleteType='off'
										editable={true}
										autoCorrect={false}
										keyboardType='default'
										textContentType='none'
										placeholder={`content data `}
										// defaultValue={this.state.user_data.email}
										defaultValue={
											this.state.editItemsData.data
										}
										onChangeText={(text) => {
											// let validated = this.validateEmail(text);
											this.setState((prevState) => ({
												editItemsData: {
													...prevState.editItemsData,
													data: text,
												},
												changeContentMade: true,
											}));
										}}
									/> */}
											<SimpleEditor
												ref={this.DrafJSEditor}
												initialHtml={
													this.state.editItemsData
														.data
												}
												exportHTML={(html) =>
													this.setState(
														(prevState) => ({
															editItemsData: {
																...prevState.editItemsData,
																data: html,
															},
															changeContentMade: true,
														}),
													)
												}
											/>
										</View>
									)}

									<TouchableOpacity
										onPress={() =>
											this.pushEditedContent(
												this.state.editItemsData,
											)
										}>
										<View
											style={{
												width: '100%',
												backgroundColor:
													APP_COLOURS.GREEN,
												padding: 9,
												justifyContent: 'center',
												alignItems: 'center',
												borderRadius: 10,
											}}>
											<Text
												style={{
													color: APP_COLOURS.WHITE,
													fontSize:
														16 +
														this.state
															.fontSizeModalArea,
												}}>
												Done
											</Text>
										</View>
									</TouchableOpacity>
								</View>

								<View
									style={{
										flex: 1,
										alignItems: 'center',
										justifyContent: 'center',
										zIndex: 99,
									}}>
									<View
										style={{
											borderRadius: 30,
											backgroundColor: '#000',
											shadowColor: '#000',
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.46,
											shadowRadius: 11.14,

											elevation: 17,
											height: '100%',
											maxHeight: 700,
											width: 320,
											paddingTop: 30,
											paddingBottom: 30,
											paddingLeft: 5,
											paddingRight: 5,
										}}>
										<Text
											style={{
												position: 'absolute',
												textAlign: 'center',
												top: 7,
												color: '#FFF',
												// left: 137,
												width: '100%',
												zIndex: 99,
												fontSize: 9,
											}}>
											Preview
										</Text>
										<View
											style={{
												width: '100%',
												textAlign: 'center',
												position: 'absolute',
												bottom: 5,
												color: '#FFF',
												zIndex: 99,
											}}>
											<Font
												family='Poppins'
												style={{ fontSize: 4 }}
												weight={900}>
												tetrice
											</Font>
										</View>

										<ScrollView
											style={{
												backgroundColor: '#FFF',
												borderRadius: 12,
												padding: 10,
												paddingBottom: 100,
											}}>
											<View
												style={{
													zIndex: 999,
												}}>
												{this.state.editItemsData
													.object == 'IMG' ? (
													<View style={{ flex: 1 }}>
														<Image
															source={{
																uri: `data:image/png;base64,${this.state.editItemsData.data}`,
															}}
															style={{
																width: '100%',
																height: 320,
																resizeMode:
																	'contain',
															}}
														/>
													</View>
												) : null}
												{this.state.editItemsData
													.object == 'P' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															textAlign:
																'justify',
															fontSize:
																15 +
																this.state
																	.fontSizeModalArea,

															color: '#000',
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
												{this.state.editItemsData
													.object == 'H1' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															textAlign: 'left',
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '800',
															color: '#000',
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
												{this.state.editItemsData
													.object == 'H2' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '700',
															color: '#000',
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
												{this.state.editItemsData
													.object == 'H3' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															fontSize:
																16 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '600',
															color: '#000',
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
												{this.state.editItemsData
													.object == 'OL' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															fontSize:
																16 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '600',
															color: '#000',
															paddingLeft: 10,
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
												{this.state.editItemsData
													.object == 'UL' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															fontSize:
																16 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '600',
															color: '#000',
															paddingLeft: 10,
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
												{this.state.editItemsData
													.object == 'IMG_TAG' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															fontSize:
																16 +
																this.state
																	.fontSizeModalArea,
															fontWeight: '600',
															color: '#000',
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}

												{this.state.editItemsData
													.object == 'LIST_P' ? (
													<p
														style={{
															flex: 1,
															marginBottom: 5,
															textAlign:
																'justify',
															paddingLeft: 15,
															fontSize:
																15 +
																this.state
																	.fontSizeModalArea,

															color: '#000',
														}}
														dangerouslySetInnerHTML={{
															__html: this.state
																.editItemsData
																.data,
														}}
													/>
												) : null}
											</View>
										</ScrollView>
									</View>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	addItem = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
							padding: 10,
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										addItem: false,
										newContent: '',
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 15,
								height: '100%',
							}}>
							<View
								style={{
									flex: 1,
									flexDirection: 'row',
									display:
										this.state.newContent.object == ''
											? 'none'
											: 'flex',
								}}>
								<View style={{ flex: 1 }}>
									<View
										style={{
											flexDirection: 'row',
											padding: 2,
											alignItems: 'center',
											justifyContent: 'space-between',
										}}>
										<Text
											allowFontScaling={false}
											selectable={false}
											style={{
												fontSize:
													16 +
													this.state
														.fontSizeModalArea,
												fontFamily: 'Avenir Next',
												textAlign: 'center',
												fontWeight: '400',
											}}>
											Add new content
										</Text>
										<View
											style={{
												flexDirection: 'row',
												padding: 2,
												alignItems: 'center',
											}}>
											<TouchableOpacity
												onPress={() =>
													this.setState({
														fontSizeModalArea:
															this.state
																.fontSizeModalArea -
															1,
													})
												}>
												<View
													style={{
														width:
															20 +
															this.state
																.fontSizeModalArea,
														height:
															20 +
															this.state
																.fontSizeModalArea,
														backgroundColor:
															APP_COLOURS.TEXTINPUT,
														// padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
													}}>
													<Text
														selectable={false}
														style={{
															color:
																APP_COLOURS.WHITE,
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
														}}>
														-
													</Text>
												</View>
											</TouchableOpacity>

											<View
												style={{
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
												}}>
												<Text
													selectable={false}
													style={{
														color:
															APP_COLOURS.TEXTINPUT,
														fontSize:
															17 +
															this.state
																.fontSizeModalArea,
														fontWeight: '500',
													}}>
													Aa
												</Text>
											</View>
											<TouchableOpacity
												onPress={() =>
													this.setState({
														fontSizeModalArea:
															this.state
																.fontSizeModalArea +
															1,
													})
												}>
												<View
													style={{
														width:
															20 +
															this.state
																.fontSizeModalArea,
														height:
															20 +
															this.state
																.fontSizeModalArea,
														backgroundColor:
															APP_COLOURS.TEXTINPUT,
														// padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
													}}>
													<Text
														selectable={false}
														style={{
															color:
																APP_COLOURS.WHITE,
															fontSize:
																17 +
																this.state
																	.fontSizeModalArea,
														}}>
														+
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>
									<View
										style={{
											// width: '100%',
											height: 60,
											borderRadius: 5,
											borderWidth: 0.3,
											borderColor: '#C5C5C5',
										}}>
										<ScrollView
											horizontal
											showsHorizontalScrollIndicator={
												true
											}>
											{[
												'H1',
												'H2',
												'H3',
												'P',

												'UL',
												'OL',
												'LIST_P',
												'IMG',
												'IMG_TAG',
												'MATH',
												'OPEN_BOOK',
												'CLOSED_BOOK',
											].map((d, i) => (
												<TouchableOpacity
													key={i}
													onPress={() =>
														this.setState(
															(prevState) => ({
																newContent: {
																	...prevState.newContent,
																	object: d,
																},
																changeContentMade: true,
															}),
														)
													}
													style={{
														width: 'auto',
														padding: 10,
														margin: 3,
														borderRadius: 5,
														borderWidth: 0.3,
														backgroundColor:
															this.state
																.newContent
																.object == d
																? APP_COLOURS.BLUE
																: APP_COLOURS.OFFWHITE,
														alignItems: 'center',
														justifyContent:
															'center',
													}}>
													<Text
														style={{
															color:
																this.state
																	.newContent
																	.object == d
																	? APP_COLOURS.WHITE
																	: APP_COLOURS.BLUE,
															fontSize:
																16 +
																this.state
																	.fontSizeModalArea,
														}}>
														{d}
													</Text>
												</TouchableOpacity>
											))}
										</ScrollView>
									</View>

									{this.state.newContent.object == 'IMG' ? (
										<View
											style={{
												flex: 1,
												marginBottom: 20,
												flexDirection: 'row',
												padding: 10,
												maxHeight: 500,
												justifyContent: 'space-evenly',
												backgroundColor:
													APP_COLOURS.OFFWHITE,
											}}>
											<View
												style={{
													// flex: 1,
													// borderWidth: 1,
													// borderColor:
													// 	APP_COLOURS.ORANGE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
													// maxHeight: 60,
													marginRight: 10,
												}}>
												<Image
													source={pngUploader}
													style={{
														width: 220,
														height: 220,
														resizeMode: 'contain',
														zIndex: 80,
														position: 'absolute',
													}}
												/>
												<ImageUploader
													base64String={(
														base64String,
													) =>
														this.setState(
															(prevState) => ({
																newContent: {
																	...prevState.newContent,
																	data: base64String,
																},
																changeContentMade: true,
															}),
														)
													}
												/>
											</View>

											<View
												style={{
													// flex: 1,
													// backgroundColor:
													// 	APP_COLOURS.BLUE,
													// borderWidth: 1,
													// borderColor:
													// 	APP_COLOURS.ORANGE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
													// maxHeight: 60,
												}}>
												<Image
													source={psdUploader}
													style={{
														width: 220,
														height: 220,
														resizeMode: 'contain',
														zIndex: 80,
														position: 'absolute',
													}}
												/>
												<PSDUploader
													base64String={(
														base64String,
													) =>
														this.setState(
															(prevState) => ({
																newContent: {
																	...prevState.newContent,
																	data: base64String,
																},
																changeContentMade: true,
															}),
														)
													}
												/>
											</View>
										</View>
									) : (
										<View
											style={{
												flex: 1,
												padding: 10,
											}}>
											<SimpleEditor
												ref={this.DrafJSEditor}
												exportHTML={(html) =>
													this.setState(
														(prevState) => ({
															newContent: {
																...prevState.newContent,
																data: html,
															},
															changeContentMade: true,
														}),
													)
												}
											/>
										</View>
									)}
									{typeof this.state.newContent.data !=
										'undefined' &&
									typeof this.state.newContent.object !=
										'undefined' ? (
										<TouchableOpacity
											onPress={() =>
												this.pushnewContent()
											}>
											<View
												style={{
													width: '100%',
													backgroundColor:
														APP_COLOURS.BLUE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
												}}>
												<Text
													style={{
														color:
															APP_COLOURS.WHITE,
														fontSize:
															16 +
															this.state
																.fontSizeModalArea,
													}}>
													+ Add
												</Text>
											</View>
										</TouchableOpacity>
									) : null}
								</View>

								<View
									style={{
										flex: 1,
										alignItems: 'center',
										justifyContent: 'center',
										zIndex: 99,
									}}>
									<View
										style={{
											borderRadius: 30,
											backgroundColor: '#000',
											shadowColor: '#000',
											shadowOffset: {
												width: 0,
												height: 8,
											},
											shadowOpacity: 0.46,
											shadowRadius: 11.14,

											elevation: 17,
											height: '100%',
											maxHeight: 700,
											width: 320,
											paddingTop: 30,
											paddingBottom: 30,
											paddingLeft: 5,
											paddingRight: 5,
										}}>
										<Text
											style={{
												position: 'absolute',
												textAlign: 'center',
												top: 7,
												color: '#FFF',
												// left: 137,
												width: '100%',
												zIndex: 99,
												fontSize: 9,
											}}>
											Preview
										</Text>
										<View
											style={{
												width: '100%',
												textAlign: 'center',
												position: 'absolute',
												bottom: 5,
												color: '#FFF',
												zIndex: 99,
											}}>
											<Font
												family='Poppins'
												style={{ fontSize: 4 }}
												weight={900}>
												tetrice
											</Font>
										</View>

										{this.state.newContent === '' ? (
											<View
												style={{
													flex: 1,
													backgroundColor: '#222B45',
													justifyContent: 'center',
													alignItems: 'center',
												}}>
												<Image
													source={tetriceLogo}
													style={{
														width: 125,
														height: 100,
														resizeMode: 'contain',
													}}
												/>
											</View>
										) : (
											<ScrollView
												style={{
													backgroundColor: '#FFF',
													borderRadius: 12,
													padding: 10,
													paddingBottom: 100,
												}}>
												<View
													style={{
														zIndex: 999,
													}}>
													{/* <TextSelector
													events={[
														{
															text: 'italics',
															handler: async (
																html,
																text,
															) => {
																let dd = await replaceAllText(
																	text,
																	this.state
																		.newContent
																		.data,
																	`<i>${text}</i>`,
																);

																console.log(
																	html,
																);

																await console.log(
																	dd,
																);

																this.setState(
																	(
																		prevState,
																	) => ({
																		newContent: {
																			...prevState.newContent,
																			data: dd,
																		},
																		changeContentMade: true,
																	}),
																);
															},
														},
														{
															text: 'Bold',
															handler: async (
																html,
																text,
															) => {
																let dd = await replaceAllText(
																	text,
																	this.state
																		.newContent
																		.data,
																	`<b>${text}</b>`,
																);

																await console.log(
																	dd,
																);

																this.setState(
																	(
																		prevState,
																	) => ({
																		newContent: {
																			...prevState.newContent,
																			data: dd,
																		},
																		changeContentMade: true,
																	}),
																);
															},
														},
														{
															text: 'Exp',
															handler: async (
																html,
																text,
															) => {
																let dd = await replaceAllText(
																	text,
																	this.state
																		.newContent
																		.data,
																	`<sup>${text}</sup>`,
																);

																await console.log(
																	dd,
																);

																this.setState(
																	(
																		prevState,
																	) => ({
																		newContent: {
																			...prevState.newContent,
																			data: dd,
																		},
																		changeContentMade: true,
																	}),
																);
															},
														},

														{
															text: 'Sub',
															handler: async (
																html,
																text,
															) => {
																// console.log(
																// 	this.state.newContent
																// 		.data,
																// );
																let dd = await replaceAllText(
																	text,
																	this.state
																		.newContent
																		.data,
																	`<sub>${text}</sub>`,
																);

																await console.log(
																	dd,
																);

																this.setState(
																	(
																		prevState,
																	) => ({
																		newContent: {
																			...prevState.newContent,
																			data: dd,
																		},
																		changeContentMade: true,
																	}),
																);
															},
														},
													]}
													color={'yellow'}
													colorText={false}
												/> */}

													{this.state.newContent
														.object == 'IMG' ? (
														<View
															style={{ flex: 1 }}>
															<Image
																source={{
																	uri: `data:image/png;base64,${this.state.newContent.data}`,
																}}
																style={{
																	width:
																		'100%',
																	height: 320,
																	resizeMode:
																		'contain',
																}}
															/>
														</View>
													) : null}
													{this.state.newContent
														.object == 'P' ? (
														<p
															style={{
																flex: 1,
																marginBottom: 5,
																textAlign:
																	'justify',
																fontSize:
																	15 +
																	this.state
																		.fontSizeModalArea,

																color: '#000',
															}}
															dangerouslySetInnerHTML={{
																__html: this
																	.state
																	.newContent
																	.data,
															}}
														/>
													) : null}
													{this.state.newContent
														.object == 'H1' ? (
														<p
															style={{
																flex: 1,
																marginBottom: 5,
																textAlign:
																	'left',
																fontSize:
																	17 +
																	this.state
																		.fontSizeModalArea,
																fontWeight:
																	'800',
																color: '#000',
															}}
															dangerouslySetInnerHTML={{
																__html: this
																	.state
																	.newContent
																	.data,
															}}
														/>
													) : null}
													{this.state.newContent
														.object == 'H2' ? (
														<p
															style={{
																flex: 1,
																marginBottom: 5,
																fontSize:
																	17 +
																	this.state
																		.fontSizeModalArea,
																fontWeight:
																	'700',
																color: '#000',
															}}
															dangerouslySetInnerHTML={{
																__html: this
																	.state
																	.newContent
																	.data,
															}}
														/>
													) : null}
													{this.state.newContent
														.object == 'H3' ? (
														<p
															style={{
																flex: 1,
																marginBottom: 5,
																fontSize:
																	16 +
																	this.state
																		.fontSizeModalArea,
																fontWeight:
																	'600',
																color: '#000',
															}}
															dangerouslySetInnerHTML={{
																__html: this
																	.state
																	.newContent
																	.data,
															}}
														/>
													) : null}
													{this.state.newContent
														.object == 'OL' ||
													this.state.newContent
														.object == 'UL' ||
													this.state.newContent
														.object == 'IMG_TAG' ||
													this.state.newContent
														.object == 'LIST_P' ? (
														<p
															style={{
																flex: 1,
																marginBottom: 5,
																fontSize:
																	14 +
																	this.state
																		.fontSizeModalArea,
																color: '#000',
																paddingLeft: 15,
															}}
															dangerouslySetInnerHTML={{
																__html: this
																	.state
																	.newContent
																	.data,
															}}
														/>
													) : null}
												</View>
											</ScrollView>
										)}
									</View>
								</View>
							</View>
						</View>

						<Modal
							animationType='fade'
							transparent={true}
							visible={this.state.contentPushedSuccessfully}>
							<View
								style={{
									flex: 1,
									justifyContent: 'center',
									alignItems: 'center',
									backgroundColor: 'rgba(52, 52, 52, 0.6)',
								}}>
								<View
									style={{
										width: 300,
										height: 'auto',
										backgroundColor: 'white',
										borderRadius: 20,
										justifyContent: 'center',
										alignItems: 'center',
									}}>
									<View
										style={{
											width: '100%',
											padding: 20,
											height: 'auto',
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 22,
												fontFamily: 'Avenir Next',
												textAlign: 'center',
												fontWeight: '700',
											}}>
											Content added
										</Text>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 17,
												fontFamily: 'Avenir Next',
												textAlign: 'center',
												fontWeight: '500',
											}}>
											In order to save changes to the
											book, click "Save Changes" in
											chapter tool bar
										</Text>
									</View>
								</View>
							</View>
						</Modal>
					</View>
				</View>
			</Modal>
		);
	};

	deleteEbookModal = () => {
		let confirm = 'delete';
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: '100%',
							maxWidth: 500,
							height: 'auto',
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										deleteEbookModal: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 20,
								height: 'auto',
								marginTop: 10,
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 18 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									fontWeight: '600',
								}}>
								Delete eBook: {this.state.currentBook.book_name}
							</Text>

							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									fontWeight: '400',
								}}>
								In order to prevent accidental deletion, please
								type{' '}
								<Text
									style={{
										color: 'red',
										fontWeight: '600',
									}}>
									delete
								</Text>{' '}
								into the text box below.
							</Text>

							<View
								style={{
									width: '100%',
									height: 'auto',
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 'auto',
										padding: 10,
										marginBottom: 5,
										marginTop: 5,
										fontSize: 16 + this.state.fontSize,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										borderWidth: 0.3,
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									placeholder={`...`}
									// defaultValue={this.state.user_data.email}
									// defaultValue={}
									onChangeText={(text) => {
										this.setState({
											deleteEbookConfirm: text == confirm,
										});
									}}
								/>
							</View>

							{this.state.deleteEbookConfirm ? (
								<TouchableOpacity
									onPress={async () => this.deleteBook()}>
									<View
										style={{
											width: '100%',
											backgroundColor: APP_COLOURS.RED,
											padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize: 10,
												fontWeight: '600',
											}}>
											Delete eBook
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	overviewComponent = (d) => {
		return (
			<View
				style={{
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}>
				<View style={{ width: 300 }}>
					<View
						style={{
							width: '100%',
							height: 'auto',
							// alignItems: 'center',
							marginTop: 5,
						}}>
						<Image
							source={{
								uri: `${d.cover_photo_url}`,
							}}
							style={{
								width: 300,
								height: 400,
								resizeMode: 'contain',
								borderWidth: 0.3,
							}}
						/>
					</View>
				</View>

				<View style={{ width: 300 }}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '700',
							marginBottom: 5,
						}}>
						{d.book_name}
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							opacity: 0.7,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
						}}>
						{d.ebook_id}
					</Text>
					<Text
						style={{
							// fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '600',
							marginBottom: 5,
						}}>
						{currencyFormat(
							parseFloat(this.state.currentBook.price),
						)}
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
						}}>
						{this.state.currentBook.category}
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						{this.state.currentBook.language}
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						{this.state.currentBook.pages}
					</Text>

					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
						}}>
						{this.state.currentBook.category}
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						{this.state.currentBook.language}
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						{this.state.currentBook.pages}
					</Text>
				</View>
				<View style={{ width: 300 }}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
						}}>
						Description
					</Text>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
						}}>
						{this.state.currentBook.about_book}
					</Text>
				</View>
			</View>
		);
	};

	devicePreview = () => {
		let bookContent = this.state.bookContent
			.filter((d) => d.chapter == this.state.selectedChapter)
			.sort((a, b) => parseFloat(b.line_num) < parseFloat(a.line_num));

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<DevicePreviewer
					bookContent={bookContent}
					linkerActive={false}
					closePreview={() =>
						this.setState({
							devicePreview: false,
						})
					}
				/>
			</Modal>
		);
	};

	editComponent = (d) => {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: 'row',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
				}}>
				<View
					style={{
						width: 300,
						height: 'auto',
						// alignItems: 'center',
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						Edit eBook
					</Text>
					<Image
						source={{
							uri: `${d.cover_photo_url}`,
						}}
						style={{
							width: 300,
							height: 400,
							resizeMode: 'contain',
							borderWidth: 0.5,
						}}
					/>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
							marginTop: 5,
						}}>
						Cover image url
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 'auto',
							padding: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						numberOfLines={10}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`cover url`}
						// defaultValue={this.state.user_data.email}
						value={this.state.currentBook.cover_photo_url}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									cover_photo_url: text,
								},
								changesMade: true,
							}));
						}}
					/>
				</View>

				<View
					style={{
						width: 300,
						height: 'auto',
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						Book title
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 50,
							paddingLeft: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`Book title`}
						// defaultValue={
						// 	d.book_name
						// }
						value={this.state.currentBook.book_name}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									book_name: text,
								},
								changesMade: true,
							}));
						}}
					/>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						Book description
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 'auto',
							padding: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						multiline
						numberOfLines={10}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`Description`}
						// defaultValue={this.state.user_data.email}
						value={this.state.currentBook.about_book}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									about_book: text,
								},
								changesMade: true,
							}));
						}}
					/>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						License category
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 'auto',
							padding: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						numberOfLines={10}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`License category`}
						// defaultValue={this.state.user_data.email}
						value={this.state.currentBook.category}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									category: text,
								},
								changesMade: true,
							}));
						}}
					/>

					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						Language
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 'auto',
							padding: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						numberOfLines={10}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`book language`}
						// defaultValue={this.state.user_data.email}
						value={this.state.currentBook.language}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									language: text,
								},
								changesMade: true,
							}));
						}}
					/>
				</View>

				<View
					style={{
						width: 300,
						height: 'auto',
						// alignItems: 'center',
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						Pages
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 'auto',
							padding: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						numberOfLines={10}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`book pages`}
						// defaultValue={this.state.user_data.email}
						value={this.state.currentBook.pages}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									pages: text,
								},
								changesMade: true,
							}));
						}}
					/>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '400',
							marginBottom: 5,
							opacity: 0.6,
						}}>
						Book Price -{' '}
						{currencyFormat(
							parseFloat(this.state.currentBook.price),
						)}
					</Text>
					<TextInput
						style={{
							width: '100%',
							height: 'auto',
							padding: 10,
							marginBottom: 5,
							marginTop: 0,
							fontSize: 16 + this.state.fontSize,
							borderRadius: 8,
							backgroundColor: APP_COLOURS.WHITE,
							// textAlign: 'center',
							color: '#000',
							fontWeight: '200',
							fontFamily: 'Avenir',
							borderWidth: 0.3,
						}}
						placeholderTextColor={'#000'}
						numberOfLines={10}
						autoCapitalize='words'
						clearButtonMode='while-editing'
						autoCompleteType='off'
						editable={true}
						autoCorrect={false}
						keyboardType='default'
						textContentType='none'
						placeholder={`Price`}
						// defaultValue={this.state.user_data.email}
						value={this.state.currentBook.price}
						onChangeText={(text) => {
							let validated = this.validateEmail(text);

							this.setState((prevState) => ({
								currentBook: {
									...prevState.currentBook,
									price: text,
								},
								changesMade: true,
							}));
						}}
					/>
				</View>

				<View
					style={{
						width: '100%',
						height: 'auto',
						marginTop: 20,
						borderTopWidth: 0.5,
						paddingTop: 10,
						flexDirection: 'row',
					}}>
					{this.state.selectedBookId == 'new' ? null : (
						<TouchableOpacity
							onPress={() =>
								this.setState({
									deleteEbookModal: true,
								})
							}>
							<View
								style={{
									width: 150,
									backgroundColor: APP_COLOURS.RED,
									padding: 9,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.WHITE,
										fontSize: 16 + this.state.fontSize,
									}}>
									Delete eBook
								</Text>
							</View>
						</TouchableOpacity>
					)}

					{this.state.deleteEbookModal ? (
						this.deleteEbookModal()
					) : (
						<View />
					)}

					{this.state.selectedBookId == 'new' ? (
						<TouchableOpacity onPress={() => this.createEbook()}>
							<View
								style={{
									width: 150,
									backgroundColor: APP_COLOURS.GREEN,
									padding: 9,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
									marginBottom: 5,
									marginLeft: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.WHITE,
										fontWeight: '500',
									}}>
									Save & Create eBook
								</Text>
							</View>
						</TouchableOpacity>
					) : this.state.changesMade ? (
						<TouchableOpacity onPress={() => this.saveChanges()}>
							<View
								style={{
									width: 150,
									backgroundColor: APP_COLOURS.GREEN,
									padding: 9,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
									marginBottom: 5,
									marginLeft: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.WHITE,
										fontWeight: '500',
									}}>
									Save changes
								</Text>
							</View>
						</TouchableOpacity>
					) : null}
				</View>
			</View>
		);
	};

	chaptersComponent = (d) => {
		return (
			<View
				style={{
					flex: 1,
					width: '100%',
					height: this.state.screenHeight - 260,
				}}>
				{this.state.downloadedbook == '' ||
				this.state.downloadedbook !==
					this.state.currentBook.ebook_id ? (
					<View
						style={{
							flex: 1,
							padding: 10,
							borderWidth: 0.5,
							borderColor: '#C5C5C5',
							borderRadius: 5,
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: APP_COLOURS.OFFWHITE,
							minWidth: 300,
							minHeight: 400,
							// height: '100%',
						}}>
						{this.state.gettingBookContent ? (
							<Image
								source={fetchingContent}
								style={{
									width: 190,
									height: 100,
									resizeMode: 'contain',
								}}
							/>
						) : (
							<TouchableOpacity
								onPress={() => this.getBookContent()}>
								<Image
									source={downloadbook}
									style={{
										width: 190,
										height: 100,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						)}
					</View>
				) : null}

				{this.state.downloadedbook ===
				this.state.currentBook.ebook_id ? (
					<View
						style={{
							width: '100%',
							height: this.state.screenHeight - 235,
						}}>
						<View
							style={{
								flexDirection: 'row',
								minHeight: 400,
								flex: 1,
								height: this.state.screenHeight - 500,
							}}>
							<View
								style={{
									width: 'auto',
									height: '100%',
									marginRight: 10,
								}}>
								<View
									style={{
										height: 30,
										width: 160,
										alignItems: 'center',
										marginBottom: 5,
										flexDirection: 'row',
									}}>
									<Text
										style={{
											fontFamily: 'Avenir',
											fontSize: 16 + this.state.fontSize,
											color: APP_COLOURS.TEXTCOLOR,
											fontWeight: '400',
											marginRight: 15,
											opacity: 0.6,
										}}>
										Chapters
									</Text>
									{this.state.loadingTestContent ? (
										<ActivityIndicator size='small' />
									) : null}
								</View>
								{/* <Text
									style={{
										fontFamily: 'Avenir',
										fontSize: 16 + this.state.fontSize,
										color: APP_COLOURS.TEXTCOLOR,
										fontWeight: '400',
										borderBottomWidth: 0.3,
										borderColor: '#C5C5C5',
										marginBottom: 10,
										paddingBottom: 10,
									}}></Text> */}
								{typeof this.state.currentBook.chapters !=
									'undefined' &&
								Array.isArray(
									this.state.currentBook.chapters,
								) ? (
									<ScrollView>
										<View
											style={{
												width: '100%',
												height: '100%',
												justifyContent: 'center',
												alignItems: 'center',
											}}>
											{this.state.currentBook.chapters
												.sort(
													(a, b) =>
														b.chapter_num <
														a.chapter_num,
												)
												.map((ch, i) => (
													<TouchableOpacity
														key={i}
														style={{
															height: 'auto',
															padding: 7,
															alignItems:
																'center',
															borderRadius: 10,
															marginTop: 5,
															width: 200,
															flexDirection:
																'row',
															backgroundColor:
																this.state
																	.selectedChapter ==
																ch.chapter_num
																	? APP_COLOURS.WHITE
																	: APP_COLOURS.OFFWHITE,
															borderWidth:
																this.state
																	.selectedChapter ==
																ch.chapter_num
																	? 0.3
																	: 0,
															borderColor:
																'#C5C5C5',
														}}
														onPress={() =>
															this.setState({
																selectedChapter:
																	ch.chapter_num,
																selectedChapterTitle:
																	ch.chapter_title,
															})
														}>
														<View
															style={{
																width: 5,
																borderRadius: 4,
																height: 40,
																backgroundColor:
																	this.state
																		.selectedChapter ==
																	ch.chapter_num
																		? APP_COLOURS.RED
																		: APP_COLOURS.WHITE,
																marginRight: 2,
															}}
														/>

														<View
															style={{
																flex: 1,
																minHeight: 40,
																alignItems:
																	'center',
																padding: 5,
															}}>
															<Text
																adjustsFontSizeToFit
																style={{
																	width:
																		'100%',
																	fontFamily:
																		'Avenir',
																	fontSize:
																		11 +
																		this
																			.state
																			.fontSize,
																	color:
																		APP_COLOURS.TEXTCOLOR,
																	opacity: 0.6,
																	fontWeight:
																		this
																			.state
																			.selectedChapter ==
																		ch.chapter_num
																			? '700'
																			: '500',
																}}>
																CH{' '}
																{ch.chapter_num}
																:
															</Text>
															<Text
																style={{
																	width:
																		'100%',
																	fontFamily:
																		'Avenir',
																	fontSize:
																		16 +
																		this
																			.state
																			.fontSize,
																	color:
																		APP_COLOURS.TEXTCOLOR,
																	fontWeight:
																		this
																			.state
																			.selectedChapter ==
																		ch.chapter_num
																			? '600'
																			: '400',
																}}>
																{
																	ch.chapter_title
																}
															</Text>
														</View>
													</TouchableOpacity>
												))}
										</View>
									</ScrollView>
								) : null}

								<TouchableOpacity
									style={{
										height: 'auto',
										padding: 7,
										alignItems: 'center',
										borderRadius: 10,
										marginTop: 10,
										width: '100%',
										backgroundColor: APP_COLOURS.WHITE,
										flexDirection: 'row',
										borderWidth: 0.3,
										borderColor: '#C5C5C5',
									}}
									onPress={() =>
										this.setState({
											createChapter: true,
										})
									}>
									<View
										style={{
											flex: 1,
											padding: 4,
											borderRadius: 10,
											backgroundColor: APP_COLOURS.WHITE,
										}}>
										<Text
											adjustsFontSizeToFit
											numberOfLines={1}
											style={{
												fontFamily: 'Avenir',
												// fontSize:
												// 	16 + this.state.fontSize,
												// opacity: 0.8,
												flex: 1,
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											+ Create new chapter
										</Text>
									</View>
								</TouchableOpacity>
							</View>

							<View
								style={{
									flex: 1,
									display:
										this.state.selectedChapter == ''
											? 'none'
											: 'flex',
									padding: 10,
									borderWidth: 0.3,
									borderColor: '#C5C5C5',
									borderRadius: 5,
									// justifyContent: 'center',
									// alignItems: 'center',
									backgroundColor: APP_COLOURS.OFFWHITE,
									minWidth: 300,
									// minHeight: 500,
								}}>
								{this.chapterContentComponent(
									this.state.selectedChapter,
								)}

								{this.state.createChapter
									? this.createChapterModal()
									: null}
							</View>
						</View>
					</View>
				) : null}
			</View>
		);
	};

	chapterContentComponent = (ch) => {
		// let filter_ = ;

		// let content = filter_.sort(
		// 	(a, b) => parseFloat(b.line_num) < parseFloat(a.line_num),
		// );

		return (
			<View
				style={{
					width: '100%',
					height: this.state.screenHeight - 260,
				}}>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '700',
							marginBottom: 5,
							paddingRight: 10,
						}}>
						Chapter {this.state.selectedChapter}:{' '}
						{this.state.selectedChapterTitle}
						<TouchableOpacity
							onPress={async () =>
								this.setState({
									updateChapterTitleModal: true,
								})
							}>
							<View
								style={{
									width: 'auto',
									// backgroundColor: APP_COLOURS.BLUE,
									padding: 9,
									marginLeft: 20,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
									marginRight: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.BLUE,
										fontSize: 10,
										fontWeight: '600',
										fontStyle: 'italic',
									}}>
									edit
								</Text>
							</View>
						</TouchableOpacity>
					</Text>

					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
						}}>
						{this.state.changesMade ? (
							<TouchableOpacity
								onPress={() => this.updateBookContent()}>
								<View
									style={{
										width: 100,
										backgroundColor: APP_COLOURS.GREEN,
										padding: 9,
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: 10,
										marginRight: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 13 + this.state.fontSize,
											fontWeight: '600',
										}}>
										Save changes
									</Text>
								</View>
							</TouchableOpacity>
						) : null}

						<TouchableOpacity
							onPress={async () =>
								this.setState({
									deleteChapterModal: true,
								})
							}>
							<View
								style={{
									width: 100,
									backgroundColor: APP_COLOURS.RED,
									padding: 9,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
									marginRight: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.WHITE,
										fontSize: 10,
										fontWeight: '600',
									}}>
									Delete Chapter
								</Text>
							</View>
						</TouchableOpacity>

						<TouchableOpacity
							onPress={async () =>
								this.setState({
									devicePreview: true,
								})
							}>
							<Image
								source={previewBook}
								style={{
									width: 200,
									height: 50,
									resizeMode: 'contain',
								}}
							/>
						</TouchableOpacity>
					</View>

					{this.state.deleteChapterModal
						? this.deleteChapterModal()
						: null}

					{this.state.updateChapterTitleModal
						? this.updateChapterTitleModal()
						: null}

					{this.state.devicePreview ? this.devicePreview() : null}
				</View>
				{/* <View
					style={{
						flexDirection: 'row',
					}}>
					{this.state.isNewcontent ? (
						<TouchableOpacity onPress={() => this.saveNewContent()}>
							<View
								style={{
									width: 100,
									backgroundColor: APP_COLOURS.BLUE,
									padding: 9,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
									marginRight: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.WHITE,
										fontSize: 16 + this.state.fontSize,
									}}>
									Save new content
								</Text>
							</View>
						</TouchableOpacity>
					) : null}
				</View> */}

				<ScrollView
					ref={(ref) => {
						this.scrollViewBookContent = ref;
					}}
					onContentSizeChange={() =>
						this.scrollViewBookContent.scrollToEnd({
							animated: true,
						})
					}>
					<View
						style={{
							width: '100%',
							height: '100%',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						{this.state.bookContent
							.filter((d) => d.chapter == ch)
							.sort(
								(a, b) =>
									parseFloat(b.line_num) <
									parseFloat(a.line_num),
							)
							.map((obj, i) => (
								<View
									key={i}
									style={{
										width: '100%',
										height: 'auto',
										minHeight: 70,
										marginTop: 5,
										marginBottom: 20,
										flexDirection: 'row',
										backgroundColor: APP_COLOURS.WHITE,
										padding: 10,
										borderRadius: 5,
										// marginRight: 40,
										justifyContent: 'space-between',
										borderWidth:
											typeof obj.newLine != 'undefined'
												? 1
												: 0,
										borderStyle: 'dotted',
										borderColor: APP_COLOURS.RED,
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: 'row',
											maxWidth: 400,
										}}>
										<View
											style={{
												marginRight: 10,
												width: 60,
											}}>
											<Text
												style={{
													// fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													fontWeight: '400',
													marginBottom: 5,
												}}>
												{obj.object}
											</Text>
											<Text
												style={{
													fontSize: 8,
													opacity: 0.6,
												}}>
												line: {obj.line_num}
											</Text>
											<Text
												style={{
													fontSize: 8,
													opacity: 0.6,
												}}>
												ch: {obj.chapter}
											</Text>
										</View>

										{obj.object == 'H1' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														19 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													fontWeight: '700',
													flexWrap: 'wrap',
												}}
											/>
										) : null}
										{obj.object == 'H2' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														18 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													fontWeight: '600',
													flexWrap: 'wrap',
												}}
											/>
										) : null}
										{obj.object == 'H3' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														17 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													fontWeight: '500',
													flexWrap: 'wrap',
												}}
											/>
										) : null}
										{obj.object == 'IMG' ? (
											<Image
												source={{
													uri: `data:image/png;base64,${obj.data}`,
												}}
												style={{
													width: 300,
													height: 300,
													resizeMode: 'contain',
												}}
											/>
										) : null}
										{obj.object == 'P' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													flexWrap: 'wrap',
													textAlign: 'justify',
												}}
											/>
										) : null}

										{obj.object == 'MATH' ? (
											<MathFormula formula={obj.data} />
										) : null}

										{obj.object == 'IMG_TAG' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													fontWeight: '300',
													flexWrap: 'wrap',
												}}
											/>
										) : null}
										{obj.object == 'OL' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													flexWrap: 'wrap',
												}}
											/>
										) : null}
										{obj.object == 'UL' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													flexWrap: 'wrap',
												}}
											/>
										) : null}
										{obj.object == 'LIST_P' ? (
											<p
												dangerouslySetInnerHTML={{
													__html: obj.data,
												}}
												style={{
													flex: 1,
													// fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													color:
														APP_COLOURS.TEXTCOLOR,
													flexWrap: 'wrap',
												}}
											/>
										) : null}

										{obj.object == 'OPEN_BOOK' ? (
											<View
												style={{
													backgroundColor:
														APP_COLOURS.RED,
													borderRadius: 5,
													justifyContent: 'center',
													alignItems: 'center',
													padding: 5,
													height: 40,
												}}>
												<Text
													style={{
														// fontFamily: 'Avenir',
														fontSize:
															16 +
															this.state.fontSize,
														color:
															APP_COLOURS.WHITE,
														fontWeight: '600',
														flexWrap: 'wrap',
														flex: 1,
													}}>
													Open Book Assessment:{' '}
													{obj.data}
												</Text>
											</View>
										) : null}

										{obj.object == 'CLOSED_BOOK' ? (
											<View
												style={{
													backgroundColor:
														APP_COLOURS.RED,
													borderRadius: 5,
													justifyContent: 'center',
													alignItems: 'center',
													padding: 5,
													height: 40,
												}}>
												<Text
													style={{
														// fontFamily: 'Avenir',
														fontSize:
															16 +
															this.state.fontSize,
														color:
															APP_COLOURS.WHITE,
														fontWeight: '600',
														flexWrap: 'wrap',
													}}>
													Closed Book Assessment:{' '}
													{obj.data}
												</Text>
											</View>
										) : null}
									</View>

									<View
										style={{
											width: 40,
											justifyContent: 'space-between',
											alignItems: 'center',
											height: 100,
										}}>
										<TouchableOpacity
											onPress={() =>
												this.updateBookContentOrder(
													obj,
													'up',
												)
											}>
											<Image
												source={up_arrow}
												style={{
													width: 20,
													height: 20,
													resizeMode: 'contain',
												}}
											/>
										</TouchableOpacity>

										<TouchableOpacity
											onPress={() =>
												this.setState({
													editItemsData: obj,
													editItem: true,
												})
											}>
											<View
												style={{
													width: '100%',
													// backgroundColor: APP_COLOURS.RED,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
													marginRight: 10,
												}}>
												<Text
													style={{
														color: APP_COLOURS.BLUE,
														fontSize:
															16 +
															this.state.fontSize,
													}}>
													Edit
												</Text>
											</View>
										</TouchableOpacity>

										<TouchableOpacity
											onPress={() =>
												this.updateBookContentOrder(
													obj,
													'down',
												)
											}>
											<Image
												source={down_arrow}
												style={{
													width: 20,
													height: 20,
													resizeMode: 'contain',
												}}
											/>
										</TouchableOpacity>
									</View>
								</View>
							))}
					</View>
				</ScrollView>

				{this.state.addItem && this.state.selectedChapter !== '' ? (
					this.addItem()
				) : (
					<TouchableOpacity
						onPress={() =>
							this.setState({
								addItem: true,
								newContent: '',
							})
						}>
						<View
							style={{
								width: '100%',
								backgroundColor: APP_COLOURS.BLUE,
								padding: 9,
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 10,
							}}>
							<Text
								style={{
									color: APP_COLOURS.WHITE,
									fontSize: 16 + this.state.fontSize,
								}}>
								+ Add content
							</Text>
						</View>
					</TouchableOpacity>
				)}

				{this.state.editItem
					? this.editItem(this.state.editItemsData)
					: null}
			</View>
		);
	};
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//

	assessmentsComponent = (d) => {
		return (
			<View
				style={{
					width: '100%',
					height: this.state.screenHeight - 260,
				}}>
				<View
					style={{
						width: '100%',
						height: this.state.screenHeight - 260,
						flexDirection: 'row',
						minHeight: 400,
					}}>
					<View
						style={{
							width: 'auto',
							height: '100%',
							minWidth: 180,
						}}>
						<View
							style={{
								height: 30,
								width: 200,
								alignItems: 'center',
								marginBottom: 5,
								flexDirection: 'row',
								marginRight: 10,
							}}>
							<Text
								style={{
									fontFamily: 'Avenir',
									fontSize: 16 + this.state.fontSize,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: '400',
									marginRight: 15,
									opacity: 0.6,
								}}>
								Assessments
							</Text>
							{this.state.loadingTestContent ? (
								<ActivityIndicator size='small' />
							) : null}
						</View>
						<Text
							style={{
								fontFamily: 'Avenir',
								fontSize: 16 + this.state.fontSize,
								color: APP_COLOURS.TEXTCOLOR,
								fontWeight: '400',
								borderBottomWidth: 0.3,
								borderColor: '#C5C5C5',
								marginBottom: 0,
							}}></Text>
						<ScrollView>
							{typeof this.state.currentBook.tests !=
								'undefined' &&
							this.state.currentBook.tests !== null &&
							Array.isArray(this.state.currentBook.tests)
								? this.state.currentBook.tests
										.sort((a, b) => b.name < a.name)
										.map((ts, i) => (
											<TouchableOpacity
												disabled={
													this.state
														.loadingTestContent
												}
												key={i}
												style={{
													height: 'auto',
													padding: 7,
													alignItems: 'center',
													borderRadius: 10,
													marginTop: 5,
													width: 200,
													flexDirection: 'row',
													backgroundColor:
														this.state
															.selectedTest ==
														ts.uuid
															? APP_COLOURS.WHITE
															: APP_COLOURS.OFFWHITE,
													borderWidth:
														this.state
															.selectedTest ==
														ts.uuid
															? 0.3
															: 0,
													borderColor: '#C5C5C5',
												}}
												onPress={() =>
													this.getTestContent(ts)
												}>
												<View
													style={{
														width: 5,
														borderRadius: 4,
														height: 40,
														backgroundColor:
															this.state
																.selectedTest ==
															ts.uuid
																? APP_COLOURS.RED
																: APP_COLOURS.WHITE,
														marginRight: 2,
													}}
												/>
												<View
													style={{
														flex: 1,
														minHeight: 40,
														alignItems: 'center',
														padding: 5,
													}}>
													<Text
														adjustsFontSizeToFit
														style={{
															width: '100%',
															fontFamily:
																'Avenir',
															fontSize:
																16 +
																this.state
																	.fontSize,
															color:
																APP_COLOURS.TEXTCOLOR,
															fontWeight:
																this.state
																	.selectedTest ==
																ts.uuid
																	? '600'
																	: '400',
														}}>
														{ts.name}
													</Text>
													<Text
														style={{
															width: '100%',
															fontFamily:
																'Avenir',
															fontSize:
																12 +
																this.state
																	.fontSize,
															opacity: 0.6,
															color:
																APP_COLOURS.TEXTCOLOR,
															fontWeight:
																this.state
																	.selectedTest ==
																ts.uuid
																	? '600'
																	: '400',
														}}>
														{ts.sub_category}
													</Text>
												</View>
											</TouchableOpacity>
										))
								: null}

							<TouchableOpacity
								style={{
									height: 'auto',
									padding: 7,
									alignItems: 'center',
									borderRadius: 10,
									height: 'auto',
									marginTop: 10,
									width: 200,
									backgroundColor: APP_COLOURS.WHITE,
									flexDirection: 'row',
									borderWidth: 0.3,
									borderColor: '#C5C5C5',
								}}
								onPress={() =>
									this.setState({
										createAssessment: true,
									})
								}>
								<View
									style={{
										flex: 1,
										flexWrap: 'wrap',
										padding: 4,
										borderRadius: 10,
										backgroundColor: APP_COLOURS.WHITE,
									}}>
									<Text
										numberOfLines={2}
										style={{
											fontFamily: 'Avenir',
											fontSize: 16 + this.state.fontSize,
											// opacity: 0.8,
											flex: 1,
											flexWrap: 'wrap',
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										+ Create new assessment
									</Text>
								</View>
							</TouchableOpacity>
						</ScrollView>
					</View>

					<View
						style={{
							flex: 1,
							display:
								this.state.selectedTest == '' ? 'none' : 'flex',
							padding: 10,
							borderWidth: 0.3,
							borderColor: '#C5C5C5',
							borderRadius: 5,
							backgroundColor: APP_COLOURS.OFFWHITE,
							minWidth: 300,
							height: this.state.screenHeight - 260,
						}}>
						{!this.state.loadingTestContent
							? this.assessmentContentComponent()
							: null}

						{this.state.createAssessment
							? this.createAssessmentModal()
							: null}
					</View>
				</View>
			</View>
		);
	};

	getTestContent = async (ts) => {
		let url = api_get_all_tests_content;

		await this.setState({
			selectedTest: ts.uuid,
			selectedTestTitle: ts.name,
			selectedTestData: ts,
			loadingTestContent: true,
			testContent: [],
		});

		let obj = {
			uuid_test: ts.uuid,
		};

		console.log('uuid', ts.uuid);

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				// console.log('response', response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log('response', responseData.data);
							this.setState({
								testContent: responseData.data,
								loadingTestContent: false,
							});

							if (!this.state.addedANewQuestion) {
								this.scrollView_.scrollTo({
									y: this.currentTestScrollPosition,
								});
							} else {
								this.scrollView_.scrollToEnd({
									animated: true,
								});
							}
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loadingTestContent: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loadingTestContent: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loadingTestContent: false,
				});
			});
	};

	assessmentContentComponent = () => {
		// let content = this.state.testContent;
		// console.log(content);
		// return;
		return (
			<View
				style={{
					width: '100%',
					height: this.state.screenHeight - 280,
				}}>
				<View
					style={{
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						padding: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 16 + this.state.fontSize,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '700',
							marginBottom: 5,
							paddingRight: 10,
						}}>
						Assessment: {this.state.selectedTestTitle}
					</Text>
					{/* {this.state.testChangesMade ? (
						<TouchableOpacity
							onPress={() => this.updateBookContent()}>
							<View
								style={{
									width: 100,
									backgroundColor: APP_COLOURS.GREEN,
									padding: 9,
									justifyContent: 'center',
									alignItems: 'center',
									borderRadius: 10,
									marginRight: 10,
								}}>
								<Text
									style={{
										color: APP_COLOURS.WHITE,
										fontSize: 12 + this.state.fontSize,
										fontWeight: '600',
									}}>
									Save changes
								</Text>
							</View>
						</TouchableOpacity>
					) : null} */}

					{/* <View>
						<CSVLink
							data={this.state.testContent}
							// className='btn btn-outline-secondary rounded-sm float-right'
							filename={
								this.state.selectedTestTitle + '_sales.csv'
							}>
							Export to Excel
						</CSVLink>
					</View> */}

					<TouchableOpacity
						onPress={() =>
							this.deleteAssessment(this.state.selectedTest)
						}>
						<View
							style={{
								width: 150,
								backgroundColor: APP_COLOURS.RED,
								padding: 9,
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 10,
							}}>
							<Text
								style={{
									color: APP_COLOURS.WHITE,
									fontSize: 12,
									fontWeight: '600',
								}}>
								Delete Assessment
							</Text>
						</View>
					</TouchableOpacity>
				</View>

				<ScrollView
					ref={(ref) => {
						this.scrollView_ = ref;
					}}
					// onContentSizeChange={() =>
					// 	this.scrollView.scrollToEnd({ animated: true })
					// }
					// onScroll={(e) => {
					// 	console.log(this.scrollView.current);
					// }}
					scrollEventThrottle={16}
					// onScrollAnimationEnd={(e) => {
					// 	console.log(e.nativeEvent);
					// 	this.setState({
					// 		assessmentScrollviewPosition: e.nativeEvent,
					// 	});
					// }}

					onScroll={(e) => {
						this.currentTestScrollPosition =
							e.nativeEvent.contentOffset.y;
					}}>
					<View
						style={{
							width: '100%',
							height: '100%',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						{this.state.testContent
							.sort((a, b) => b.question_no < a.question_no)
							.map((op, i) => {
								let chapter_ = '';
								let linenum_ = '';

								try {
									linenum_ = JSON.parse(op.book_link)
										.line_num;
									chapter_ = JSON.parse(op.book_link).chapter;
								} catch (e) {}

								let isExplanationBase64String = op.explanation.includes(
									`base64,`,
								);

								return (
									<View
										key={i}
										style={{
											width: '100%',
											height: 'auto',
											minHeight: 70,
											marginTop: 5,
											marginBottom: 20,
											flexDirection: 'row',
											backgroundColor: APP_COLOURS.WHITE,
											padding: 10,
											borderRadius: 5,
											justifyContent: 'space-between',
										}}>
										<View
											style={{
												flex: 1,
												maxWidth: 400,
											}}>
											<View
												style={{
													marginBottom: 10,
													width: '100%',
													flexDirection: 'row',
												}}>
												<Text
													style={{
														fontSize:
															16 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '400',
													}}>
													{op.question_no}){' '}
												</Text>
												<p
													dangerouslySetInnerHTML={{
														__html: op.question,
													}}
													style={{
														flex: 1,
														padding: 0,
														margin: 0,
														fontSize:
															16 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														flexWrap: 'wrap',
													}}
												/>
											</View>

											<View
												style={{
													width: '100%',
													height: 'auto',
													padding: 3,
												}}>
												{op.options.map(
													(option, index) => {
														let answer = op.answer;

														// let isBase64String = option[
														// 	index + 1
														// ].includes(
														// 	`data:image/png;base64,`,
														// );

														let isBase64String = option[
															index + 1
														].includes(`base64,`);

														return (
															<View
																key={index}
																style={{
																	width:
																		'100%',
																	//
																	// borderBottomWidth: 0.3,
																	// borderBottomColor: '#999797',
																	padding: 5,
																	// justifyContent: 'center',
																	alignItems:
																		'center',
																	flexDirection:
																		'row',
																	// backgroundColor: 'red',
																}}>
																<View
																	style={{
																		width: 30,
																		alignItems:
																			'center',
																		justifyContent:
																			'center',
																	}}>
																	{answer ==
																	Object.keys(
																		option,
																	) ? (
																		<Image
																			source={
																				tick
																			}
																			style={{
																				width: 15,
																				height: 15,
																				resizeMode:
																					'contain',
																			}}
																		/>
																	) : null}
																</View>
																<Text
																	style={{
																		fontSize:
																			16 +
																			this
																				.state
																				.fontSize,
																		fontWeight:
																			'700',
																		marginRight: 10,
																		color:
																			'#0567CB',
																	}}>
																	<div
																		dangerouslySetInnerHTML={{
																			__html: Object.keys(
																				option,
																			),
																		}}
																		style={{
																			// flex: 1,
																			padding: 0,
																			margin: 0,
																			fontSize:
																				16 +
																				this
																					.state
																					.fontSize,
																			color:
																				APP_COLOURS.TEXTCOLOR,
																			flexWrap:
																				'wrap',
																		}}
																	/>
																</Text>

																<View
																	style={{
																		// flex: 1,
																		flexDirection:
																			'row',
																	}}>
																	{isBase64String ? (
																		<Image
																			source={{
																				uri: `data:image/png;${
																					option[
																						index +
																							1
																					]
																				}`,
																			}}
																			style={{
																				width: 200,
																				height: 100,
																				resizeMode:
																					'contain',
																			}}
																		/>
																	) : (
																		<div
																			dangerouslySetInnerHTML={{
																				__html:
																					option[
																						index +
																							1
																					],
																			}}
																			style={{
																				padding: 0,
																				margin: 0,
																				fontSize:
																					16 +
																					this
																						.state
																						.fontSize,
																				color:
																					APP_COLOURS.TEXTCOLOR,
																				flexWrap:
																					'wrap',
																			}}
																		/>
																	)}
																</View>
															</View>
														);
													},
												)}
											</View>

											<View
												style={{
													marginBottom: 10,
													width: '100%',
													marginTop: 10,
												}}>
												<Text
													style={{
														fontSize:
															10 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '400',
														opacity: 0.7,
													}}>
													Explanation:
												</Text>

												{isExplanationBase64String ? (
													<Image
														source={{
															uri: `data:image/png;${op.explanation}`,
														}}
														style={{
															width: 100,
															height: 100,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													<div
														style={{
															fontSize:
																13 +
																this.state
																	.fontSize,
															color:
																APP_COLOURS.TEXTCOLOR,
															fontWeight: '400',
														}}
														dangerouslySetInnerHTML={{
															__html:
																op.explanation,
														}}
													/>
												)}
											</View>
											<View
												style={{
													marginBottom: 10,
													width: '100%',
												}}>
												<Text
													style={{
														fontSize:
															10 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '400',
														opacity: 0.7,
													}}>
													book link:
												</Text>
												<Text
													style={{
														fontSize:
															13 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '400',
														marginBottom: 5,
													}}>
													- Chapter: {chapter_}
												</Text>
												<Text
													style={{
														fontSize:
															13 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '400',
														marginBottom: 5,
													}}>
													- Line number: {linenum_}
												</Text>
											</View>
											<View
												style={{
													marginBottom: 10,
													width: '100%',
												}}>
												<Text
													style={{
														fontSize:
															10 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '400',
														opacity: 0.7,
													}}>
													Mark allocation
												</Text>
												<Text
													style={{
														fontSize:
															13 +
															this.state.fontSize,
														color:
															APP_COLOURS.TEXTCOLOR,
														fontWeight: '700',
														marginBottom: 5,
													}}>
													{op.total}
												</Text>
											</View>
										</View>

										<View
											style={{
												width: 40,
												justifyContent: 'space-between',
												alignItems: 'center',
												height: 100,
											}}>
											{/* <TouchableOpacity
									onPress={() =>
										this.updateBookContentOrder(obj, 'up')
									}>
									<Image
										source={up_arrow}
										style={{
											width: 20,
											height: 20,
											resizeMode: 'contain',
										}}
									/>
								</TouchableOpacity> */}

											<TouchableOpacity
												onPress={() => {
													this.setState({
														editAssessmentData: op,
														editAssessmentQuestion: true,
													});
													// console.log(op);
												}}>
												<View
													style={{
														width: '100%',

														padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
														marginRight: 10,
													}}>
													<Text
														style={{
															color:
																APP_COLOURS.BLUE,
															fontSize:
																16 +
																this.state
																	.fontSize,
														}}>
														Edit
													</Text>
												</View>
											</TouchableOpacity>

											{/* <TouchableOpacity
									onPress={() =>
										this.updateBookContentOrder(obj, 'down')
									}>
									<Image
										source={down_arrow}
										style={{
											width: 20,
											height: 20,
											resizeMode: 'contain',
										}}
									/>
								</TouchableOpacity> */}
										</View>
									</View>
								);
							})}
					</View>
				</ScrollView>

				<View
					style={{
						flexDirection: 'row',
						width: '100%',
					}}>
					{this.state.addQuestionModal
						? this.addQuestionModal()
						: null}

					{this.state.uploadCSV ? this.uploadCSV() : null}

					<TouchableOpacity
						onPress={async () =>
							await this.setState(
								{
									editAssessmentData: {
										uuid: '',
										uuid_test: '',
										question_no: '',
										options: [],
										question: '',
										answer: -1,
										type: 'radio',
										total: '',
										created: '2021-10-02 13:07:50',
										updated: '2021-10-02 13:07:50',
										book_link: '',
										explanation: '',
									},
								},
								this.setState({
									addQuestionModal: true,
								}),
							)
						}>
						<View
							style={{
								// backgroundColor: APP_COLOURS.BLUE,
								// padding: 9,
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 10,
							}}>
							<Image
								source={addQuestions}
								style={{
									width: 200,
									height: 60,
									resizeMode: 'contain',
								}}
							/>
						</View>
					</TouchableOpacity>

					<TouchableOpacity
						onPress={async () =>
							await this.setState({
								uploadCSV: true,
							})
						}>
						<View
							style={{
								// width: '100%',
								// backgroundColor: APP_COLOURS.BLUE,
								// padding: 9,
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 10,
							}}>
							<Image
								source={exceluploader}
								style={{
									width: 200,
									height: 60,
									resizeMode: 'contain',
								}}
							/>
						</View>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	uploadCSV = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: '100%',
							maxWidth: 500,
							height: 'auto',
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										uploadCSV: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 20,
								height: 'auto',
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 17 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '600',
									marginBottom: 20,
								}}>
								Assessment CSV uploader
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '400',
									marginBottom: 20,
									width: '100%',
								}}>
								Please note, correctly formatted CSV/Excel
								spreadsheets are neccessary for the assessment
								to be uploaded and displayed correctly.
							</Text>

							<CSVDragnDrop
								jsonReturn={(d) => this.updateTestContent(d)}
								assessment_uuid={this.state.selectedTest}
								testLength={this.state.testContent.length}
							/>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	createAssessment = async () => {
		let now = moment().toISOString();
		let obj = {
			description: '',
			expiry_date_start: now,
			expiry_date_end: now,
			test_name: this.state.newAssessment.test_name,
			sub_category: this.state.newAssessment.sub_category,
			ebook_id: this.state.currentBook.ebook_id,
		};

		console.log(obj);
		let url = api_initiate_test;
		await this.setState({
			creatingAssessment: true,
		});

		let raw = await JSON.stringify(obj);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log('response', response);
				if (response.status == 200) {
					this.getAllBooks();
					this.setState({
						creatingAssessment: false,
						createAssessment: false,
					});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert('error updating book');
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						updating: false,
						createAssessment: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
					updating: false,
				});
			});
	};

	createChapterModal = () => {
		let chapters = [];
		if (
			typeof this.state.currentBook.chapters != 'undefined' &&
			Array.isArray(this.state.currentBook.chapters) &&
			this.state.currentBook.chapters != null
		) {
			chapters = this.state.currentBook.chapters;
		}

		let newChapterNumber = chapters.length + 1;
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: '100%',
							maxWidth: 500,
							height: 'auto',
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										createChapterModal: false,
										createChapter: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 20,
								height: 'auto',
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '400',
								}}>
								Create Chapter {newChapterNumber}
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '300',
								}}>
								{this.state.currentBook.name}
							</Text>

							<View
								style={{
									width: '100%',
									height: 'auto',
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 'auto',
										padding: 10,
										marginBottom: 5,
										marginTop: 5,
										fontSize: 16 + this.state.fontSize,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										borderWidth: 0.3,
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									placeholder={`Chapter title `}
									// defaultValue={this.state.user_data.email}
									// defaultValue={}
									onChangeText={(text) =>
										this.setState({
											newChapter: {
												chapter_num: newChapterNumber,
												chapter_title: text,
											},
										})
									}
								/>
							</View>

							{typeof this.state.newChapter.chapter_num !=
								'undefined' &&
							typeof this.state.newChapter.chapter_title !=
								'undefined' ? (
								<TouchableOpacity
									onPress={async () => {
										let selectedChapter_temp = this.state
											.newChapter.chapter_num;
										let selectedChapterTitle_temp = this
											.state.newChapter.chapter_title;
										let selectedBookId = this.state
											.selectedBookId;
										let currentBook = this.state
											.currentBook;
										let book_tabs = this.state.book_tabs;

										const push = await chapters.push(
											this.state.newChapter,
										);
										// console.log(chapters);

										await this.setState((prevState) => ({
											currentBook: {
												...prevState.currentBook,
												chapters: chapters,
											},
										}));
										await this.saveChanges();

										await this.setState({
											selectedChapter: selectedChapter_temp,
											selectedChapterTitle: selectedChapterTitle_temp,
											currentBook: currentBook,
											selectedBookId: selectedBookId,
											book_tabs: book_tabs,
										});
									}}>
									<View
										style={{
											width: '100%',
											backgroundColor: APP_COLOURS.BLUE,
											padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize:
													16 + this.state.fontSize,
											}}>
											{this.state.creatingChapter ? (
												<ActivityIndicator />
											) : (
												'+ Create'
											)}
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	updateChapterTitleModal = (d) => {
		let {
			selectedChapter,
			selectedChapterTitle,
			currentBook,
			selectedBookId,
		} = this.state;
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: '100%',
							maxWidth: 500,
							height: 'auto',
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										updateChapterTitleModal: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 20,
								height: 'auto',
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '400',
								}}>
								Chapter {selectedChapter}
							</Text>
							{/* <Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '300',
								}}>
								{this.state.currentBook.name}
							</Text> */}

							<View
								style={{
									width: '100%',
									height: 'auto',
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 'auto',
										padding: 10,
										marginBottom: 5,
										marginTop: 5,
										fontSize: 16 + this.state.fontSize,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										borderWidth: 0.3,
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									// placeholder={`Chapter title `}
									defaultValue={selectedChapterTitle}
									// defaultValue={}
									onChangeText={(text) =>
										this.setState({
											newTitle: text,
										})
									}
								/>
							</View>

							{typeof this.state.newTitle != 'undefined' &&
							typeof this.state.newTitle != 'undefined' ? (
								<TouchableOpacity
									onPress={async () => {
										let selectedChapter = this.state
											.selectedChapter;
										let selectedBookId = this.state
											.selectedBookId;
										let newTitle = this.state.newTitle;
										let currentBook = this.state
											.currentBook;
										let book_tabs = this.state.book_tabs;

										let chapters = await this.state
											.currentBook.chapters;
										const push = await chapters.filter(
											(d) =>
												d.chapter_num !==
												selectedChapter,
										);

										let obj = {
											chapter_num: selectedChapter,
											chapter_title: newTitle,
										};

										let pushNew = await [...push, obj];
										console.log(pushNew);
										let sort_ = await pushNew.sort(
											(a, b) =>
												b.chapter_num < a.chapter_num,
										);

										await this.setState((prevState) => ({
											currentBook: {
												...prevState.currentBook,
												chapters: sort_,
											},
										}));
										currentBook = this.state.currentBook;
										await this.saveChanges();

										await this.setState({
											selectedChapter: selectedChapter,
											selectedChapterTitle: newTitle,
											currentBook: currentBook,
											selectedBookId: selectedBookId,
											book_tabs: book_tabs,
											updateChapterTitleModal: false,
										});
									}}>
									<View
										style={{
											width: '100%',
											backgroundColor: APP_COLOURS.BLUE,
											padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize:
													16 + this.state.fontSize,
											}}>
											{this.state.creatingChapter ? (
												<ActivityIndicator />
											) : (
												'Save changes'
											)}
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	deleteOptionInEdit = async (i) => {
		let optionsarray = await [...this.state.editAssessmentData.options];
		// const newArray_ = await optionsarray.filter((_, ndx) => i !== ndx);

		const newArray_ = await optionsarray.splice(i, 1);

		// console.log(i, optionsarray);
		// return;

		await this.setState((prevState) => ({
			editAssessmentData: {
				...prevState.editAssessmentData,
				options: [],
			},
			testChangesMade: true,
			addNewImage: -1,
		}));

		let newArray = [];
		let count = 1;
		for (const da of optionsarray) {
			const ll = await newArray.push({
				[count]: da[[Object.keys(da)]],
			});
			// await console.log(da);
			await count++;
		}

		await console.log(optionsarray);

		// return;

		// await this.setState({
		// 	editAssessmentData: {
		// 		options: optionsarray,
		// 	},
		// 	testChangesMade: true,
		// 	addNewImage: -1,
		// });

		await this.setState((prevState) => ({
			editAssessmentData: {
				...prevState.editAssessmentData,
				options: optionsarray,
			},
			testChangesMade: true,
			addNewImage: -1,
		}));
		this.setState({});
	};

	deleteChapterModal = () => {
		let confirm = 'delete';
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: '100%',
							maxWidth: 500,
							height: 'auto',
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										deleteChapterModal: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 20,
								height: 'auto',
								marginTop: 10,
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 18 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									fontWeight: '600',
								}}>
								Delete CH {this.state.selectedChapter}:{' '}
								{this.state.selectedChapterTitle}
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									fontWeight: '400',
								}}>
								In order to prevent accidental deletion, please
								type{' '}
								<Text
									style={{
										color: 'red',
										fontWeight: '600',
									}}>
									delete
								</Text>{' '}
								into the text box below.
							</Text>

							<View
								style={{
									width: '100%',
									height: 'auto',
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 'auto',
										padding: 10,
										marginBottom: 5,
										marginTop: 5,
										fontSize: 16 + this.state.fontSize,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										borderWidth: 0.3,
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									placeholder={`...`}
									// defaultValue={this.state.user_data.email}
									// defaultValue={}
									onChangeText={(text) => {
										this.setState({
											deleteChapterConfirm:
												text == confirm,
										});
									}}
								/>
							</View>

							{this.state.deleteChapterConfirm ? (
								<TouchableOpacity
									onPress={async () => {
										let bookContentExists = await this.state.bookContent.filter(
											(d) =>
												d.chapter ==
												this.state.selectedChapter,
										);
										if (bookContentExists.length > 0) {
											await this.deleteChapterAndContent();
										} else {
											await this.deleteChapterInEBookChapters();
										}
									}}>
									<View
										style={{
											width: '100%',
											backgroundColor: APP_COLOURS.RED,
											padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize: 10,
												fontWeight: '600',
											}}>
											Delete Chapter
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	createAssessmentModal = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',
					}}>
					<View
						style={{
							width: '100%',
							maxWidth: 500,
							height: 'auto',
							backgroundColor: 'white',
							borderRadius: 20,
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<View
							style={{
								position: 'absolute',
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										createAssessment: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: 'contain',
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: '100%',
								padding: 20,
								height: 'auto',
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '400',
								}}>
								Create Assessment
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: 'Avenir Next',
									textAlign: 'center',
									fontWeight: '300',
								}}>
								{this.state.currentBook.name}
							</Text>
							<View
								style={{
									width: '100%',
									height: 50,
									borderRadius: 5,
									borderWidth: 0.3,
									borderColor: '#C5C5C5',
								}}>
								<ScrollView horizontal>
									{['open', 'closed'].map((d, i) => (
										<TouchableOpacity
											key={i}
											onPress={() =>
												this.setState((prevState) => ({
													newAssessment: {
														...prevState.newAssessment,
														sub_category: d,
													},
													changeContentMade: true,
												}))
											}
											style={{
												width: 60,
												padding: 10,
												margin: 3,
												borderRadius: 5,
												borderWidth: 0.3,
												alignItems: 'center',
												backgroundColor:
													this.state.newAssessment
														.sub_category == d
														? APP_COLOURS.BLUE
														: APP_COLOURS.OFFWHITE,
											}}>
											<Text
												style={{
													color:
														this.state.newAssessment
															.sub_category == d
															? APP_COLOURS.WHITE
															: APP_COLOURS.BLUE,
													fontSize:
														16 +
														this.state.fontSize,
												}}>
												{d}
											</Text>
										</TouchableOpacity>
									))}
								</ScrollView>
							</View>
							<View
								style={{
									width: '100%',
									height: 'auto',
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 'auto',
										minHeight: 80,
										padding: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16 + this.state.fontSize,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										borderWidth: 0.3,
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									placeholder={`Assessment title `}
									// defaultValue={this.state.user_data.email}
									// defaultValue={}
									onChangeText={(text) => {
										// let validated = this.validateEmail(text);
										this.setState((prevState) => ({
											newAssessment: {
												...prevState.newAssessment,
												test_name: text,
											},
											changeContentMade: true,
										}));
									}}
								/>
							</View>

							{typeof this.state.newAssessment.test_name !=
								'undefined' &&
							typeof this.state.newAssessment.sub_category !=
								'undefined' ? (
								<TouchableOpacity
									onPress={() => this.createAssessment()}>
									<View
										style={{
											width: '100%',
											backgroundColor: APP_COLOURS.BLUE,
											padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize:
													16 + this.state.fontSize,
											}}>
											{this.state.creatingAssessment ? (
												<ActivityIndicator />
											) : (
												'+ Create'
											)}
										</Text>
									</View>
								</TouchableOpacity>
							) : null}
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	addQuestionModal = () => {
		let bookContent = this.state.bookContent
			.filter((d) => d.chapter == this.state.selectedChapter)
			.sort((a, b) => parseFloat(b.line_num) < parseFloat(a.line_num));
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',

						width: '100%',
						height: '100%',
					}}>
					<ScrollView>
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: 200,
								padding: 20,
							}}>
							<View
								style={{
									flex: 1,
									width: '100%',
									maxWidth: 700,
									minWidth: 400,
									height: 'auto',
									backgroundColor: 'white',
									borderRadius: 20,
									justifyContent: 'center',
									alignItems: 'center',
									zIndex: 990,
									opacity: this.state.openBookLinker
										? 0.1
										: 1,
								}}>
								<View
									style={{
										position: 'absolute',
										zIndex: 99,
										top: 20,
										left: 20,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												addQuestionModal: false,
											})
										}>
										<Image
											source={close}
											style={{
												width: 20,
												height: 20,

												resizeMode: 'contain',
											}}
										/>
									</TouchableOpacity>
								</View>
								<View
									style={{
										width: '100%',
										padding: 20,
										height: 'auto',
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 16 + this.state.fontSize,
											fontFamily: 'Avenir Next',
											// textAlign: 'center',
											fontWeight: '700',
											opacity: 0.5,
											marginLeft: 40,
										}}>
										Add question:{' '}
										{this.state.testContent.length + 1}
									</Text>

									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												paddingLeft: 10,
												fontWeight: '300',
											}}>
											QUESTION:
										</Text>

										<SimpleEditor
											ref={this.DrafJSEditor}
											initialHtml={
												this.state.editAssessmentData
													.question
											}
											exportHTML={(html) => {
												this.setState((prevState) => ({
													editAssessmentData: {
														...prevState.editAssessmentData,
														question: html,
													},
													testChangesMade: true,
												}));
												console.log(
													'question change',
													this.state
														.editAssessmentData,
												);
											}}
										/>
									</View>

									<Text
										allowFontScaling={false}
										style={{
											fontSize: 16 + this.state.fontSize,
											fontFamily: 'Avenir Next',
											margin: 5,
											// paddingLeft: 10,
											fontWeight: '300',
										}}>
										OPTIONS:
									</Text>

									{this.state.editAssessmentData.options.map(
										(d_, i) => {
											// let isBase64String = d_[
											// 	Object.keys(d_)
											// ].includes(
											// 	`data:image/png;base64,`,
											// );

											let isBase64String = d_[
												Object.keys(d_)
											].includes(`base64,`);

											return (
												<View
													key={i}
													style={{
														width: '100%',
														height: 'auto',
														marginBottom: 10,
													}}>
													<View
														style={{
															width: '100%',
															height: 'auto',
															flexDirection:
																'row',
														}}>
														<TouchableOpacity
															style={{
																width: 40,
																alignItems:
																	'center',
																justifyContent:
																	'center',
															}}
															onPress={() => {
																let answer = Object.keys(
																	d_,
																);
																this.setState(
																	(
																		prevState,
																	) => ({
																		editAssessmentData: {
																			...prevState.editAssessmentData,
																			answer: parseInt(
																				answer[0],
																			),
																		},
																		testChangesMade: true,
																	}),
																);
															}}>
															{this.state
																.editAssessmentData
																.answer ==
															Object.keys(d_) ? (
																<Image
																	source={
																		tick
																	}
																	style={{
																		width: 20,
																		height: 20,
																		resizeMode:
																			'contain',
																	}}
																/>
															) : (
																<View
																	style={{
																		borderRadius: 10,
																		width: 20,
																		height: 20,
																		borderWidth: 1,
																		borderColor:
																			APP_COLOURS.GREEN,
																	}}></View>
															)}
														</TouchableOpacity>

														{d_[Object.keys(d_)] !==
														'' ? (
															<View style={{}}>
																{isBase64String ? (
																	<Image
																		source={{
																			uri: `data:image/png;${
																				d_[
																					Object.keys(
																						d_,
																					)
																				]
																			}`,
																		}}
																		style={{
																			width: 100,
																			height: 100,
																			resizeMode:
																				'contain',
																		}}
																	/>
																) : (
																	<SimpleEditor
																		ref={
																			this
																				.DrafJSEditor
																		}
																		initialHtml={
																			d_[
																				Object.keys(
																					d_,
																				)
																			]
																		}
																		exportHTML={(
																			html,
																		) => {
																			// let validated = this.validateEmail(text);
																			let optionsarray = this
																				.state
																				.editAssessmentData
																				.options;
																			optionsarray[
																				i
																			] = {
																				[Object.keys(
																					d_,
																				)]: html,
																			};

																			console.log(
																				'optionsarray',
																				optionsarray,
																			);

																			this.setState(
																				(
																					prevState,
																				) => ({
																					editAssessmentData: {
																						...prevState.editAssessmentData,
																						options: optionsarray,
																					},
																					testChangesMade: true,
																				}),
																			);
																		}}
																	/>
																)}
															</View>
														) : this.state
																.addNewImage ==
														  Object.keys(d_) ? (
															<View
																style={{
																	flexDirection:
																		'row',
																}}>
																<View
																	style={{
																		padding: 9,
																		justifyContent:
																			'center',
																		alignItems:
																			'center',
																		borderRadius: 10,
																		// maxHeight: 60,
																		marginRight: 10,
																	}}>
																	<Image
																		source={
																			pngUploader
																		}
																		style={{
																			width: 90,
																			height: 90,
																			resizeMode:
																				'contain',
																			zIndex: 80,
																			position:
																				'absolute',
																		}}
																	/>
																	<ImageUploader
																		base64String={(
																			base64String,
																		) => {
																			// let optionsarray = this
																			// 	.state
																			// 	.editAssessmentData
																			// 	.options;
																			// optionsarray[
																			// 	i
																			// ] = {
																			// 	[Object.keys(
																			// 		d_,
																			// 	)]: `data:image/png;base64,${base64String}`,
																			// };
																			let optionsarray = this
																				.state
																				.editAssessmentData
																				.options;
																			optionsarray[
																				i
																			] = {
																				[Object.keys(
																					d_,
																				)]: `base64,${base64String}`,
																			};

																			console.log(
																				'optionsarray',
																				optionsarray,
																			);

																			this.setState(
																				(
																					prevState,
																				) => ({
																					editAssessmentData: {
																						...prevState.editAssessmentData,
																						options: optionsarray,
																					},
																					testChangesMade: true,
																					addNewImage: -1,
																				}),
																			);
																		}}
																	/>
																</View>

																<View
																	style={{
																		// padding: 9,
																		justifyContent:
																			'center',
																		alignItems:
																			'center',
																		borderRadius: 10,
																	}}>
																	<Image
																		source={
																			psdUploader
																		}
																		style={{
																			width: 90,
																			height: 90,
																			resizeMode:
																				'contain',
																			zIndex: 80,
																			position:
																				'absolute',
																		}}
																	/>
																	<PSDUploader
																		base64String={(
																			base64String,
																		) => {
																			let optionsarray = this
																				.state
																				.editAssessmentData
																				.options;
																			optionsarray[
																				i
																			] = {
																				[Object.keys(
																					d_,
																				)]: `base64,${base64String}`,
																			};

																			console.log(
																				'optionsarray',
																				optionsarray,
																			);

																			this.setState(
																				(
																					prevState,
																				) => ({
																					editAssessmentData: {
																						...prevState.editAssessmentData,
																						options: optionsarray,
																					},
																					testChangesMade: true,
																					addNewImage: -1,
																				}),
																			);
																		}}
																	/>
																</View>
															</View>
														) : (
															<SimpleEditor
																ref={
																	this
																		.DrafJSEditor
																}
																initialHtml={
																	d_[
																		Object.keys(
																			d_,
																		)
																	]
																}
																exportHTML={(
																	html,
																) => {
																	// let validated = this.validateEmail(text);
																	let optionsarray = this
																		.state
																		.editAssessmentData
																		.options;
																	optionsarray[
																		i
																	] = {
																		[Object.keys(
																			d_,
																		)]: html,
																	};

																	console.log(
																		'optionsarray',
																		optionsarray,
																	);

																	this.setState(
																		(
																			prevState,
																		) => ({
																			editAssessmentData: {
																				...prevState.editAssessmentData,
																				options: optionsarray,
																			},
																			testChangesMade: true,
																		}),
																	);
																}}
															/>
														)}

														<View
															style={{
																position:
																	'absolute',
																right: 5,
																top: 5,
															}}>
															<TouchableOpacity
																onPress={async () =>
																	this.deleteOptionInEdit(
																		i,
																	)
																}>
																<Image
																	source={
																		delete_
																	}
																	style={{
																		width: 20,
																		height: 20,
																		resizeMode:
																			'contain',
																	}}
																/>
															</TouchableOpacity>
														</View>
													</View>
												</View>
											);
										},
									)}

									{/* <TouchableOpacity
										onPress={async () => {
											let options = await this.state
												.editAssessmentData.options;
											let objec = {
												[options.length + 1]: '',
											};
											const push = await options.push(
												objec,
											);
											console.log(options);
											this.setState((prevState) => ({
												editAssessmentData: {
													...prevState.editAssessmentData,
													options: options,
												},
											}));
										}}>
										<View
											style={{
												width: '100%',
												backgroundColor:
													APP_COLOURS.BLUE,
												padding: 9,
												justifyContent: 'center',
												alignItems: 'center',
												borderRadius: 10,
											}}>
											<Text
												style={{
													color: APP_COLOURS.WHITE,
													fontSize:
														16 +
														this.state.fontSize,
												}}>
												{this.state
													.creatingAssessment ? (
													<ActivityIndicator />
												) : (
													'+ Option'
												)}
											</Text>
										</View>
									</TouchableOpacity> */}

									{!this.state.addNewOption ? (
										<TouchableOpacity
											onPress={async () => {
												this.setState({
													addNewOption: !this.state
														.addNewOption,
												});
											}}>
											<View
												style={{
													width: '100%',
													backgroundColor:
														APP_COLOURS.BLUE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
												}}>
												<Text
													style={{
														color:
															APP_COLOURS.WHITE,
														fontSize:
															16 +
															this.state.fontSize,
													}}>
													{this.state
														.creatingAssessment ? (
														<ActivityIndicator />
													) : (
														'+ Option'
													)}
												</Text>
											</View>
										</TouchableOpacity>
									) : (
										<View>
											<Text
												allowFontScaling={false}
												style={{
													fontSize:
														16 +
														this.state.fontSize,
													fontFamily: 'Avenir Next',
													margin: 5,
													// paddingLeft: 10,
													fontWeight: '300',
												}}>
												Select one of the following
												field types
											</Text>
											<View
												style={{
													flexDirection: 'row',
													flex: 1,
													width: '100%',
												}}>
												<TouchableOpacity
													style={{
														flex: 1,
														marginRight: 10,
													}}
													onPress={async () => {
														let options = await this
															.state
															.editAssessmentData
															.options;

														let objec = {
															[options.length +
															1]: '',
														};
														const push = await options.push(
															objec,
														);
														console.log(options);
														this.setState(
															(prevState) => ({
																editAssessmentData: {
																	...prevState.editAssessmentData,
																	options: options,
																},
																addNewOption: false,
																addNewImage: -1,
															}),
														);
													}}>
													<View
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.BLUE,
															padding: 9,
															justifyContent:
																'center',
															alignItems:
																'center',
															borderRadius: 10,
														}}>
														<Text
															style={{
																color:
																	APP_COLOURS.WHITE,
																fontSize:
																	16 +
																	this.state
																		.fontSize,
															}}>
															+ Text
														</Text>
													</View>
												</TouchableOpacity>
												<TouchableOpacity
													style={{
														flex: 1,
													}}
													onPress={async () => {
														let options = await this
															.state
															.editAssessmentData
															.options;

														let objec = {
															[options.length +
															1]: '',
														};
														const push = await options.push(
															objec,
														);
														console.log(options);
														this.setState(
															(prevState) => ({
																editAssessmentData: {
																	...prevState.editAssessmentData,
																	options: options,
																},
																addNewOption: false,
																addNewImage:
																	options.length,
															}),
														);
													}}>
													<View
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.BLUE,
															padding: 9,
															justifyContent:
																'center',
															alignItems:
																'center',
															borderRadius: 10,
														}}>
														<Text
															style={{
																color:
																	APP_COLOURS.WHITE,
																fontSize:
																	16 +
																	this.state
																		.fontSize,
															}}>
															+ Image
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										</View>
									)}

									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												// paddingLeft: 10,
												fontWeight: '300',
											}}>
											Book link
										</Text>
										{/* <TextInput
									style={{
										width: '100%',
										height: 'auto',
										// minHeight: 80,
										padding: 10,
										marginBottom: 5,
										marginTop: 5,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										borderWidth: 0.3,

										fontSize: 16 + this.state.fontSize,
										fontFamily: 'Avenir Next',
										fontWeight: '500',
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									placeholder={`Book link`}
									defaultValue={
										this.state.editAssessmentData.book_link
									}
									// defaultValue={this.state.editItemsData.data}
									onChangeText={async (text) => {
										// let validated = this.validateEmail(text);
										await this.setState((prevState) => ({
											editAssessmentData: {
												...prevState.editAssessmentData,
												book_link: text,
											},
											testChangesMade: true,
										}));
										await console.log(
											'book_link change',
											this.state.editAssessmentData,
										);
									}}
								/> */}
										<TouchableOpacity
											onPress={() => {
												this.setState({
													openBookLinker: true,
													currentBookLink: '',
												});
											}}>
											<View
												style={{
													width: '100%',
													backgroundColor:
														APP_COLOURS.BLUE,
													borderRadius: 10,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
												}}>
												<Text
													style={{
														color:
															APP_COLOURS.WHITE,
														fontSize:
															16 +
															this.state.fontSize,
													}}>
													{this.state
														.editAssessmentData
														.book_link != ''
														? this.state
																.editAssessmentData
																.book_link
														: '+ Add book link'}
												</Text>
											</View>
										</TouchableOpacity>
									</View>
									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												// paddingLeft: 10,
												fontWeight: '300',
											}}>
											Explanation
										</Text>
										<TextInput
											style={{
												width: '100%',
												height: 'auto',
												// minHeight: 80,
												padding: 10,
												marginBottom: 5,
												marginTop: 5,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												// textAlign: 'center',
												color: '#000',
												borderWidth: 0.3,

												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												fontWeight: '500',
											}}
											placeholderTextColor={'#000'}
											multiline
											// numberOfLines={10}
											autoCapitalize='words'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Explanation`}
											defaultValue={
												this.state.editAssessmentData
													.explanation
											}
											// defaultValue={this.state.editItemsData.data}
											onChangeText={async (text) => {
												// let validated = this.validateEmail(text);
												await this.setState(
													(prevState) => ({
														editAssessmentData: {
															...prevState.editAssessmentData,
															explanation: text,
														},
														testChangesMade: true,
													}),
												);
											}}
										/>
									</View>
									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												// paddingLeft: 10,
												fontWeight: '300',
											}}>
											Mark allocation
										</Text>

										{this.state.markMustBeNumber ? (
											<Text
												allowFontScaling={false}
												style={{
													fontSize:
														13 +
														this.state.fontSize,
													fontFamily: 'Avenir Next',
													color: 'red',
													margin: 5,
													// paddingLeft: 10,
													fontWeight: '300',
													fontStyle: 'italic',
												}}>
												Mark must be numeric.
											</Text>
										) : null}
										<TextInput
											style={{
												width: '100%',
												height: 'auto',
												// minHeight: 80,
												padding: 10,
												marginBottom: 5,
												marginTop: 5,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												// textAlign: 'center',
												color: '#000',
												borderWidth: 0.3,

												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												fontWeight: '500',
											}}
											placeholderTextColor={'#000'}
											multiline
											// numberOfLines={10}
											autoCapitalize='words'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Mark allocation`}
											defaultValue={
												this.state.editAssessmentData
													.total
											}
											// defaultValue={this.state.editItemsData.data}
											onChangeText={async (text) => {
												// let validated = this.validateEmail(text);

												if (isNumeric(text)) {
													await this.setState(
														(prevState) => ({
															editAssessmentData: {
																...prevState.editAssessmentData,
																total: text,
															},
															testChangesMade: true,
															markMustBeNumber: false,
														}),
													);
												} else {
													this.setState({
														markMustBeNumber: true,
													});
												}
											}}
										/>
									</View>

									{this.state.editAssessmentData.options
										.length > 1 &&
									this.state.editAssessmentData.answer > -1 &&
									this.state.editAssessmentData.question !==
										'' &&
									this.state.editAssessmentData.total > 0 &&
									this.state.editAssessmentData.book_link !==
										'' ? (
										<TouchableOpacity
											onPress={async () => {
												await this.setState(
													(prevState) => ({
														editAssessmentData: {
															...prevState.editAssessmentData,
															uuid_test: this
																.state
																.selectedTest,
															question_no:
																this.state
																	.testContent
																	.length + 1,
															addedANewQuestion: true,
														},
														testChangesMade: true,
													}),
												);
												let array = [];
												await array.push(
													this.state
														.editAssessmentData,
												);
												console.log(array);
												await this.updateTestContent(
													array,
												);
											}}>
											<View
												style={{
													width: '100%',
													backgroundColor:
														APP_COLOURS.GREEN,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
												}}>
												<Text
													style={{
														color:
															APP_COLOURS.WHITE,
														fontSize:
															16 +
															this.state.fontSize,
													}}>
													Save & Update
												</Text>
											</View>
										</TouchableOpacity>
									) : (
										<View>
											<Text
												style={{
													fontSize: 8,
													fontWeight: '600',
												}}>
												Required parameters:
											</Text>

											<Text
												style={{
													fontSize: 8,
												}}>
												{this.state.editAssessmentData
													.question !== '' ? (
													<Image
														source={tick}
														style={{
															width: 8,
															height: 8,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													' - '
												)}{' '}
												Question
											</Text>
											<Text
												style={{
													fontSize: 8,
												}}>
												{this.state.editAssessmentData
													.answer > -1 ? (
													<Image
														source={tick}
														style={{
															width: 8,
															height: 8,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													' - '
												)}{' '}
												Answer
											</Text>
											<Text
												style={{
													fontSize: 8,
												}}>
												{this.state.editAssessmentData
													.options.length > 1 ? (
													<Image
														source={tick}
														style={{
															width: 8,
															height: 8,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													' - '
												)}{' '}
												more than one option
											</Text>
											<Text
												style={{
													fontSize: 8,
												}}>
												{this.state.editAssessmentData
													.book_link !== '' ? (
													<Image
														source={tick}
														style={{
															width: 8,
															height: 8,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													' - '
												)}{' '}
												open book link provided
											</Text>
											<Text
												style={{
													fontSize: 8,
												}}>
												{this.state.editAssessmentData
													.total > 0 ? (
													<Image
														source={tick}
														style={{
															width: 8,
															height: 8,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													' - '
												)}{' '}
												total mark for question
											</Text>
										</View>
									)}
								</View>
							</View>
							{!this.state.openBookLinker ? null : (
								<View
									style={{
										flex: 1,
										justifyContent: 'center',
										alignItems: 'center',
										zIndex: 999,
										position: 'absolute',
										// backgroundColor: 'rgba(52, 52, 52, 0.6)',
										width: '100%',
										height: this.state.screenHeight,
									}}>
									{bookContent.length > 0 ? (
										<DevicePreviewer
											bookContent={bookContent}
											linkerActive={true}
											selectedLine={''}
											setBookLink={(uuid) =>
												this.setState((prevState) => ({
													editAssessmentData: {
														...prevState.editAssessmentData,
														book_link: uuid,
													},
													testChangesMade: true,
												}))
											}
											closePreview={() =>
												this.setState({
													openBookLinker: false,
												})
											}
										/>
									) : (
										<View
											style={{
												padding: 20,
												justifyContent: 'center',
												alignItems: 'center',
												backgroundColor: '#FFF',
												borderRadius: 15,
												maxWidth: 400,
												paddingLeft: 40,
											}}>
											<View
												style={{
													position: 'absolute',
													zIndex: 99,
													top: 10,
													left: 10,
												}}>
												<TouchableOpacity
													onPress={() =>
														this.setState({
															openBookLinker: false,
														})
													}>
													<Image
														source={close}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																'contain',
														}}
													/>
												</TouchableOpacity>
											</View>
											<Text>
												Book content and chapter has not
												been selected.
											</Text>
										</View>
									)}
								</View>
							)}
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	editAssessmentQuestion = () => {
		let bookContent = this.state.bookContent
			.filter((d) => d.chapter == this.state.selectedChapter)
			.sort((a, b) => parseFloat(b.line_num) < parseFloat(a.line_num));

		console.log(
			decodeURIComponent(this.state.editAssessmentData.book_link),
		);

		let chapter_ = '';
		let linenum_ = '';

		try {
			linenum_ = JSON.parse(this.state.editAssessmentData.book_link)
				.line_num;
			chapter_ = JSON.parse(this.state.editAssessmentData.book_link)
				.chapter;
		} catch (e) {}

		// return;

		let isExplanationBase64String = this.state.editAssessmentData.explanation.includes(
			`base64,`,
		);

		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: 'rgba(52, 52, 52, 0.6)',

						width: '100%',
						height: '100%',
						// padding: 50,
					}}>
					<ScrollView>
						<View
							style={{
								flex: 1,
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: 200,
								padding: 20,
							}}>
							<View
								style={{
									flex: 1,
									width: '100%',
									maxWidth: 700,
									minWidth: 400,
									height: 'auto',
									backgroundColor: 'white',
									borderRadius: 20,
									justifyContent: 'center',
									alignItems: 'center',
									zIndex: 990,
									opacity: this.state.openBookLinker
										? 0.1
										: 1,
								}}>
								<View
									style={{
										position: 'absolute',
										zIndex: 99,
										top: 20,
										left: 20,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												editAssessmentQuestion: false,
											})
										}>
										<Image
											source={close}
											style={{
												width: 20,
												height: 20,

												resizeMode: 'contain',
											}}
										/>
									</TouchableOpacity>
								</View>
								<View
									style={{
										width: '100%',
										padding: 20,
										height: 'auto',
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 16 + this.state.fontSize,
											fontFamily: 'Avenir Next',
											// textAlign: 'center',
											fontWeight: '700',
											opacity: 0.5,
											marginLeft: 40,
										}}>
										Edit Question:
									</Text>

									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												paddingLeft: 10,
												fontWeight: '300',
											}}>
											QUESTION:
										</Text>

										<SimpleEditor
											ref={this.DrafJSEditor}
											initialHtml={
												this.state.editAssessmentData
													.question
											}
											exportHTML={(html) => {
												this.setState((prevState) => ({
													editAssessmentData: {
														...prevState.editAssessmentData,
														question: html,
													},
													testChangesMade: true,
												}));
												console.log(
													'question change',
													html,
												);
											}}
										/>
									</View>

									<Text
										allowFontScaling={false}
										style={{
											fontSize: 16 + this.state.fontSize,
											fontFamily: 'Avenir Next',
											margin: 5,
											// paddingLeft: 10,
											fontWeight: '300',
										}}>
										OPTIONS:
									</Text>

									{this.state.editAssessmentData.options.map(
										(d_, i) => {
											// let isBase64String = d_[
											// 	Object.keys(d_)
											// ].includes(
											// 	`data:image/png;base64,`,
											// );

											let isBase64String = d_[
												Object.keys(d_)
											].includes(`base64,`);

											return (
												<View
													key={i}
													style={{
														width: '100%',
														height: 'auto',
														marginBottom: 10,
													}}>
													<View
														style={{
															width: '100%',
															height: 'auto',
															flexDirection:
																'row',
														}}>
														<TouchableOpacity
															style={{
																width: 40,
																alignItems:
																	'center',
																justifyContent:
																	'center',
															}}
															onPress={async () => {
																let answer = Object.keys(
																	d_,
																);
																await console.log(
																	answer,
																);
																await this.setState(
																	(
																		prevState,
																	) => ({
																		editAssessmentData: {
																			...prevState.editAssessmentData,
																			answer: parseInt(
																				answer[0],
																			),
																		},
																		testChangesMade: true,
																	}),
																);
															}}>
															{this.state
																.editAssessmentData
																.answer ==
															Object.keys(d_) ? (
																<Image
																	source={
																		tick
																	}
																	style={{
																		width: 20,
																		height: 20,
																		resizeMode:
																			'contain',
																	}}
																/>
															) : (
																<View
																	style={{
																		borderRadius: 10,
																		width: 20,
																		height: 20,
																		borderWidth: 1,
																		borderColor:
																			APP_COLOURS.GREEN,
																	}}></View>
															)}
														</TouchableOpacity>

														{d_[Object.keys(d_)] !==
														'' ? (
															<View style={{}}>
																{isBase64String ? (
																	<Image
																		source={{
																			uri: `data:image/png;${
																				d_[
																					Object.keys(
																						d_,
																					)
																				]
																			}`,
																		}}
																		style={{
																			width: 100,
																			height: 100,
																			resizeMode:
																				'contain',
																		}}
																	/>
																) : (
																	<SimpleEditor
																		ref={
																			this
																				.DrafJSEditor
																		}
																		initialHtml={
																			d_[
																				Object.keys(
																					d_,
																				)
																			]
																		}
																		exportHTML={(
																			html,
																		) => {
																			// let validated = this.validateEmail(text);
																			let optionsarray = this
																				.state
																				.editAssessmentData
																				.options;
																			optionsarray[
																				i
																			] = {
																				[Object.keys(
																					d_,
																				)]: html,
																			};

																			console.log(
																				'optionsarray',
																				optionsarray,
																			);

																			this.setState(
																				(
																					prevState,
																				) => ({
																					editAssessmentData: {
																						...prevState.editAssessmentData,
																						options: optionsarray,
																					},
																					testChangesMade: true,
																				}),
																			);
																		}}
																	/>
																)}
															</View>
														) : this.state
																.addNewImage ==
														  Object.keys(d_) ? (
															<View
																style={{
																	flexDirection:
																		'row',
																}}>
																<View
																	style={{
																		padding: 9,
																		justifyContent:
																			'center',
																		alignItems:
																			'center',
																		borderRadius: 10,
																		// maxHeight: 60,
																		marginRight: 10,
																	}}>
																	<Image
																		source={
																			pngUploader
																		}
																		style={{
																			width: 90,
																			height: 90,
																			resizeMode:
																				'contain',
																			zIndex: 80,
																			position:
																				'absolute',
																		}}
																	/>
																	<ImageUploader
																		base64String={(
																			base64String,
																		) => {
																			let optionsarray = this
																				.state
																				.editAssessmentData
																				.options;
																			optionsarray[
																				i
																			] = {
																				[Object.keys(
																					d_,
																				)]: `data:image/png;base64,${base64String}`,
																			};

																			console.log(
																				'optionsarray',
																				optionsarray,
																			);

																			this.setState(
																				(
																					prevState,
																				) => ({
																					editAssessmentData: {
																						...prevState.editAssessmentData,
																						options: optionsarray,
																					},
																					testChangesMade: true,
																					addNewImage: -1,
																				}),
																			);
																		}}
																	/>
																</View>

																<View
																	style={{
																		// padding: 9,
																		justifyContent:
																			'center',
																		alignItems:
																			'center',
																		borderRadius: 10,
																	}}>
																	<Image
																		source={
																			psdUploader
																		}
																		style={{
																			width: 90,
																			height: 90,
																			resizeMode:
																				'contain',
																			zIndex: 80,
																			position:
																				'absolute',
																		}}
																	/>
																	<PSDUploader
																		base64String={(
																			base64String,
																		) => {
																			let optionsarray = this
																				.state
																				.editAssessmentData
																				.options;
																			optionsarray[
																				i
																			] = {
																				[Object.keys(
																					d_,
																				)]: `data:image/png;base64,${base64String}`,
																			};

																			console.log(
																				'optionsarray',
																				optionsarray,
																			);

																			this.setState(
																				(
																					prevState,
																				) => ({
																					editAssessmentData: {
																						...prevState.editAssessmentData,
																						options: optionsarray,
																					},
																					testChangesMade: true,
																					addNewImage: -1,
																				}),
																			);
																		}}
																	/>
																</View>
															</View>
														) : (
															<SimpleEditor
																ref={
																	this
																		.DrafJSEditor
																}
																initialHtml={
																	d_[
																		Object.keys(
																			d_,
																		)
																	]
																}
																exportHTML={(
																	html,
																) => {
																	// let validated = this.validateEmail(text);
																	let optionsarray = this
																		.state
																		.editAssessmentData
																		.options;
																	optionsarray[
																		i
																	] = {
																		[Object.keys(
																			d_,
																		)]: html,
																	};

																	console.log(
																		'optionsarray',
																		optionsarray,
																	);

																	this.setState(
																		(
																			prevState,
																		) => ({
																			editAssessmentData: {
																				...prevState.editAssessmentData,
																				options: optionsarray,
																			},
																			testChangesMade: true,
																		}),
																	);
																}}
															/>
														)}

														<View
															style={{
																position:
																	'absolute',
																right: 5,
																top: 5,
															}}>
															<TouchableOpacity
																onPress={async () =>
																	this.deleteOptionInEdit(
																		i,
																	)
																}>
																<Image
																	source={
																		delete_
																	}
																	style={{
																		width: 20,
																		height: 20,
																		resizeMode:
																			'contain',
																	}}
																/>
															</TouchableOpacity>
															{/* <TouchableOpacity
															onPress={async () => {

																
																let optionsarray = await this
																	.state
																	.editAssessmentData
																	.options;
																const newArray_ = await optionsarray.filter(
																	(_, ndx) =>
																		i !==
																		ndx,
																);

																let newArray = [];
																let count = 1;
																for (const da of newArray_) {
																	const ll = await newArray.push(
																		{
																			[count]:
																				da[
																					[
																						Object.keys(
																							da,
																						),
																					]
																				],
																		},
																	);
																	// await console.log(da);
																	await count++;
																}

																await console.log(
																	newArray,
																);

																// return;

																await this.setState(
																	(
																		prevState,
																	) => ({
																		editAssessmentData: {
																			...prevState.editAssessmentData,
																			options: newArray,
																		},
																		testChangesMade: true,
																		addNewImage: -1,
																	}),
																);
																this.setState(
																	{},
																);
															}}>
															<Image
																source={delete_}
																style={{
																	width: 20,
																	height: 20,
																	resizeMode:
																		'contain',
																}}
															/>
														</TouchableOpacity> */}
														</View>
													</View>
												</View>
											);
										},
									)}

									{!this.state.addNewOption ? (
										<TouchableOpacity
											onPress={async () => {
												this.setState({
													addNewOption: !this.state
														.addNewOption,
												});
											}}>
											<View
												style={{
													width: '100%',
													backgroundColor:
														APP_COLOURS.BLUE,
													padding: 9,
													justifyContent: 'center',
													alignItems: 'center',
													borderRadius: 10,
												}}>
												<Text
													style={{
														color:
															APP_COLOURS.WHITE,
														fontSize:
															16 +
															this.state.fontSize,
													}}>
													{this.state
														.creatingAssessment ? (
														<ActivityIndicator />
													) : (
														'+ Option'
													)}
												</Text>
											</View>
										</TouchableOpacity>
									) : (
										<View>
											<Text
												allowFontScaling={false}
												style={{
													fontSize:
														16 +
														this.state.fontSize,
													fontFamily: 'Avenir Next',
													margin: 5,
													// paddingLeft: 10,
													fontWeight: '300',
												}}>
												Select one of the following
												field types
											</Text>
											<View
												style={{
													flexDirection: 'row',
													flex: 1,
													width: '100%',
												}}>
												<TouchableOpacity
													style={{
														flex: 1,
														marginRight: 10,
													}}
													onPress={async () => {
														let options = await this
															.state
															.editAssessmentData
															.options;

														let objec = {
															[options.length +
															1]: '',
														};
														const push = await options.push(
															objec,
														);
														console.log(options);
														this.setState(
															(prevState) => ({
																editAssessmentData: {
																	...prevState.editAssessmentData,
																	options: options,
																},
																addNewOption: false,
																addNewImage: -1,
															}),
														);
													}}>
													<View
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.BLUE,
															padding: 9,
															justifyContent:
																'center',
															alignItems:
																'center',
															borderRadius: 10,
														}}>
														<Text
															style={{
																color:
																	APP_COLOURS.WHITE,
																fontSize:
																	16 +
																	this.state
																		.fontSize,
															}}>
															+ Text
														</Text>
													</View>
												</TouchableOpacity>
												<TouchableOpacity
													style={{
														flex: 1,
													}}
													onPress={async () => {
														let options = await this
															.state
															.editAssessmentData
															.options;

														let objec = {
															[options.length +
															1]: '',
														};
														const push = await options.push(
															objec,
														);
														console.log(options);
														this.setState(
															(prevState) => ({
																editAssessmentData: {
																	...prevState.editAssessmentData,
																	options: options,
																},
																addNewOption: false,
																addNewImage:
																	options.length,
															}),
														);
													}}>
													<View
														style={{
															flex: 1,
															backgroundColor:
																APP_COLOURS.BLUE,
															padding: 9,
															justifyContent:
																'center',
															alignItems:
																'center',
															borderRadius: 10,
														}}>
														<Text
															style={{
																color:
																	APP_COLOURS.WHITE,
																fontSize:
																	16 +
																	this.state
																		.fontSize,
															}}>
															+ Image
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										</View>
									)}

									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
											marginTop: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												// paddingLeft: 10,
												fontWeight: '300',
											}}>
											Book link
										</Text>
										{/* <TextInput
									style={{
										width: '100%',
										height: 'auto',
										// minHeight: 80,
										padding: 10,
										marginBottom: 5,
										marginTop: 5,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										borderWidth: 0.3,

										fontSize: 16 + this.state.fontSize,
										fontFamily: 'Avenir Next',
										fontWeight: '500',
									}}
									placeholderTextColor={'#000'}
									multiline
									// numberOfLines={10}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='off'
									editable={true}
									autoCorrect={false}
									keyboardType='default'
									textContentType='none'
									placeholder={`Book link`}
									defaultValue={this.state.editAssessmentData.book_link}
									// defaultValue={this.state.editItemsData.data}
									onChangeText={async (text) => {
										// let validated = this.validateEmail(text);
										await this.setState((prevState) => ({
											editAssessmentData: {
												...prevState.editAssessmentData,
												book_link: text,
											},
											testChangesMade: true,
										}));
										await console.log(
											'book_link change',
											this.state.editAssessmentData,
										);
									}}
								/> */}

										<TouchableOpacity
											onPress={() => {
												this.setState({
													openBookLinker: true,
													currentBookLink: this.state
														.editAssessmentData
														.book_link,
												});
											}}>
											{chapter_ !== '' ? (
												<View
													style={{
														width: '100%',
														backgroundColor:
															APP_COLOURS.BLUE,
														borderRadius: 10,
														padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<Text
														style={{
															fontSize:
																13 +
																this.state
																	.fontSize,
															color: '#FFF',
															fontWeight: '400',
															marginBottom: 5,
														}}>
														Chapter: {chapter_}
													</Text>
													<Text
														style={{
															fontSize:
																13 +
																this.state
																	.fontSize,
															color: '#FFF',
															fontWeight: '400',
															marginBottom: 5,
														}}>
														Line number: {linenum_}
													</Text>
												</View>
											) : (
												<View
													style={{
														width: '100%',
														backgroundColor:
															APP_COLOURS.BLUE,
														borderRadius: 10,
														padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
													}}>
													<Text
														style={{
															fontSize:
																13 +
																this.state
																	.fontSize,
															color: '#FFF',
															fontWeight: '400',
															marginBottom: 5,
														}}>
														Object:
														{decodeURIComponent(
															this.state
																.editAssessmentData
																.book_link,
														)}
													</Text>
												</View>
											)}
										</TouchableOpacity>
									</View>

									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,

											marginTop: 20,
										}}>
										<View
											style={{
												flex: 1,
												justifyContent: 'space-between',
												flexDirection: 'row',
											}}>
											<Text
												allowFontScaling={false}
												style={{
													fontSize:
														16 +
														this.state.fontSize,
													fontFamily: 'Avenir Next',
													margin: 5,
													// paddingLeft: 10,
													fontWeight: '300',
												}}>
												Explanation
											</Text>

											<TouchableOpacity
												onPress={async () =>
													this.setState(
														(prevState) => ({
															editAssessmentData: {
																...prevState.editAssessmentData,
																explanation: '',
															},
															testChangesMade: true,
															explanationType: '',
														}),
													)
												}>
												<Image
													source={delete_}
													style={{
														width: 20,
														height: 20,
														resizeMode: 'contain',
													}}
												/>
											</TouchableOpacity>
										</View>

										{this.state.editAssessmentData
											.explanation !== '' ? (
											<View style={{}}>
												{isExplanationBase64String ? (
													<Image
														source={{
															uri: `data:image/png;${this.state.editAssessmentData.explanation}`,
														}}
														style={{
															width: 100,
															height: 100,
															resizeMode:
																'contain',
														}}
													/>
												) : (
													<SimpleEditor
														ref={this.DrafJSEditor}
														initialHtml={
															this.state
																.editAssessmentData
																.explanation
														}
														exportHTML={(html) => {
															this.setState(
																(
																	prevState,
																) => ({
																	editAssessmentData: {
																		...prevState.editAssessmentData,
																		explanation: html,
																	},
																	testChangesMade: true,
																}),
															);
														}}
													/>
												)}
											</View>
										) : this.state.explanationType == '' ? (
											<View>
												<Text
													allowFontScaling={false}
													style={{
														fontSize:
															16 +
															this.state.fontSize,
														fontFamily:
															'Avenir Next',
														margin: 5,
														// paddingLeft: 10,
														fontWeight: '300',
													}}>
													Select one of the following
													explanation types
												</Text>
												<View
													style={{
														flexDirection: 'row',
														flex: 1,
														width: '100%',
													}}>
													<TouchableOpacity
														style={{
															flex: 1,
															marginRight: 10,
														}}
														onPress={async () => {
															this.setState({
																explanationType:
																	'Text',
															});
														}}>
														<View
															style={{
																flex: 1,
																backgroundColor:
																	APP_COLOURS.BLUE,
																padding: 9,
																justifyContent:
																	'center',
																alignItems:
																	'center',
																borderRadius: 10,
															}}>
															<Text
																style={{
																	color:
																		APP_COLOURS.WHITE,
																	fontSize:
																		16 +
																		this
																			.state
																			.fontSize,
																}}>
																+ Text
															</Text>
														</View>
													</TouchableOpacity>
													<TouchableOpacity
														style={{
															flex: 1,
														}}
														onPress={async () => {
															this.setState({
																explanationType:
																	'Image',
															});
														}}>
														<View
															style={{
																flex: 1,
																backgroundColor:
																	APP_COLOURS.BLUE,
																padding: 9,
																justifyContent:
																	'center',
																alignItems:
																	'center',
																borderRadius: 10,
															}}>
															<Text
																style={{
																	color:
																		APP_COLOURS.WHITE,
																	fontSize:
																		16 +
																		this
																			.state
																			.fontSize,
																}}>
																+ Image
															</Text>
														</View>
													</TouchableOpacity>
												</View>
											</View>
										) : this.state.explanationType ==
										  'Image' ? (
											<View
												style={{
													flexDirection: 'row',
												}}>
												<View
													style={{
														padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
														// maxHeight: 60,
														marginRight: 10,
													}}>
													<Image
														source={pngUploader}
														style={{
															width: 90,
															height: 90,
															resizeMode:
																'contain',
															zIndex: 80,
															position:
																'absolute',
														}}
													/>
													<ImageUploader
														base64String={(
															base64String,
														) => {
															this.setState(
																(
																	prevState,
																) => ({
																	editAssessmentData: {
																		...prevState.editAssessmentData,
																		explanation: `base64,${base64String}`,
																	},
																	testChangesMade: true,
																	explanationType:
																		'',
																}),
															);
														}}
													/>
												</View>

												<View
													style={{
														// padding: 9,
														justifyContent:
															'center',
														alignItems: 'center',
														borderRadius: 10,
													}}>
													<Image
														source={psdUploader}
														style={{
															width: 90,
															height: 90,
															resizeMode:
																'contain',
															zIndex: 80,
															position:
																'absolute',
														}}
													/>
													<PSDUploader
														base64String={(
															base64String,
														) => {
															this.setState(
																(
																	prevState,
																) => ({
																	editAssessmentData: {
																		...prevState.editAssessmentData,
																		explanation: `base64,${base64String}`,
																	},
																	testChangesMade: true,
																	explanationType:
																		'',
																}),
															);
														}}
													/>
												</View>
											</View>
										) : (
											<SimpleEditor
												ref={this.DrafJSEditor}
												initialHtml={
													this.state
														.editAssessmentData
														.explanation
												}
												exportHTML={(html) => {
													this.setState(
														(prevState) => ({
															editAssessmentData: {
																...prevState.editAssessmentData,
																explanation: html,
															},
															testChangesMade: true,
														}),
													);
												}}
											/>
										)}

										{/* // <TextInput
										// 	style={{
										// 		width: '100%',
										// 		height: 'auto',
										// 		// minHeight: 80,
										// 		padding: 10,
										// 		marginBottom: 5,
										// 		marginTop: 5,
										// 		borderRadius: 8,
										// 		backgroundColor:
										// 			APP_COLOURS.WHITE,
										// 		// textAlign: 'center',
										// 		color: '#000',
										// 		borderWidth: 0.3,

										// 		fontSize:
										// 			16 + this.state.fontSize,
										// 		fontFamily: 'Avenir Next',
										// 		fontWeight: '500',
										// 	}}
										// 	placeholderTextColor={'#000'}
										// 	multiline
										// 	// numberOfLines={10}
										// 	autoCapitalize='words'
										// 	clearButtonMode='while-editing'
										// 	autoCompleteType='off'
										// 	editable={true}
										// 	autoCorrect={false}
										// 	keyboardType='default'
										// 	textContentType='none'
										// 	placeholder={`Explanation`}
										// 	defaultValue={
										// 		this.state.editAssessmentData
										// 			.explanation
										// 	}
										// 	// defaultValue={this.state.editItemsData.data}
										// 	onChangeText={async (text) => {
										// 		// let validated = this.validateEmail(text);
										// 		await this.setState(
										// 			(prevState) => ({
										// 				editAssessmentData: {
										// 					...prevState.editAssessmentData,
										// 					explanation: text,
										// 				},
										// 				testChangesMade: true,
										// 			}),
										// 		);
										// 	}}
										// />



										
										
										
										
										 */}
									</View>

									<View
										style={{
											width: '100%',
											height: 'auto',
											marginBottom: 20,
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												margin: 5,
												// paddingLeft: 10,
												fontWeight: '300',
											}}>
											Mark allocation
										</Text>
										{this.state.markMustBeNumber ? (
											<Text
												allowFontScaling={false}
												style={{
													fontSize:
														13 +
														this.state.fontSize,
													fontFamily: 'Avenir Next',
													color: 'red',
													margin: 5,
													// paddingLeft: 10,
													fontWeight: '300',
													fontStyle: 'italic',
												}}>
												Mark must be numeric.
											</Text>
										) : null}
										<TextInput
											style={{
												width: '100%',
												height: 'auto',
												// minHeight: 80,
												padding: 10,
												marginBottom: 5,
												marginTop: 5,
												borderRadius: 8,
												backgroundColor:
													APP_COLOURS.WHITE,
												// textAlign: 'center',
												color: '#000',
												borderWidth: 0.3,

												fontSize:
													16 + this.state.fontSize,
												fontFamily: 'Avenir Next',
												fontWeight: '500',
											}}
											placeholderTextColor={'#000'}
											multiline
											// numberOfLines={10}
											autoCapitalize='words'
											clearButtonMode='while-editing'
											autoCompleteType='off'
											editable={true}
											autoCorrect={false}
											keyboardType='default'
											textContentType='none'
											placeholder={`Mark allocation`}
											defaultValue={
												this.state.editAssessmentData
													.total
											}
											// defaultValue={this.state.editItemsData.data}
											onChangeText={async (text) => {
												// let validated = this.validateEmail(text);
												if (isNumeric(text)) {
													await this.setState(
														(prevState) => ({
															editAssessmentData: {
																...prevState.editAssessmentData,
																total: text,
															},
															testChangesMade: true,
															markMustBeNumber: false,
														}),
													);
												} else {
													this.setState({
														markMustBeNumber: true,
													});
												}
											}}
										/>
									</View>

									<TouchableOpacity
										onPress={async () => {
											let array = [];
											await array.push(
												this.state.editAssessmentData,
											);
											console.log(array);
											await this.setState({
												addedANewQuestion: false,
											});
											await this.updateTestContent(array);
										}}>
										<View
											style={{
												width: '100%',
												backgroundColor:
													APP_COLOURS.BLUE,
												padding: 9,
												justifyContent: 'center',
												alignItems: 'center',
												borderRadius: 10,
											}}>
											<Text
												style={{
													color: APP_COLOURS.WHITE,
													fontSize:
														16 +
														this.state.fontSize,
												}}>
												Update question
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							</View>
							{!this.state.openBookLinker ? null : (
								<View
									style={{
										flex: 1,
										justifyContent: 'center',
										alignItems: 'center',
										zIndex: 999,
										position: 'absolute',
										width: '100%',
										height: '100%',
									}}>
									{bookContent.length > 0 ? (
										<DevicePreviewer
											bookContent={bookContent}
											linkerActive={true}
											selectedLine={''}
											fontSize={this.state.fontSize}
											setBookLink={(uuid) =>
												this.setState((prevState) => ({
													editAssessmentData: {
														...prevState.editAssessmentData,
														book_link: uuid,
													},
													testChangesMade: true,
												}))
											}
											closePreview={() =>
												this.setState({
													openBookLinker: false,
												})
											}
										/>
									) : (
										<View
											style={{
												padding: 20,
												justifyContent: 'center',
												alignItems: 'center',
												backgroundColor: '#FFF',
												borderRadius: 15,
												maxWidth: 400,
												paddingLeft: 40,
											}}>
											<View
												style={{
													position: 'absolute',
													zIndex: 99,
													top: 10,
													left: 10,
												}}>
												<TouchableOpacity
													onPress={() =>
														this.setState({
															openBookLinker: false,
														})
													}>
													<Image
														source={close}
														style={{
															width: 20,
															height: 20,
															resizeMode:
																'contain',
														}}
													/>
												</TouchableOpacity>
											</View>
											<Text>
												Book content and chapter has not
												been selected.
											</Text>
										</View>
									)}
								</View>
							)}
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	updateTestContent = async (editAssessmentData) => {
		let url = api_create_test_contents;

		// editAssessmentData.uuid_test = this.state.selectedTest;

		// await console.log(editAssessmentData);
		// console.log(
		// 	'this.state.testContent.length',
		// 	this.state.testContent.length,
		// );
		// console.log(
		// 	'editAssessmentData.question_no',
		// 	editAssessmentData.question_no,
		// );
		// return;

		await this.setState({
			loading: true,
			updating: true,
		});

		let raw = await JSON.stringify(editAssessmentData);

		let requestOptions = {
			method: 'POST',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		// console.log(url, editAssessmentData);
		// return;

		await fetch(url, requestOptions)
			.then((response) => {
				// let ress = response
				// 	.json()
				// 	.then(async (responseData) => {
				// 		console.log('response', responseData);
				// 	})
				// 	.catch((error) => {
				// 		alert(error);
				// 		this.setState({
				// 			loadingTestContent: false,
				// 		});
				// 	});
				// console.log('response', ress);
				if (response.status == 200) {
					this.updatedQuestionModal();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert('error updating book');
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						updating: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
					updating: false,
				});
			});
	};

	updatedQuestionModal = async () => {
		await this.setState({
			ebookUpdated: true,
			updating: false,
			editAssessmentQuestion: false,
			addQuestionModal: false,
			editAssessmentData: '',
		});
		await this.getTestContent(this.state.selectedTestData);
		await setTimeout(() => {
			this.setState({
				ebookUpdated: false,
			});
		}, 2000);
	};

	deleteAssessment = async (uuid_test) => {
		let url = api_delete_test;

		await this.setState({
			updating: true,
			selectedTest: '',
			selectedTestTitle: '',
			selectedTestData: '',
		});

		let obj = {
			uuid: uuid_test,
		};

		let raw = JSON.stringify(obj);

		let requestOptions = {
			method: 'DELETE',
			body: raw,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Origin: '',
				dataType: 'json',
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: 'follow',
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log('response', response);
				if (response.status == 200) {
					this.setState({
						updating: false,
						updatingSuccess: true,
						ebookUpdated: true,
					});
					this.getAllBooks();
					setTimeout(() => {
						this.setState({
							ebookUpdated: false,
						});
					}, 3000);
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({
						errorUpdatingBook: true,
						loading: false,
						updating: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorUpdatingBook: true,
					loading: false,
				});
			});
	};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: 'xMidYMid slice',
			},
		};

		if (this.state.updating) {
			return (
				<div
					style={{
						flex: 1,
						overflow: '-moz-hidden-unscrollable',
						backgroundColor: APP_COLOURS.WHITE,
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					{/* <View
						style={{
							position: 'absolute',
							zIndex: 99,
						}}>
						<Lottie
							options={defaultOptions}
							height={200}
							width={200}
						/>
					</View> */}
					<View
						style={{
							flex: 1,
							zIndex: 98,
							justifyContent: 'center',
							alignItems: 'center',
							padding: 10,
							width: '100%',
							height: '100%',
						}}>
						<Image
							source={logo}
							style={{
								width: 100,
								height: 100,
								resizeMode: 'contain',
							}}
						/>
						<Text
							style={{
								marginBottom: 5,
							}}>
							Updating eBook...
						</Text>
						<ActivityIndicator size='small' />
					</View>
				</div>
			);
		}
		return (
			<div
				style={{
					overflow: '-moz-hidden-unscrollable',
					flex: 1,
					padding: 8,
					width: '100%',
					height: '100%',
				}}>
				<View
					style={{
						// alignItems: 'center',
						// justifyContent: 'center',
						width: '100%',
						borderBottomWidth: 0.3,
						borderBottomColor: '#C5C5C5',
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 18,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '700',
						}}>
						Books
					</Text>
				</View>
				{/* <Fade> */}

				{this.state.editAssessmentQuestion
					? this.editAssessmentQuestion(this.state.editAssessmentData)
					: null}

				<View
					style={{
						// flex: 1,
						// padding: 20,
						// marginTop: 20,
						minWidth: 500,

						width: '100%',
						height: '100%',
					}}>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							marginBottom: 15,
							justifyContent: 'space-between',
							// height: '100%',
						}}>
						<View
							style={{
								flexDirection: 'row',
								alignItems: 'center',
							}}>
							<View
								style={{
									flexDirection: 'row',
									alignItems: 'center',
									marginRight: 10,
									width: 150,
									height: 40,
								}}>
								<Text
									style={{
										fontFamily: 'Avenir',
										fontSize: 16 + this.state.fontSize,
										color: APP_COLOURS.TEXTCOLOR,
										fontWeight: '500',
									}}>
									{this.state.selectedCategory == ''
										? 'All'
										: this.state.selectedCategory}{' '}
									books{' '}
									{this.state.fetching
										? null
										: `(${this.state.books.length})`}
								</Text>
								{this.state.fetching ? (
									<ActivityIndicator size='small' />
								) : null}
							</View>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										selectedCategory: '',
									})
								}
								style={{
									borderRadius: 10,
									height: 'auto',
									// marginBottom: 10,
									width: 80,
									backgroundColor:
										this.state.selectedCategory == ''
											? APP_COLOURS.WHITE
											: APP_COLOURS.OFFWHITE,
									flexDirection: 'row',
									borderWidth:
										this.state.selectedCategory == ''
											? 0.3
											: 0,
									borderColor: '#C5C5C5',
									// shadowColor: '#ECF4FF',
									// shadowOffset: {
									// 	width: 0,
									// 	height: 8,
									// },
									// shadowOpacity: 0.44,
									// shadowRadius: 10.32,

									// elevation: 16,

									justifyContent: 'center',
									alignItems: 'center',
									padding: 5,
								}}>
								<Text
									style={{
										fontFamily: 'Avenir',
										fontSize: 16 + this.state.fontSize,
										color: APP_COLOURS.TEXTCOLOR,
										fontWeight: '500',
									}}>
									All
								</Text>
							</TouchableOpacity>
							{this.state.categories.map((d, i) => (
								<TouchableOpacity
									key={i}
									onPress={() =>
										this.setState({
											selectedCategory: d,
										})
									}
									style={{
										borderRadius: 10,
										height: 'auto',
										// marginBottom: 10,
										marginLeft: 10,
										width: 'auto',
										backgroundColor:
											this.state.selectedCategory == d
												? APP_COLOURS.WHITE
												: APP_COLOURS.OFFWHITE,
										flexDirection: 'row',
										borderWidth:
											this.state.selectedCategory == d
												? 0.3
												: 0,
										borderColor: '#C5C5C5',
										shadowColor: '#ECF4FF',
										shadowOffset: {
											width: 0,
											height: 8,
										},
										shadowOpacity: 0.44,
										shadowRadius: 10.32,

										elevation: 16,
										alignItems: 'center',
										justifyContent: 'center',
										padding: 5,
										paddingRight: 30,
									}}>
									<View
										style={{
											width: 5,
											borderRadius: 4,
											height: '100%',
											backgroundColor:
												this.state.selectedCategory == d
													? APP_COLOURS.RED
													: APP_COLOURS.WHITE,
											marginRight: 12,
										}}
									/>
									<Text
										style={{
											fontFamily: 'Avenir',
											fontSize: 16 + this.state.fontSize,
											color: APP_COLOURS.TEXTCOLOR,
											fontWeight: '500',
										}}>
										{d}
									</Text>
								</TouchableOpacity>
							))}
						</View>
						<View
							style={{
								flexDirection: 'row',
								padding: 2,
								alignItems: 'center',
								paddingRight: 5,
							}}>
							<View
								style={{
									flexDirection: 'row',
									padding: 2,
									alignItems: 'center',
									marginRight: 5,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											fontSize: this.state.fontSize - 1,
										})
									}>
									<View
										style={{
											width: 20 + this.state.fontSize,
											height: 20 + this.state.fontSize,
											backgroundColor:
												APP_COLOURS.TEXTINPUT,
											// padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize:
													17 + this.state.fontSize,
											}}>
											-
										</Text>
									</View>
								</TouchableOpacity>

								<View
									style={{
										padding: 9,
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.TEXTINPUT,
											fontSize: 17 + this.state.fontSize,
											fontWeight: '500',
										}}>
										Aa
									</Text>
								</View>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											fontSize: this.state.fontSize + 1,
										})
									}>
									<View
										style={{
											width: 20 + this.state.fontSize,
											height: 20 + this.state.fontSize,
											backgroundColor:
												APP_COLOURS.TEXTINPUT,
											// padding: 9,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.WHITE,
												fontSize:
													17 + this.state.fontSize,
											}}>
											+
										</Text>
									</View>
								</TouchableOpacity>
							</View>
							<TouchableOpacity
								onPress={() => this.refreshBooks()}>
								<View
									style={{
										width: 100,
										backgroundColor: APP_COLOURS.TEXTINPUT,
										padding: 9,
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
											fontSize: 12 + this.state.fontSize,
										}}>
										Refresh
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							flexDirection: 'row',
						}}>
						<View
							style={{
								width: 220,
								// padding: 10,
								paddingLeft: 0,
								height: this.state.screenHeight - 130,
							}}>
							<Text
								numberOfLines={2}
								style={{
									fontFamily: 'Avenir',
									fontSize: 16 + this.state.fontSize,
									opacity: 0.8,
									flexWrap: 'wrap',
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 10,
								}}>
								Books
							</Text>
							<View
								style={{
									flex: 1,
								}}>
								<ScrollView>
									{this.state.books
										.filter((data) => {
											if (
												this.state.selectedCategory !==
												''
											) {
												if (
													data.category ==
													this.state.selectedCategory
												) {
													return data;
												}
											} else {
												return data;
											}
										})
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												style={{
													width: '100%',
													height: 'auto',
													padding: 7,
													alignItems: 'center',
													// borderRadius: 8,
													height: 'auto',
													marginBottom: 1,
													width: '100%',
													backgroundColor:
														this.state
															.selectedBookId ==
														d.ebook_id
															? APP_COLOURS.WHITE
															: APP_COLOURS.OFFWHITE,
													flexDirection: 'row',
													borderWidth:
														this.state
															.selectedBookId ==
														d.ebook_id
															? 0.3
															: 0,
													borderColor: '#C5C5C5',
													// shadowColor: '#ECF4FF',
													// shadowOffset: {
													// 	width: 0,
													// 	height: 8,
													// },
													// shadowOpacity: 0.44,
													// shadowRadius: 10.32,

													// elevation: 16,
												}}
												onPress={() =>
													this.setState({
														selectedBookId:
															d.ebook_id,
														changesMade: false,
														currentBook: d,
													})
												}>
												<View
													style={{
														width: 5,
														borderRadius: 4,
														height: '100%',
														backgroundColor:
															this.state
																.selectedBookId ==
															d.ebook_id
																? APP_COLOURS.RED
																: APP_COLOURS.WHITE,
														marginRight: 12,
													}}
												/>
												<View
													style={{
														flex: 1,
														flexWrap: 'wrap',
														padding: 4,
														borderRadius: 10,
														backgroundColor:
															this.state
																.selectedBookId ==
															d.ebook_id
																? APP_COLOURS.WHITE
																: APP_COLOURS.OFFWHITE,
														// justifyContent: 'center',
														// alignItems: 'center',
													}}>
													<Text
														numberOfLines={2}
														style={{
															fontFamily:
																'Avenir',
															fontSize:
																16 +
																this.state
																	.fontSize,
															// opacity: 0.8,
															flex: 1,
															flexWrap: 'wrap',
															color:
																APP_COLOURS.TEXTCOLOR,
														}}>
														{d.book_name}
													</Text>
												</View>
											</TouchableOpacity>
										))}

									<Text
										numberOfLines={2}
										style={{
											fontFamily: 'Avenir',
											fontSize: 16 + this.state.fontSize,
											opacity: 0.8,
											flexWrap: 'wrap',
											color: APP_COLOURS.TEXTCOLOR,
											marginTop: 20,
										}}>
										Other
									</Text>
									<TouchableOpacity
										style={{
											width: '100%',
											height: 'auto',
											padding: 7,
											alignItems: 'center',
											borderRadius: 10,
											height: 'auto',
											marginTop: 10,
											width: '100%',
											backgroundColor: APP_COLOURS.WHITE,
											flexDirection: 'row',
											borderWidth: 0.3,
											borderColor: '#C5C5C5',
										}}
										onPress={() => this.newBook()}>
										{/* <View
													style={{
														width: 5,
														borderRadius: 4,
														height: '100%',
														backgroundColor:
															this.state
																.selectedBookId ==
															d.ebook_id
																? APP_COLOURS.RED
																: APP_COLOURS.WHITE,
														marginRight: 20,
													}}
												/> */}
										<View
											style={{
												flex: 1,
												flexWrap: 'wrap',
												padding: 4,
												borderRadius: 10,
												backgroundColor:
													APP_COLOURS.WHITE,
											}}>
											<Text
												numberOfLines={2}
												style={{
													fontFamily: 'Avenir',
													fontSize:
														16 +
														this.state.fontSize,
													// opacity: 0.8,
													flex: 1,
													flexWrap: 'wrap',
													color:
														APP_COLOURS.TEXTCOLOR,
												}}>
												+ Create new book
											</Text>
										</View>
									</TouchableOpacity>
								</ScrollView>
							</View>
						</View>
						<View
							style={{
								height: this.state.screenHeight - 130,
								flex: 1,
								paddingLeft: 10,
								// borderWidth: 0.5,
								borderRadius: 5,
								display:
									this.state.selectedBookId == ''
										? 'none'
										: 'flex',

								// height: '100%',
							}}>
							{this.state.books
								.filter(
									(d_) =>
										d_.ebook_id ===
										this.state.selectedBookId,
								)
								.map((d, i) => (
									<View key={i} style={{}}>
										<Text
											style={{
												fontFamily: 'Avenir',
												fontSize:
													16 + this.state.fontSize,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: '600',
												marginBottom: 5,
											}}>
											{d.book_name}
										</Text>
									</View>
								))}
							<View
								style={{
									flexDirection: 'row',
									display:
										this.state.selectedBookId == ''
											? 'none'
											: 'flex',
									marginBottom: 5,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											book_tabs: 'overview',
										})
									}>
									<View
										style={{
											width: 100,
											backgroundColor:
												this.state.book_tabs ==
												'overview'
													? APP_COLOURS.BLUE
													: APP_COLOURS.OFFWHITE,
											padding: 7,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 8,
										}}>
										<Text
											style={{
												fontSize: 12,
												color:
													this.state.book_tabs ==
													'overview'
														? APP_COLOURS.WHITE
														: APP_COLOURS.BLUE,
											}}>
											Overview
										</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											book_tabs: 'edit',
										})
									}>
									<View
										style={{
											width: 100,
											backgroundColor:
												this.state.book_tabs == 'edit'
													? APP_COLOURS.BLUE
													: APP_COLOURS.OFFWHITE,
											padding: 7,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 8,
											marginLeft: 10,
										}}>
										<Text
											style={{
												fontSize: 12,
												color:
													this.state.book_tabs ==
													'edit'
														? APP_COLOURS.WHITE
														: APP_COLOURS.BLUE,
											}}>
											Edit
										</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											book_tabs: 'Chapters',
										})
									}>
									<View
										style={{
											width: 100,
											backgroundColor:
												this.state.book_tabs ==
												'Chapters'
													? APP_COLOURS.BLUE
													: APP_COLOURS.OFFWHITE,
											padding: 7,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 8,
											marginLeft: 10,
										}}>
										<Text
											style={{
												fontSize: 12,
												color:
													this.state.book_tabs ==
													'Chapters'
														? APP_COLOURS.WHITE
														: APP_COLOURS.BLUE,
											}}>
											Chapters
										</Text>
									</View>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											book_tabs: 'Assessments',
										})
									}>
									<View
										style={{
											width: 100,
											backgroundColor:
												this.state.book_tabs ==
												'Assessments'
													? APP_COLOURS.BLUE
													: APP_COLOURS.OFFWHITE,
											padding: 7,
											justifyContent: 'center',
											alignItems: 'center',
											borderRadius: 8,
											marginLeft: 10,
										}}>
										<Text
											style={{
												fontSize: 12,
												color:
													this.state.book_tabs ==
													'Assessments'
														? APP_COLOURS.WHITE
														: APP_COLOURS.BLUE,
											}}>
											Assessments
										</Text>
									</View>
								</TouchableOpacity>
							</View>

							<View
								style={{
									flex: 1,
									// height: '100%',
								}}>
								<ScrollView
									style={{
										flex: 1,

										// height: '100%',
									}}>
									<View
										style={{
											flex: 1,
											padding: 10,
											borderWidth: 0.5,
											borderColor: '#C5C5C5',
											borderRadius: 5,
											// height: '100%',
										}}>
										{this.state.books
											.filter(
												(d_) =>
													d_.ebook_id ===
													this.state.selectedBookId,
											)
											.map((d, i) => (
												<View
													key={i}
													style={{
														flex: 1,
														// backgroundColor: 'red',
														// height: '100%',
													}}>
													{this.state.book_tabs ==
													'overview'
														? this.overviewComponent(
																d,
														  )
														: null}

													{this.state.book_tabs ==
													'edit'
														? this.editComponent(d)
														: null}

													{this.state.book_tabs ==
													'Chapters'
														? this.chaptersComponent(
																d,
														  )
														: null}

													{this.state.book_tabs ==
													'Assessments'
														? this.assessmentsComponent(
																d,
														  )
														: null}
												</View>
											))}
									</View>
								</ScrollView>
							</View>
						</View>
					</View>
				</View>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.ebookDeleted}>
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(52, 52, 52, 0.6)',
						}}>
						<View
							style={{
								width: 300,
								height: 'auto',
								backgroundColor: 'white',
								borderRadius: 20,
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<View
								style={{
									width: '100%',
									padding: 20,
									height: 'auto',
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 22,
										fontFamily: 'Avenir Next',
										textAlign: 'center',
										fontWeight: '700',
									}}>
									eBook deleted!
								</Text>
							</View>
						</View>
					</View>
				</Modal>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.ebookCreated}>
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(52, 52, 52, 0.6)',
						}}>
						<View
							style={{
								width: 300,
								height: 'auto',
								backgroundColor: 'white',
								borderRadius: 20,
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							{/* <LottieView
                style={{
                  width: 150,
                  height: 150,
                }}
                source={require('../../assets/lottie/6640-times-up.json')}
                autoPlay
                loop={false}
              /> */}

							<View
								style={{
									width: '100%',
									padding: 20,
									height: 'auto',
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 22,
										fontFamily: 'Avenir Next',
										textAlign: 'center',
										fontWeight: '700',
									}}>
									eBook created!
								</Text>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 16 + this.state.fontSize,
										fontFamily: 'Avenir Next',
										textAlign: 'center',
									}}>
									You can now add content to the new book
								</Text>
							</View>

							{/* <View
										style={{
											width: '80%',
											height: 40,
											flexDirection: 'row',
											justifyContent: 'space-between',
											marginBottom: 20,
										}}>
										<TouchableOpacity
											style={{
												flex: 1,
												backgroundColor: '#0567CB',
												justifyContent: 'center',
												borderRadius: 20,
											}}
											// onPress={() => {
											// 	this.setState({
											// 		timeExpired: false,
											// 	}),
											// 		this.completeAssessment();
											// }}
										>
											<Text
												allowFontScaling={false}
												style={{
													width: '100%',
													textAlign: 'center',
													color: 'white',
													fontSize: 16 + this.state.fontSize,
													fontWeight: '600',
												}}>
												Results
											</Text>
										</TouchableOpacity>
									</View> */}
						</View>
					</View>
				</Modal>

				<Modal
					animationType='fade'
					transparent={true}
					visible={this.state.ebookUpdated}>
					<View
						style={{
							flex: 1,
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: 'rgba(52, 52, 52, 0.6)',
						}}>
						<View
							style={{
								width: 300,
								height: 'auto',
								backgroundColor: 'white',
								borderRadius: 20,
								justifyContent: 'center',
								alignItems: 'center',
							}}>
							<View
								style={{
									width: '100%',
									padding: 20,
									height: 'auto',
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 16 + this.state.fontSize,
										fontFamily: 'Avenir Next',
										textAlign: 'center',
										fontWeight: '700',
										marginBottom: 10,
									}}>
									eBook updated!
								</Text>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 16 + this.state.fontSize,
										fontFamily: 'Avenir Next',
										textAlign: 'justify',
										marginBottom: 10,
									}}>
									Please note: Students will be required to
									manually download the new content.
								</Text>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 16 + this.state.fontSize,
										fontFamily: 'Avenir Next',
										textAlign: 'justify',
										marginBottom: 10,
									}}>
									For more information, please contact tech
									support.
								</Text>
							</View>
						</View>
					</View>
				</Modal>

				{/* </Fade> */}
			</div>
		);
	}
}

export default index;
