// import React, { useState } from 'react';
// import { MathJax, MathJaxContext } from 'better-react-mathjax';
// import { View } from 'react-native';

// const config = {
// 	loader: { load: ['[tex]/html'] },
// 	tex: {
// 		packages: { '[+]': ['html'] },
// 		inlineMath: [
// 			['$', '$'],
// 			['\\(', '\\)'],
// 		],
// 		displayMath: [
// 			['$$', '$$'],
// 			['\\[', '\\]'],
// 		],
// 	},
// };

// export default function App(props) {
// 	const [num1, setNum1] = useState(10);
// 	const [num2, setNum2] = useState(10);

// 	return (
// 		<View
// 			style={{
// 				flex: 1,
// 			}}>
// 			<MathJaxContext version={3} config={config}>
// 				<MathJax hideUntilTypeset={'first'}>
// 					{`Inside a Math block element, one might use both Latex inline math, such
//           as \\(x\\) or \\(\\frac{25x}{10} = 2^{10}\\), but then also switch
//           to Latex display math, like
//           \\[\\sum_{n = 100}^{1000}\\left(\\frac{10\\sqrt{n}}{n}\\right)\\]
//           ... and then continue with inline math.`}
// 				</MathJax>
// 			</MathJaxContext>

// 			<MathJaxContext version={3} config={config}>
// 				<MathJax hideUntilTypeset={'first'}>
// 					{this.props.formula}
//                     {`°F = 100°C x 1.8 + 32 = 212°F  \\(\\frac{9}{5} = 1.8\\)`}
// 				</MathJax>
// 			</MathJaxContext>
// 		</View>
// 	);
// }

import React, { Component } from 'react';
import { View, Text } from 'react-native';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const config = {
	loader: { load: ['[tex]/html'] },
	tex: {
		packages: { '[+]': ['html'] },
		inlineMath: [
			['$', '$'],
			['\\(', '\\)'],
		],
		displayMath: [
			['$$', '$$'],
			['\\[', '\\]'],
		],
	},
};

class MathFormula extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
				}}>
				{/* <MathJaxContext version={3} config={config}>
					<MathJax hideUntilTypeset={'first'}>
						{`Inside a Math block element, one might use both Latex inline math, such
          as \\(x\\) or \\(\\frac{25x}{10} = 2^{10}\\), but then also switch
          to Latex display math, like
          \\[\\sum_{n = 100}^{1000}\\left(\\frac{10\\sqrt{n}}{n}\\right)\\]
          ... and then continue with inline math.`}
					</MathJax>
				</MathJaxContext> */}

				<MathJaxContext version={3} config={config}>
					<MathJax hideUntilTypeset={'first'}>
						{this.props.formula}
						{/* {`°F = 100°C x 1.8 + 32 = 212°F  \\(\\frac{9}{5} = 1.8\\)`} */}
						{/* $7\dfrac{4}{3}$ */}
						{/* °F = 100°C x 1.8 + 32 = 212°F  $(\frac{9}{5} = 1.8$) */}
						{/* °F= 100°   $\frac{9}{5} +$ 32° = 212°F */}
					</MathJax>
				</MathJaxContext>
			</View>
		);
	}
}

export default MathFormula;
