// let e = `http://167.99.245.216/index.php`;
let e = `https://api.avex.cloud/index.php`;

export const api_login = e + "/user_management/manage/login";
// export const api_login =
// 	'https://api-intelliuation.com/api_v2/index.php/user_management/manage/login';
// export const api_signup = e + '/proshop/user/signup';
// export const course_data = e + '/coursedata/'; // by course id

// export const course_create_tees = e + '/coursedata/new_course_tee';
// export const course_create_pins = e + '/coursedata/new_course_pins';
// export const course_updateholedata = e + '/holedata/update';
// export const course_updatepinsheet = e + '/pinsheet/update';
export const api_get_all_books = e + "/book/manage/get_all_books/";
export const api_update_ebook = e + "/book/manage/update_ebook";

//
//

export const api_create_ebook = e + "/book/manage/create_ebook/";
export const api_delete_book_content = e + "/book/manage/delete_book_content";

export const api_book_line_uploader = e + "/book/manage/book_line_uploader/";

export const api_initiate_test = e + "/test_management/manage/initiate_test";

export const api_get_all_tests_content =
	e + "/test_management/manage/get_all_tests_content/";

export const api_delete_test = e + "/test_management/manage/delete_test";

export const api_create_test_contents =
	e + "/test_management/manage/create_test_contents";

export const api_get_book_content =
	e + "/book/manage/get_book_line_content_by_id/";

export const api_delete_line_contents = e + "/book/manage/delete_line_content/";

export const api_remove_line_content_chapter =
	e + "/book/manage/remove_line_content_chapter";

export const api_getallusers =
	e + "/user_management/admin_manage/get_all_users";
//

export const api_getall_purchases =
	e + "/book_store_management/purchases/index";
