import React, { Component } from 'react';
import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import * as XLSX from 'xlsx';

class ExcelToJson extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.state = {
			file: '',
			csv: [],
		};
	}

	handleClick(e) {
		this.refs.fileUploader.click();
	}

	filePathset(e) {
		e.stopPropagation();
		e.preventDefault();
		var file = e.target.files[0];
		console.log(file);
		this.setState({ file });

		// console.log(this.state.file);
		this.readFile(file);
	}

	readFile(f) {
		var name = f.name;
		const reader = new FileReader();
		reader.onload = (evt) => {
			// evt = on_file_select event
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: 'binary' });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws);
			// const data = XLSX.utils.sheet_to_json(ws);
			/* Update state */
			console.log(data); // shows that excel data is read
			console.log(this.convertToJson(data)); // shows data in json format
		};
		reader.readAsBinaryString(f);
	}

	convertToJson(csv) {
		let assessment_uuid = this.props.assessment_uuid;
		var lines = csv.split('\n');

		var result = [];

		let testLength = this.props.testLength;
		let question_number = testLength + 1;
		for (var i = 0; i < lines.length; i++) {
			let c = lines[i].split(',');

			if (c[0] !== '') {
				// ALL COLUMNS ARE STRUCTURED  c[colum number]
				//
				//
				//
				let options = [
					{
						[1]: c[1],
					},
					{
						[2]: c[2],
					},
					{
						[3]: c[3],
					},
					{
						[4]: c[4],
					},
				];

				let obj = {
					question: c[0],
					options: options,
					answer: c[5],
					uuid: '',
					uuid_test: assessment_uuid,
					question_no: question_number,
					type: 'radio',
					total: 1,
					created: '2021-10-02 13:07:50',
					updated: '2021-10-02 13:07:50',
					book_link: 'No book link allocated',
					explanation: c[6],
				};
				console.log(obj);
				question_number++;
				result.push(obj);
			}
		}
		this.setState({
			csv: result,
		});
		//
	}

	render() {
		return (
			<div
				unselectable='on'
				onselectstart='return false;'
				onmousedown='return false;'>
				<label htmlFor={'file'}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							textAlign: 'center',
							flexDirection: 'row',
							width: '100%',

							height: 50,

							borderStyle: 'dashed',
							borderWidth: 1,
							borderColor: 'green',
							borderRadius: 5,
							color: 'green',
						}}>
						{this.state.file !== ''
							? this.state.file.name
							: `Upload (.xlsx, .csv)`}
					</div>
				</label>
				<input
					type='file'
					id='file'
					ref='fileUploader'
					onChange={this.filePathset.bind(this)}
					style={{
						display: 'none',
					}}
				/>

				<View
					style={{
						width: '100%',
						height: 'auto',
						maxHeight: 300,
						marginTop: 20,
					}}>
					<ScrollView>
						{this.state.csv.map((d, i) => (
							<View
								style={{
									width: '100%',
									height: 'auto',
									marginBottom: 10,
									borderBottomWidth: 0.5,
									paddingBottom: 10,
								}}>
								<Text
									style={{
										fontSize: 12,
										fontWeight: '500',
										marginBottom: 4,
									}}>
									Question {i + 1})
								</Text>
								<Text
									style={{
										fontSize: 12,
										marginBottom: 4,
									}}>
									{d.question}
								</Text>
								<View>
									{d.options.map((op, ind) => {
										let answer = parseInt(d.answer);
										// console.log(answer);
										if (!isNaN(answer)) {
											return (
												<Text
													style={{
														fontSize: 11,
														fontWeight:
															answer == ind + 1
																? '600'
																: '300',
													}}>
													{Object.keys(op)}){' '}
													{op[Object.keys(op)]}
												</Text>
											);
										} else {
											return (
												<Text
													style={{
														fontSize: 11,
														color: 'red',
														fontStyle: 'italic',
													}}>
													Error with aswer format
												</Text>
											);
										}
									})}
								</View>

								<Text
									style={{
										fontSize: 10,
										marginBottom: 4,
										marginTop: 5,
									}}>
									{d.explanation}
								</Text>
							</View>
						))}
					</ScrollView>
				</View>
				{this.state.csv.length > 0 ? (
					<TouchableOpacity
						onPress={async () =>
							this.props.jsonReturn(this.state.csv)
						}>
						<View
							style={{
								width: '100%',
								backgroundColor: 'green',
								padding: 9,
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: 10,
								marginTop: 10,
							}}>
							<Text
								style={{
									color: 'white',
									fontSize: 15,
								}}>
								Add questions
							</Text>
						</View>
					</TouchableOpacity>
				) : null}
			</div>
		);
	}
}

export default ExcelToJson;

// import React, { useState } from 'react';
// import { FileUploader } from 'react-drag-drop-files';

// const fileTypes = ['CSV', 'XLSX'];

// function DragDrop() {
// 	const [file, setFile] = useState(null);
// 	const handleChange = (file) => {
// 		setFile(file);

// 		//  sheet_to_json
// 		//  sheet_to_csv
// 	};
// 	return (
// 		<FileUploader
// 			handleChange={handleChange}
// 			name='file'
// 			types={fileTypes}
// 			hoverTitle='Drop here'
// 		/>
// 	);
// }

// export default DragDrop;

// import React, { Component } from 'react';
// import { View, Text } from 'react-native';
// import { FileUploader } from 'react-drag-drop-files';

// import * as XLSX from 'xlsx';

// const fileTypes = [];

// class CSVDrag extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			file: '',
// 		};
// 	}

// 	handleChange = (file) => {
// 		// const workbook = XLSX.read(res, { type: 'binary' });
// 		// console.log(workbook);
// 		//  sheet_to_json
// 		//  sheet_to_csv
// 		this.readFile(file);
// 	};

// 	filePathset(e) {
// 		e.stopPropagation();
// 		e.preventDefault();
// 		var file = e.target.files[0];
// 		console.log(file);
// 		this.setState({ file });

// 		console.log(this.state.file);
// 	}

// 	readFile(file) {
// 		var f = file;
// 		var name = f.name;
// 		const reader = new FileReader();
// 		reader.onload = (evt) => {
// 			// evt = on_file_select event
// 			/* Parse data */
// 			const bstr = evt.target.result;
// 			const wb = XLSX.read(bstr, { type: 'binary' });
// 			/* Get first worksheet */
// 			const wsname = wb.SheetNames[0];
// 			const ws = wb.Sheets[wsname];
// 			/* Convert array of arrays */
// 			const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
// 			/* Update state */
// 			console.log('Data =====' + data); // shows that excel data is read
// 			console.log(this.convertToJson(data)); // shows data in json format
// 		};
// 		reader.readAsBinaryString(f);
// 	}

// 	convertToJson(csv) {
// 		var lines = csv.split('\n');

// 		var result = [];

// 		var headers = lines[0].split(',');

// 		for (var i = 1; i < lines.length; i++) {
// 			var obj = {};
// 			var currentline = lines[i].split(',');

// 			for (var j = 0; j < headers.length; j++) {
// 				obj[headers[j]] = currentline[j];
// 			}

// 			result.push(obj);
// 		}

// 		//return result; //JavaScript object
// 		return JSON.stringify(result); //JSON
// 	}

// 	render() {
// 		return (
// 			<View>
// 				<FileUploader
// 					handleChange={this.filePathset.bind(this)}
// 					// handleChange={this.filePathset.bind(this)}
// 					name='file'
// 					types={fileTypes}
// 					hoverTitle='Drop here'
// 				/>
// 			</View>
// 		);
// 	}
// }

// export default CSVDrag;
