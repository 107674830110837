import React, { Component } from "react";
import {
	View,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default class Bundles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			oo: {
				id: "2b0de6f7-146f-4c74-aef9-b55e274bf517",
				bundle_name: "PPL Aeroplane Bundle",
				bundle_price: "1200",
			},
		};
	}

	componentDidUpdate(prevProps) {
		// console.log(prevProps.bundles);
	}

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;
		return (
			<View
				style={{
					flex: 1,
				}}>
				<ScrollView>
					{this.props.bundles.map((d, i) => (
						<View
							key={i}
							style={{
								flex: 1,
								// opacity:
								// 	this.state.viewMore === ""
								// 		? 1
								// 		: this.state.viewMore === d.id
								// 		? 1
								// 		: 0.5,
							}}>
							<View
								key={i}
								onMouseEnter={() =>
									this.setState({
										hover: i,
									})
								}
								onMouseLeave={() =>
									this.setState({
										hover: -1,
									})
								}
								style={{
									flex: 1,
									opacity: this.state.hover === i ? 1 : 0.9,
									flexDirection: "row",
									height: "auto",
									padding: 7,
									alignItems: "center",
									borderRadius: 8,
									marginRight: 10,
									height: "auto",
									marginBottom: 2,
									backgroundColor:
										this.state.hover == i
											? APP_COLOURS.WHITE
											: APP_COLOURS.OFFWHITE,
									flexDirection: "row",
									// borderWidth:
									// 	this.state.hover ==
									// 	i
									// 		? 0.3
									// 		: 0,
									borderColor: "#C5C5C5",
								}}>
								<View
									style={{
										marginRight: 5,
									}}>
									<Image
										source={{
											uri: `${d.bundle_cover}`,
										}}
										style={{
											width: 120,
											height: 150,
											resizeMode: "contain",
											borderWidth: 0.3,
										}}
									/>
								</View>
								<View>
									<View
										style={{
											// width: ,
											flexDirection: "row",
										}}>
										<View
											style={{
												// width: ,
												flexDirection: "row",
												borderRadius: 9,
												borderWidth: 0.3,
												padding: 5,
												marginRight: 10,
											}}>
											<Text
												style={{
													fontSize: 12,
													fontWeight: "600",
												}}>
												{d.bundle_category}
											</Text>
										</View>

										<View
											style={{
												width: 350,
											}}>
											<Text
												style={{
													fontSize: 15,
												}}>
												{d.bundle_name}
											</Text>
											<Text
												style={{
													opacity: 0.5,
													fontSize: 9,
												}}>
												{d.id}
											</Text>
										</View>

										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={
													{
														// fontSize: 7,
													}
												}>
												{currencyFormat(
													parseFloat(d.bundle_price),
												)}
											</Text>
										</View>
									</View>

									<View
										style={{
											width: 350,
											marginTop: 10,
										}}>
										{d.book_ids.map((dd, ii) => {
											let bookID =
												this.props.books.filter(
													(da) => da.ebook_id == dd,
												);

											if (bookID.length > 0)
												return (
													<Text
														key={ii}
														style={{
															fontSize: 12,
														}}>
														- {bookID[0].book_name}
													</Text>
												);
										})}
									</View>
								</View>
								<View
									style={{
										width: col2,
										marginLeft: 10,
									}}>
									<View style={{}}>
										<Text
											style={{
												fontSize: 9,
												opacity: 0.5,
											}}>
											android_product_id:
										</Text>
										<Text
											style={{
												fontSize: 11,
											}}>
											- {d.android_product_id}
										</Text>
									</View>
									<View style={{}}>
										<Text
											style={{
												fontSize: 9,
												opacity: 0.5,
											}}>
											android_product_id:
										</Text>
										<Text
											style={{
												fontSize: 11,
											}}>
											- {d.ios_product_id}
										</Text>
									</View>
								</View>
								{/* <View
									style={{
										width: col2,
									}}>
									<Text
										style={{
											fontSize: 9,
											opacity: 0.5,
										}}>
										publish date:
									</Text>
									<Text
										style={{
											fontSize: 11,
										}}>
										{d.publish_date}
									</Text>
								</View> */}
								{/* <View
									style={{
										width: col2,
									}}>
									<Text
										style={{
											fontSize: 9,
											opacity: 0.5,
										}}>
										chapters:
									</Text>
									<Text
										style={{
											fontSize: 11,
										}}>
										{d.chapters.length}
									</Text>
								</View> */}
							</View>
						</View>
					))}
					<View
						style={{
							height: 100,
						}}
					/>
				</ScrollView>
			</View>
		);
	}
}
