import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
	View,
	Text,
	Dimensions,
	Image,
	TouchableOpacity,
	ScrollView,
} from "react-native";
import Lottie from "react-lottie";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Font from "react-font";

import { APP_COLOURS } from "../APP_VARS";
import * as animationData from "./../../assets/lottie/lf30_editor_t3bgbljb.json";
import * as animationData2 from "../../assets/lottie/lf20_q8buwK.json";

import Profile from "./Profile";
import Books from "./Books";

import logo from "./../../assets/images/logo.png";
import female from "./../../assets/images/female.png";

import HomeTESTPAGE from "./HomeTESTPAGE";
import UserManagement from "./UserManagement/index";
import Store from "./Store/Store";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: "",
			email: "",
			auth_token: "",
			refresh_token: "",
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			authenticating: true,
			validated: true,
			hover: true,
			currentPage: "store",
			pages: [
				// {
				// 	title: 'Dashboard',
				// 	page: 'dashboard',
				// },
				{ title: "Books", page: "books" },
				// { title: 'Assessments', page: 'assessments' },
				{ title: "Store", page: "store" },
				{ title: "User management", page: "usermanagement" },
				{ title: "Flight schools", page: "flightschool" },
				// { title: 'Reports', page: 'reports' },
				{ title: "Tech support", page: "techsupport" },
			],
		};
	}

	componentDidMount() {
		this.validateSession();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();

		// test to see if updting
		// another line
		// from mac 2
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let auth_token = await sessionStorage.getItem("auth_token");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			auth_token,
			refresh_token,
		});

		if (auth_token !== null && auth_token !== "") {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	timeShit() {
		let date_ = "2021-07-31T11:00:00.000Z";
		console.log(date_);
		let months__ = moment(date_).diff(moment(), "months", true);
		let months = months__.toFixed(0);
		//
		//
		let days_ = moment(date_).diff(moment(), "days", true);

		let days_fix = days_.toFixed(0);

		let months_ = moment(date_).diff(moment(), "months", true);
		let months_fix = months_.toFixed(0) * 30;
		let days = days_fix - 30;
		//
		//
		let from_ = moment(date_).diff(moment(), "hours", true);
		// console.log(from_);
		let diff = moment("11:00:00").diff(moment(), "days", true);
		console.log(diff);
		let hours_ = from_.toFixed(0) - diff.toFixed(0) * 24;
		// console.log(hours_);
		let hours = hours_ > 0 ? hours_ : 0;
		// moment("2021 07 31 13:00:00")
		//
		//
		//
		//
		//
		var openingDate = moment().hour(13).minute(0).second(0);
		var fromNow = openingDate.fromNow();
		var gg = moment().toISOString();
		let from_c = moment(openingDate).diff(moment(), "hours", true);

		if (from_c < 0) {
			from_c = 1 * from_c;
		}
		let hourF = moment().hour(from_c).format("HH");
		console.log("fromNow", hourF);
	}

	logOut = async () => {
		await sessionStorage.clear();
		await this.props.history.push("/");
	};

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		const defaultOptions2 = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		if (!this.state.validated) {
			return <Redirect to={"/dashboard/"} />;
		} else if (this.state.authenticating) {
			return (
				<div
					style={{
						flex: 1,

						backgroundColor: APP_COLOURS.WHITE,
						width: screenWidth,
						height: screenHeight,
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
							}}
						/>
						<Text>Authenticating...</Text>
						{/* <Lottie
							options={defaultOptions}
							height={400}
							width={400}
						/> */}
					</View>
				</div>
			);
		} else if (false) {
			return <HomeTESTPAGE />;
		} else
			return (
				<div
					style={{
						overflow: "-moz-hidden-unscrollable",
						flex: 1,
						width: this.state.screenWidth,
						height: this.state.screenHeight,
					}}>
					{/* <div
						style={{
							height: '100%',
							width: '100%',
							position: 'fixed',
							bottom: 0,
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						<Image
							source={bg}
							style={{
								width: '100%',
								height: '100%',
								resizeMode: 'cover',
							}}
						/>
					</div> */}

					{/* <Fade> */}
					<View
						style={{
							flex: 1,
							marginLeft: "auto",
							marginRight: "auto",
							flexDirection: "row",
						}}>
						<View
							onMouseEnter={() => this.setState({ hover: true })}
							onMouseLeave={() => this.setState({ hover: false })}
							style={{
								// width: this.state.screenWidth * 0.2,
								height: this.state.screenHeight,
								width: this.state.hover ? 220 : 80,
								// backgroundColor: 'red',
								alignItems: "center",
								padding: 15,
								borderRightWidth: 0.5,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										hover: !this.state.hover,
									})
								}>
								<Image
									source={logo}
									style={{
										width: this.state.hover ? 180 : 60,
										height: 39,
										resizeMode: "contain",
										marginBottom: 25,
										marginTop: 5,
									}}
								/>
							</TouchableOpacity>

							<View
								style={{
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
									// display:  'flex' : 'none',
								}}>
								{this.state.hover ? (
									<TouchableOpacity
										style={{
											width: "100%",
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											minHeight: 70,
											marginBottom: 10,
											// backgroundColor:
											// 	this.state.currentPage == d.page
											// 		? APP_COLOURS.WHITE
											// 		: APP_COLOURS.OFFWHITE,
											flexDirection: "row",
											// borderWidth:
											// 	this.state.currentPage == d.page
											// 		? 0.3
											// 		: 0,
											borderColor: "#C5C5C5",
										}}
										onPress={() => {
											// this.logOut();
											this.setState({
												currentPage: "profile",
											});
										}}>
										<Image
											source={female}
											style={{
												width: 30,
												height: 30,
												borderRadius: 25,
												resizeMode: "contain",
												backgroundColor: "#FFF",
												borderWidth: 0.3,
												borderColor: "#C5C5C5",
												shadowColor: "#ECF4FF",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.44,
												shadowRadius: 10.32,

												elevation: 16,
											}}
										/>
										<View
											style={{
												padding: 5,
												borderRadius: 10,
												alignItems: "center",
												flexDirection: "column",
												flex: 1,
												// alignItems: 'flex-end',
												alignItems: "flex-start",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												{this.state.user_data.name}{" "}
												{this.state.user_data.last_name}
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													opacity: 0.8,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												Admin
											</Text>
										</View>
									</TouchableOpacity>
								) : (
									<View
										style={{
											width: "100%",
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											minHeight: 70,
											marginBottom: 10,
											flexDirection: "row",
											borderColor: "#C5C5C5",
										}}>
										<Image
											source={female}
											style={{
												width: 30,
												height: 30,
												borderRadius: 25,
												resizeMode: "contain",
												backgroundColor: "#FFF",
												borderWidth: 0.3,
												borderColor: "#C5C5C5",
												shadowColor: "#ECF4FF",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.44,
												shadowRadius: 10.32,

												elevation: 16,
											}}
										/>
									</View>
								)}
							</View>

							{this.state.pages.map((d, i) => (
								<View
									key={i}
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										// display: this.state.hover
										// 	? 'flex'
										// 	: 'none',
									}}>
									<TouchableOpacity
										// onPress={() =>
										// 	this.props.history.push(
										// 		'/dashboard/Location',
										// 	)
										// }
										style={{
											width: "100%",
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											minHeight: 45,
											marginBottom: 10,
											backgroundColor:
												this.state.currentPage == d.page
													? APP_COLOURS.WHITE
													: APP_COLOURS.OFFWHITE,
											flexDirection: "row",
											borderWidth:
												this.state.currentPage == d.page ? 0.3 : 0,
											borderColor: "#C5C5C5",
											// shadowColor: '#ECF4FF',
											// shadowOffset: {
											// 	width: 0,
											// 	height: 8,
											// },
											// shadowOpacity: 0.44,
											// shadowRadius: 10.32,

											// elevation: 16,
										}}
										onPress={() =>
											this.setState({
												currentPage: d.page,
											})
										}>
										<View
											style={{
												width: 5,
												borderRadius: 4,
												height: "100%",
												backgroundColor:
													this.state.currentPage == d.page
														? APP_COLOURS.RED
														: APP_COLOURS.WHITE,
												marginRight: 12,
											}}
										/>
										<View
											style={{
												padding: 5,
												borderRadius: 10,
												backgroundColor:
													this.state.currentPage == d.page
														? APP_COLOURS.WHITE
														: APP_COLOURS.OFFWHITE,
												// justifyContent: 'center',
												alignItems: "center",
												display: this.state.hover ? "flex" : "none",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													// opacity: 0.8,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												{d.title}
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							))}
						</View>

						<View
							style={{
								flex: 1,
								backgroundColor: "#E8E8E8",
								height: this.state.screenHeight,
								width: this.state.hover
									? this.state.screenWidth - 220
									: this.state.screenWidth - 80,
							}}>
							{/* <ScrollView> */}
							{this.state.currentPage === "profile" ? (
								<Profile logOut={this.logOut.bind(this)} />
							) : (
								<View />
							)}
							{this.state.currentPage === "books" ? (
								<Books logOut={this.logOut.bind(this)} />
							) : (
								<View />
							)}
							{this.state.currentPage === "usermanagement" ? (
								<UserManagement
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{this.state.currentPage === "store" ? (
								<Store
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}

							{/* </ScrollView> */}
						</View>
					</View>
					{/* </Fade> */}

					{/* <View
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							width: 220,
							position: 'absolute',
							right: 10,
							top: 10,
							backgroundColor: APP_COLOURS.WHITE,
							borderRadius: 15,
							borderWidth: 0.3,
							borderColor: '#C5C5C5',
							shadowColor: '#ECF4FF',
							shadowOffset: {
								width: 0,
								height: 8,
							},
							shadowOpacity: 0.44,
							shadowRadius: 10.32,

							elevation: 16,
						}}>
						<TouchableOpacity
							style={{
								width: '100%',
								height: 'auto',
								alignItems: 'center',
								flexDirection: 'row',
								padding: 5,
							}}
							onPress={() => {
								// this.logOut();
								this.setState({
									currentPage: 'profile',
								});
							}}>
							<Image
								source={logo}
								style={{
									width: 45,
									height: 45,
									borderRadius: 25,
									resizeMode: 'contain',
									backgroundColor: '#FFF',
									borderWidth: 0.3,
									borderColor: '#C5C5C5',
									shadowColor: '#ECF4FF',
									shadowOffset: {
										width: 0,
										height: 8,
									},
									shadowOpacity: 0.44,
									shadowRadius: 10.32,

									elevation: 16,
								}}
							/>
							<View
								style={{
									padding: 5,
									borderRadius: 10,
									alignItems: 'center',
									flexDirection: 'column',
									flex: 1,
									// alignItems: 'flex-end',
									alignItems: 'flex-start',
								}}>
								<Text
									style={{
										fontFamily: 'Avenir',
										fontSize: 14,
										fontWeight: '600',
										color: APP_COLOURS.TEXTCOLOR,
									}}>
									{this.state.user_data.name}{' '}
									{this.state.user_data.last_name}
								</Text>
								<Text
									style={{
										fontFamily: 'Avenir',
										fontSize: 10,
										opacity: 0.8,
										color: APP_COLOURS.TEXTCOLOR,
									}}>
									Admin
								</Text>
							</View>
						</TouchableOpacity>
					</View> */}
				</div>
			);
	}
}

export default index;
