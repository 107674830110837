import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { View, Text, Image, TouchableOpacity, TextInput } from 'react-native';
import Lottie from 'react-lottie';
import moment from 'moment';
import Fade from 'react-reveal/Fade';

import Font from 'react-font';

import { APP_COLOURS } from '../APP_VARS';

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: {
				uuid: '....',
				email: '...',
				name: '... ',
				last_name: '...',
				flight_school: '...',
				phone_number: '....',
				is_email_verfied: '1',
				user_type: '2',
				is_delete: '0',
				user_status: '1',
				device_token_id: '',
				device_type: '',
				dob: '...',
				profile_pic: '',
				sex: '',
				user_name: '',
				fax: '',
				address: '',
				zipcode: '0',
				user_id: '0',
				created: '2021-06-11 12:13:34',
				updated: '2021-09-09 20:23:23',
			},
			email: '',
			auth_token: '',
			refresh_token: '',
			screenWidth: 0,
			screenHeight: 0,
			authenticating: true,
			validated: true,
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem('user_data');
		let email = await sessionStorage.getItem('email');
		let auth_token = await sessionStorage.getItem('auth_token');
		let refresh_token = await sessionStorage.getItem('refresh_token');

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			auth_token,
			refresh_token,
		});

		if (auth_token !== null && auth_token !== '') {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			this.props.history.push('/');
			sessionStorage.clear();
		}
	};

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	logOut = async () => {
		await this.props.logOut();
	};

	render() {
		return (
			<div
				style={{
					overflow: '-moz-hidden-unscrollable',

					flex: 1,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					padding: 20,
				}}>
				<View
					style={{
						// alignItems: 'center',
						// justifyContent: 'center',
						width: '100%',
						borderBottomWidth: 0.3,
						borderBottomColor: '#C5C5C5',
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: 'Avenir',
							fontSize: 25,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: '700',
						}}>
						Profile
					</Text>
				</View>
				<Fade>
					<View
						style={{
							flex: 1,
							// alignItems: 'center',
							// padding: 10,
							// justifyContent: 'space-between',
							// maxWidth: 500,
							marginLeft: 'auto',
							marginRight: 'auto',
							flexDirection: 'row',
							flexWrap: 'wrap',
						}}>
						<View
							style={{
								flex: 1,
								padding: 20,
								minWidth: 300,
							}}>
							<Text
								style={{
									fontFamily: 'Avenir',
									fontSize: 16,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: '500',
									marginBottom: 15,
								}}>
								Account details
							</Text>
							<View
								style={{
									width: 300,
									height: 60,
									// alignItems: 'center',
									marginTop: 5,
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 50,
										paddingLeft: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										// letterSpacing: 0.26,
										// borderWidth: 0.3,
										// borderColor: '#000',
									}}
									placeholderTextColor={'#000'}
									autoCapitalize='none'
									clearButtonMode='while-editing'
									autoCompleteType='email'
									editable={false}
									autoCorrect={false}
									keyboardType='email-address'
									textContentType='emailAddress'
									placeholder={`Email address`}
									// defaultValue={this.state.user_data.email}
									value={this.state.user_data.email}
									onChangeText={(text) => {
										let validated = this.validateEmail(
											text,
										);

										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												email: text,
											},
										}));
									}}
								/>
								{/* LINE UNDER INPUT */}
								<View
									style={{
										position: 'absolute',
										width: '90%',
										bottom: 10,
										borderBottomColor: this.state.emailValid
											? APP_COLOURS.GREEN
											: APP_COLOURS.PLACEHOLDER,
										borderBottomWidth: 0.5,
									}}
								/>
								{/* {this.state.emailValid ? (
									<Image
										source={tick}
										style={{
											width: 16,
											height: 16,
											resizeMode: 'contain',
											position: 'absolute',
											left: 20,
											top: 19,
										}}
									/>
								) : null} */}
							</View>
							<View
								style={{
									width: 300,
									height: 60,
									marginTop: 5,
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 50,
										paddingLeft: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										// letterSpacing: 0.26,
										// borderWidth: 0.3,
										// borderColor: '#000',
									}}
									placeholderTextColor={'#000'}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='name'
									autoCorrect={false}
									keyboardType='default'
									textContentType='givenName'
									placeholder={`First name`}
									value={this.state.user_data.name}
									onChangeText={(text) => {
										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												name: text,
											},
										}));
									}}
								/>
								{/* LINE UNDER INPUT */}
								<View
									style={{
										position: 'absolute',
										width: '90%',
										bottom: 10,
										borderBottomColor: this.state.emailValid
											? APP_COLOURS.GREEN
											: APP_COLOURS.PLACEHOLDER,
										borderBottomWidth: 0.5,
									}}
								/>
							</View>
							<View
								style={{
									width: 300,
									height: 60,
									// alignItems: 'center',
									marginTop: 5,
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 50,
										paddingLeft: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										// letterSpacing: 0.26,
										// borderWidth: 0.3,
										// borderColor: '#000',
									}}
									placeholderTextColor={'#000'}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='name'
									autoCorrect={false}
									keyboardType='default'
									textContentType='familyName'
									placeholder={`First name`}
									value={this.state.user_data.last_name}
									onChangeText={(text) => {
										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												last_name: text,
											},
										}));
									}}
								/>
								{/* LINE UNDER INPUT */}
								<View
									style={{
										position: 'absolute',
										width: '90%',
										bottom: 10,
										borderBottomColor: this.state.emailValid
											? APP_COLOURS.GREEN
											: APP_COLOURS.PLACEHOLDER,
										borderBottomWidth: 0.5,
									}}
								/>
								{/* {this.state.emailValid ? (
									<Image
										source={tick}
										style={{
											width: 16,
											height: 16,
											resizeMode: 'contain',
											position: 'absolute',
											left: 20,
											top: 19,
										}}
									/>
								) : null} */}
							</View>

							<View
								style={{
									width: 300,
									height: 60,
									marginTop: 5,
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 50,
										paddingLeft: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										// letterSpacing: 0.26,
										// borderWidth: 0.3,
										// borderColor: '#000',
									}}
									placeholderTextColor={'#000'}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									autoCompleteType='tel'
									autoCorrect={false}
									keyboardType='phone-pad'
									textContentType='telephoneNumber'
									placeholder={`Phonenumber`}
									value={this.state.user_data.phone_number}
									onChangeText={(text) => {
										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												phone_number: text,
											},
										}));
									}}
								/>
								{/* LINE UNDER INPUT */}
								<View
									style={{
										position: 'absolute',
										width: '90%',
										bottom: 10,
										borderBottomColor: this.state.emailValid
											? APP_COLOURS.GREEN
											: APP_COLOURS.PLACEHOLDER,
										borderBottomWidth: 0.5,
									}}
								/>
							</View>
						</View>
						<View
							style={{
								flex: 1,

								padding: 20,
								minWidth: 300,
							}}>
							<Text
								style={{
									fontFamily: 'Avenir',
									fontSize: 16,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: '500',
									marginBottom: 15,
								}}>
								Settings
							</Text>
							<View
								style={{
									width: 300,
									height: 60,
									// alignItems: 'center',
									marginTop: 5,
								}}>
								<TextInput
									style={{
										width: '100%',
										height: 50,
										paddingLeft: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: '#000',
										fontWeight: '200',
										fontFamily: 'Avenir',
										// letterSpacing: 0.26,
										// borderWidth: 0.3,
										// borderColor: '#000',
									}}
									placeholderTextColor={'#000'}
									autoCapitalize='none'
									clearButtonMode='while-editing'
									autoCompleteType='email'
									editable={false}
									autoCorrect={false}
									keyboardType='email-address'
									textContentType='emailAddress'
									placeholder={`Email address`}
									// defaultValue={this.state.user_data.email}
									value={this.state.user_data.email}
									onChangeText={(text) => {
										let validated = this.validateEmail(
											text,
										);

										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												email: text,
											},
										}));
									}}
								/>
								{/* LINE UNDER INPUT */}
								<View
									style={{
										position: 'absolute',
										width: '90%',
										bottom: 10,
										borderBottomColor: this.state.emailValid
											? APP_COLOURS.GREEN
											: APP_COLOURS.PLACEHOLDER,
										borderBottomWidth: 0.5,
									}}
								/>
								{/* {this.state.emailValid ? (
									<Image
										source={tick}
										style={{
											width: 16,
											height: 16,
											resizeMode: 'contain',
											position: 'absolute',
											left: 20,
											top: 19,
										}}
									/>
								) : null} */}
							</View>
						</View>
					</View>
				</Fade>

				<View
					style={{
						position: 'absolute',
						bottom: 20,
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Fade>
						<div
							style={{
								height: '100%',
								width: '100%',
								maxWidth: 300,
								alignItems: 'center',
								justifyContent: 'center',
							}}>
							<TouchableOpacity onPress={() => this.logOut()}>
								<View
									style={{
										backgroundColor: APP_COLOURS.TEXTINPUT,
										flex: 1,
										padding: 9,
										justifyContent: 'center',
										alignItems: 'center',
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
										}}>
										Log out
									</Text>
								</View>
							</TouchableOpacity>
						</div>
					</Fade>
				</View>
			</div>
		);
	}
}

export default index;
