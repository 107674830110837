import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

import tick from "./../../../assets/images/tick.png";
import bin from "./../../../assets/images/delete.png";
import close from "../../../assets/images/close.png";

import Books from "./Books";
import Bundles from "./Bundles";
import { api_get_all_books } from "../../Api";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default class Store extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			filterTabs: ["Books", "Bundles", "Purchases"],
			selectedFilterTab: "Books",
			books: [],
			bundles: [],
			filterByName: "",

			createBundle: false,
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let auth_token = await sessionStorage.getItem("auth_token");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			auth_token,
			refresh_token,
		});

		if (auth_token !== null && auth_token !== "") {
			this.getAllPurchases();
		} else {
			this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	componentWillUnmount() {}

	getAllPurchases = async () => {
		let url = api_get_all_books;

		await this.setState({
			loading: true,
			books: [],
			bundles: [],
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: "follow",
		};

		// console.log(requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								books: responseData.books,
								bundles: responseData.bundles,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let books = this.state.books
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.book_name} ${d_.ebook_id} ${d_.category}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})

			.sort((a, b) => b.book_name < a.book_name);

		let bundles = this.state.bundles
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.bundle_name} ${d_.ebook_id} ${d_.category}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})

			.sort((a, b) => b.book_name < a.book_name);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						width: "100%",
						borderBottomWidth: 0.3,
						borderBottomColor: "#C5C5C5",
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 18,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: "700",
						}}>
						Book Store
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						marginTop: 16,
					}}>
					<View
						style={{
							// width: 220,
							paddingLeft: 0,
							// height: this.state.screenHeight - 130,
							paddingRight: 8,
							flexDirection: "row",
							alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								width: "100%",
								// marginBottom: 10,
							}}>
							<ScrollView horizontal>
								<View
									style={{
										flexDirection: "row",
									}}>
									{this.state.filterTabs.map((d, i) => (
										<TouchableOpacity
											key={i}
											style={{
												width: 150,
												height: "auto",
												padding: 7,
												alignItems: "center",
												borderRadius: 8,
												marginRight: 10,
												height: "auto",
												marginBottom: 1,
												backgroundColor:
													this.state
														.selectedFilterTab == d
														? APP_COLOURS.WHITE
														: APP_COLOURS.OFFWHITE,
												flexDirection: "row",
												borderWidth:
													this.state
														.selectedFilterTab == d
														? 0.3
														: 0,
												borderColor: "#C5C5C5",
											}}
											onPress={() => {
												this.setState({
													selectedFilterTab: d,
													changesMade: false,
													filterByName: "",
												});
												this.filterByName.clear();
											}}>
											<View
												style={{
													width: 5,
													borderRadius: 4,
													height: "100%",
													backgroundColor:
														this.state
															.selectedFilterTab ==
														d
															? APP_COLOURS.RED
															: APP_COLOURS.WHITE,
													marginRight: 12,
												}}
											/>

											<View
												style={{
													flex: 1,
													flexWrap: "wrap",
													padding: 4,
													borderRadius: 10,
													backgroundColor:
														this.state
															.selectedFilterTab ==
														d
															? APP_COLOURS.WHITE
															: APP_COLOURS.OFFWHITE,
												}}>
												<Text
													numberOfLines={2}
													style={{
														fontFamily: "Avenir",
														fontSize:
															16 +
															this.state.fontSize,
														// opacity: 0.8,
														flex: 1,
														flexWrap: "wrap",
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													{d}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</View>
								{/* <Text
									numberOfLines={2}
									style={{
										fontFamily: 'Avenir',
										fontSize: 16 + this.state.fontSize,
										opacity: 0.8,
										flexWrap: 'wrap',
										color: APP_COLOURS.TEXTCOLOR,
										marginTop: 20,
									}}>
									Other
								</Text> */}
								{/* <TouchableOpacity
									style={{
										width: '100%',
										height: 'auto',
										padding: 7,
										alignItems: 'center',
										borderRadius: 10,
										height: 'auto',
										marginTop: 10,
										width: '100%',
										backgroundColor: APP_COLOURS.WHITE,
										flexDirection: 'row',
										borderWidth: 0.3,
										borderColor: '#C5C5C5',
									}}
									onPress={() => this.newBook()}>
							
									<View
										style={{
											flex: 1,
											flexWrap: 'wrap',
											padding: 4,
											borderRadius: 10,
											backgroundColor: APP_COLOURS.WHITE,
										}}>
										<Text
											numberOfLines={2}
											style={{
												fontFamily: 'Avenir',
												fontSize:
													16 + this.state.fontSize,
												// opacity: 0.8,
												flex: 1,
												flexWrap: 'wrap',
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											+ Create new gr
										</Text>
									</View>
								</TouchableOpacity> */}
							</ScrollView>
						</View>

						<View
							style={{
								flexDirection: "row",
								flex: 1,
								alignItems: "center",

								paddingTop: 5,
								marginBottom: 5,
							}}>
							<TouchableOpacity
								style={{
									width: 130,
									height: "auto",
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									marginRight: 10,
									height: "auto",
									backgroundColor: APP_COLOURS.WHITE,
									flexDirection: "row",
									borderWidth: 0.3,
									borderColor: "#C5C5C5",

									display:
										this.state.selectedFilterTab ===
										"Bundles"
											? "flex"
											: "none",
								}}
								onPress={() =>
									this.setState({
										createBundle: true,
									})
								}>
								<View
									style={{
										flex: 1,
										flexWrap: "wrap",
										padding: 4,
										borderRadius: 10,
										backgroundColor: APP_COLOURS.WHITE,
									}}>
									<Text
										numberOfLines={2}
										style={{
											fontFamily: "Avenir",
											fontSize: 16 + this.state.fontSize,
											// opacity: 0.8,
											flex: 1,
											flexWrap: "wrap",
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										+ Create bundle
									</Text>
								</View>
							</TouchableOpacity>
							<TextInput
								ref={(ref) => {
									this.filterByName = ref;
								}}
								style={{
									flex: 1,
									padding: 10,
									fontSize: 16,
									borderRadius: 9,
									color: APP_COLOURS.TEXTINPUT,
									fontWeight: "200",
									fontFamily: "Avenir",
									borderWidth: 0.3,
									borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
									marginRight: 5,
								}}
								placeholderTextColor={"#797979"}
								multiline
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`Search ${this.state.selectedFilterTab} `}
								onChangeText={(text) => {
									this.setState({
										filterByName: text,
									});
								}}
							/>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							height: this.props.screenHeight - 100,
							// padding: 8,
							paddingTop: 8,
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
							// backgroundColor: "red",
						}}>
						{this.state.selectedFilterTab === "Books" ? (
							<Books {...this.props} books={books} />
						) : (
							<View />
						)}

						{this.state.selectedFilterTab === "Bundles" ? (
							<Bundles
								{...this.props}
								bundles={bundles}
								books={this.state.books}
							/>
						) : (
							<View />
						)}
					</View>
				</View>

				{this.state.createBundle ? this.createBundle() : <View />}
			</div>
		);
	}

	createBundle = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: "100%",
							maxWidth: 500,
							height: "auto",
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										createBundle: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "auto",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 17 + this.state.fontSize,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "600",
									marginBottom: 20,
								}}>
								Create new bundle
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "400",
									marginBottom: 20,
									width: "100%",
								}}>
								Please note, correctly formatted CSV/Excel
								spreadsheets are neccessary for the assessment
								to be uploaded and displayed correctly.
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}
