import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from "./Authed/Home";
import Login from "./Unauthed/Login";

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route
						path='/dashboard/login'
						render={(props) => <Login {...props} />}
					/>
					<Route
						path='/dashboard/Home'
						render={(props) => <Home {...props} />}
					/>
					{/* 
					
					<Route
						path='/dashboard/rsvp'
						render={(props) => <Rsvp {...props} />}
					/>
					
					<Route


						exact
						path='/dashboard/'
						render={(props) => <UnAuthedLayout {...props} />}
					/>

					<Route
						path='/dashboard/rsvp'
						render={(props) => <Rsvp {...props} />}
					/>
					
					<Route
						path='/dashboard/OurStory'
						render={(props) => <OurStory {...props} />}
					/>
					<Route
						path='/dashboard/Location'
						render={(props) => <Location {...props} />}
					/>
					<Route
						path='/dashboard/Accomodation'
						render={(props) => <Accomodation {...props} />}
					/>
					<Route
						path='/dashboard/GiftRegistry'
						render={(props) => <GiftRegistry {...props} />}
					/>
					<Route
						path='/dashboard/GuestBook'
						render={(props) => <GuestBook {...props} />}
					/>
					<Route
						path='/dashboard/KeyPeople'
						render={(props) => <KeyPeople {...props} />}
					/>

					<Route
						path='/dashboard/Accomodation'
						render={(props) => <Accomodation {...props} />}
					/>

					<Route
						path='/dashboard/PhotoGallery'
						render={(props) => <PhotoGallery {...props} />}
					/>

					<Route
						path='/dashboard/FacebookLive'
						render={(props) => <FacebookLive {...props} />}
					/> */}

					<Redirect from='*' to='/dashboard/login' />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default index;
