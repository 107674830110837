import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";
import Fade from "react-reveal/Fade";

import tick from "./../../../assets/images/tick.png";
import bin from "./../../../assets/images/delete.png";
import close from "../../../assets/images/close.png";

import { api_getallusers } from "../../Api";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			userGroups: [
				{
					title: "Student",
					group_id: 1,
				},
				// {
				// 	title: 'Flight schools',
				// 	group_id: 3,
				// },
				{
					title: "Admin",
					group_id: 2,
				},
			],
			createUser: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Student",
			users: [
				// {
				// 	uuid: '0eab6853-275b-4e3c-ab55-d080a4e0c1e7',
				// 	email: 'monique@avexair.com',
				// 	password:
				// 		'$2y$10$R4gwowaSclTdvXdV4I5dCe/hUM9GmQJGyilDNnk82LLYOUjMQCgBO',
				// 	name: 'Monique',
				// 	last_name: 'Bethwaite',
				// 	flight_school: 'null',
				// 	phone_number: '0812502054',
				// 	email_verfication_link:
				// 		'Tmxoa3hlS1JIeUhoN1p3QUhnbXlCa2N5ckhMUzVYMy9qSU5UUExlbnZhOHdyc0VpUTI5V3dHUXNqSTB2ckhrZWpTY0p6b2Frb0pMUmtJd3FnOFhBalIvT2RaRUpBaEYwZjFtaTFnSXVjMmV1RnhwcDJqMjZYZWF6NWRIMmVCUlpHeUtFNGFYRlYvdmtCUGhVRllrQnZZWk8rOU1PZU1ZLzRNNjVNZ2EwMFUvYlBkdUZLL3p1OEJKdUViYnNaZzJEWU5PZ3c2RVR0cWJOWGpmaUtnaE1IZDJlcEtqZDBVMEJkNjNMblBVb1c1RT0=',
				// 	is_email_verfied: '1',
				// 	password_verfication_link: '',
				// 	is_passsword_verfied: '0',
				// 	user_type: '2',
				// 	is_delete: '0',
				// 	user_status: '1',
				// 	device_token_id: '',
				// 	device_type: '',
				// 	dob: '',
				// 	profile_pic: '',
				// 	sex: '',
				// 	user_name: '',
				// 	fax: '',
				// 	address: '',
				// 	zipcode: '0',
				// 	user_id: '0',
				// 	created: '2021-10-19 11:47:19',
				// 	updated: '2021-10-19 11:48:47',
				// },
			],
			filterByName: "",
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let auth_token = await sessionStorage.getItem("auth_token");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			auth_token,
			refresh_token,
		});

		if (auth_token !== null && auth_token !== "") {
			this.getAllUsers();
		} else {
			this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	componentWillUnmount() {}

	getAllUsers = async () => {
		let url = api_getallusers;

		await this.setState({
			loading: true,
			users: [],
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.auth_token}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData.data);
							this.setState({
								users: responseData.data,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let users = this.state.users
			.filter((d_) => d_.user_type == this.state.selectedUserGroup)
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.last_name} ${d_.name}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})

			.sort((a, b) => b.last_name < a.last_name);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						width: "100%",
						borderBottomWidth: 0.3,
						borderBottomColor: "#C5C5C5",
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 18,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: "700",
						}}>
						User management
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						marginTop: 16,
					}}>
					<Text
						numberOfLines={2}
						style={{
							fontFamily: "Avenir",
							fontSize: 16 + this.state.fontSize,
							opacity: 0.8,
							flexWrap: "wrap",
							color: APP_COLOURS.TEXTCOLOR,
							marginBottom: 10,
						}}>
						User groups
					</Text>
					<View
						style={{
							// width: 220,
							paddingLeft: 0,
							// height: this.state.screenHeight - 130,
							paddingRight: 8,
							flexDirection: "row",
							alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								width: "100%",
								// marginBottom: 10,
							}}>
							<ScrollView horizontal>
								<View
									style={{
										flexDirection: "row",
									}}>
									{this.state.userGroups.map((d, i) => (
										<TouchableOpacity
											key={i}
											style={{
												width: 150,
												height: "auto",
												padding: 7,
												alignItems: "center",
												borderRadius: 8,
												marginRight: 10,
												height: "auto",
												marginBottom: 1,
												backgroundColor:
													this.state
														.selectedUserGroup ==
													d.group_id
														? APP_COLOURS.WHITE
														: APP_COLOURS.OFFWHITE,
												flexDirection: "row",
												borderWidth:
													this.state
														.selectedUserGroup ==
													d.group_id
														? 0.3
														: 0,
												borderColor: "#C5C5C5",
											}}
											onPress={() => {
												this.setState({
													selectedUserGroup:
														d.group_id,
													selecteedUserGroupName:
														d.title,
													filterByName: "",
												});

												this.filterByName.clear();
											}}>
											<View
												style={{
													width: 5,
													borderRadius: 4,
													height: "100%",
													backgroundColor:
														this.state
															.selectedUserGroup ==
														d.group_id
															? APP_COLOURS.RED
															: APP_COLOURS.WHITE,
													marginRight: 12,
												}}
											/>
											<View
												style={{
													flex: 1,
													flexWrap: "wrap",
													padding: 4,
													borderRadius: 10,
													backgroundColor:
														this.state
															.selectedUserGroup ==
														d.group_id
															? APP_COLOURS.WHITE
															: APP_COLOURS.OFFWHITE,
													// justifyContent: 'center',
													// alignItems: 'center',
												}}>
												<Text
													numberOfLines={2}
													style={{
														fontFamily: "Avenir",
														fontSize:
															16 +
															this.state.fontSize,
														// opacity: 0.8,
														flex: 1,
														flexWrap: "wrap",
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													{d.title}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</View>
								{/* <Text
									numberOfLines={2}
									style={{
										fontFamily: 'Avenir',
										fontSize: 16 + this.state.fontSize,
										opacity: 0.8,
										flexWrap: 'wrap',
										color: APP_COLOURS.TEXTCOLOR,
										marginTop: 20,
									}}>
									Other
								</Text> */}
								{/* <TouchableOpacity
									style={{
										width: '100%',
										height: 'auto',
										padding: 7,
										alignItems: 'center',
										borderRadius: 10,
										height: 'auto',
										marginTop: 10,
										width: '100%',
										backgroundColor: APP_COLOURS.WHITE,
										flexDirection: 'row',
										borderWidth: 0.3,
										borderColor: '#C5C5C5',
									}}
									onPress={() => this.newBook()}>
							
									<View
										style={{
											flex: 1,
											flexWrap: 'wrap',
											padding: 4,
											borderRadius: 10,
											backgroundColor: APP_COLOURS.WHITE,
										}}>
										<Text
											numberOfLines={2}
											style={{
												fontFamily: 'Avenir',
												fontSize:
													16 + this.state.fontSize,
												// opacity: 0.8,
												flex: 1,
												flexWrap: 'wrap',
												color: APP_COLOURS.TEXTCOLOR,
											}}>
											+ Create new gr
										</Text>
									</View>
								</TouchableOpacity> */}
							</ScrollView>
						</View>

						<View
							style={{
								flexDirection: "row",
								flex: 1,
								alignItems: "center",

								paddingTop: 5,
								marginBottom: 5,
							}}>
							<TouchableOpacity
								style={{
									width: 130,
									height: "auto",
									padding: 7,
									alignItems: "center",
									borderRadius: 10,
									marginRight: 10,
									height: "auto",
									backgroundColor: APP_COLOURS.WHITE,
									flexDirection: "row",
									borderWidth: 0.3,
									borderColor: "#C5C5C5",
								}}
								onPress={() =>
									this.setState({
										createUser: true,
									})
								}>
								<View
									style={{
										flex: 1,
										flexWrap: "wrap",
										padding: 4,
										borderRadius: 10,
										backgroundColor: APP_COLOURS.WHITE,
									}}>
									<Text
										numberOfLines={2}
										style={{
											fontFamily: "Avenir",
											fontSize: 16 + this.state.fontSize,
											// opacity: 0.8,
											flex: 1,
											flexWrap: "wrap",
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										+ Create{" "}
										{this.state.selecteedUserGroupName}
									</Text>
								</View>
							</TouchableOpacity>
							<TextInput
								ref={(ref) => {
									this.filterByName = ref;
								}}
								style={{
									flex: 1,
									padding: 10,
									fontSize: 16,
									borderRadius: 9,
									color: APP_COLOURS.TEXTINPUT,
									fontWeight: "200",
									fontFamily: "Avenir",
									borderWidth: 0.3,
									borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
									marginRight: 5,
								}}
								placeholderTextColor={"#797979"}
								multiline
								autoCapitalize='sentences'
								clearButtonMode='while-editing'
								autoCompleteType='off'
								editable={true}
								autoCorrect={false}
								keyboardType='default'
								textContentType='none'
								placeholder={`Search ${this.state.selecteedUserGroupName} `}
								onChangeText={(text) => {
									this.setState({
										filterByName: text,
									});
								}}
							/>
						</View>
					</View>
					<View
						style={{
							flex: 1,
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
						}}>
						<View
							style={{
								flex: 1,
								height: this.props.screenHeight - 400,
								// padding: 8,
								paddingTop: 8,
							}}>
							<ScrollView>
								{users.map((d, i) => (
									<View
										key={i}
										style={{
											flex: 1,
											opacity:
												this.state.viewMore === ""
													? 1
													: this.state.viewMore ===
													  d.id
													? 1
													: 0.5,
										}}>
										<TouchableOpacity
											onPress={() => {
												this.setState({
													selectedUserID: d.uuid,
												});
												console.log(d.uuid);
											}}
											// onPress={() =>
											// 	this.setState({
											// 		viewMore:
											// 			this.state
											// 				.viewMore ===
											// 			d.id
											// 				? ""
											// 				: d.id,
											// 	})
											// }
										>
											<View
												key={i}
												onMouseEnter={() =>
													this.setState({
														hover: i,
													})
												}
												onMouseLeave={() =>
													this.setState({
														hover: -1,
													})
												}
												style={{
													flex: 1,
													opacity:
														this.state.hover === i
															? 1
															: 0.9,
													flexDirection: "row",
													height: "auto",
													padding: 7,
													alignItems: "center",
													borderRadius: 8,
													marginRight: 10,
													height: "auto",
													marginBottom: 2,
													backgroundColor:
														this.state.hover == i
															? APP_COLOURS.WHITE
															: APP_COLOURS.OFFWHITE,
													flexDirection: "row",
													// borderWidth:
													// 	this.state.hover ==
													// 	i
													// 		? 0.3
													// 		: 0,
													borderColor: "#C5C5C5",
												}}>
												<View
													style={{
														width: 5,
														borderRadius: 4,
														height: 30,
														backgroundColor:
															this.state
																.selectedUserID ===
															d.uuid
																? APP_COLOURS.RED
																: "transparent",
														marginRight: 12,
													}}
												/>
												<Text
													style={{
														width: col1,
													}}>
													<Text
														style={{
															fontWeight: "600",
														}}>
														{d.last_name}
													</Text>
													, {d.name}
												</Text>
												<View
													style={{
														width: col1,
														flexDirection: "row",
													}}>
													{d.is_email_verfied > 0 ? (
														<Image
															source={tick}
															style={{
																width: 15,
																height: 15,
																// borderRadius: 25,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
														/>
													) : (
														<View />
													)}
													<Text
														style={{
															width: col1,
														}}>
														{d.email}
													</Text>
												</View>

												<View
													style={{
														width: col2,
													}}>
													<Text
														style={{
															fontSize: 7,
														}}>
														created at:
													</Text>
													<Text
														style={{
															fontSize: 7,
														}}>
														{d.created}
													</Text>
												</View>

												{/* <TouchableOpacity
														onPress={() =>
															this.removeItem(
																d.id,
															)
														}
														style={{
															flexDirection:
																"row",
															justifyContent:
																"center",
															width: 40,
															alignItems:
																"center",
															padding: 5,
															// borderLeftWidth: 0.5,
															// borderLeftColor: "#FFF",
															marginLeft: 5,
														}}>
														<Image
															source={bin}
															style={{
																width: 22,
																height: 22,
																// borderRadius: 25,
																resizeMode:
																	"contain",
																marginRight: 5,
															}}
														/>
													</TouchableOpacity> */}
											</View>
										</TouchableOpacity>
									</View>
								))}
							</ScrollView>
						</View>
					</View>
				</View>
				{this.state.createUser ? this.createUser() : <View />}
			</div>
		);
	}

	createUser = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							width: "100%",
							maxWidth: 500,
							height: "auto",
							backgroundColor: "white",
							borderRadius: 20,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										createUser: false,
									})
								}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>
						<View
							style={{
								width: "100%",
								padding: 20,
								height: "auto",
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 17 + this.state.fontSize,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "600",
									marginBottom: 20,
								}}>
								Create new bundle
							</Text>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16 + this.state.fontSize,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "400",
									marginBottom: 20,
									width: "100%",
								}}>
								Please note, correctly formatted CSV/Excel
								spreadsheets are neccessary for the assessment
								to be uploaded and displayed correctly.
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}
