import React, { Component } from 'react';
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	ScrollView,
	Modal,
	Dimensions,
	ActivityIndicator,
} from 'react-native';
import Font from 'react-font';
import { APP_COLOURS } from '../APP_VARS';
import close from './../../assets/images/close.png';
import tetriceLogo from './../../assets/images/logoTetrice.png';
import MathFormula from './MathFormula';

const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;

class DevicePreviewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookContent: [],
			loading: true,
			selectedLine: this.props.selectedLine,
			fontSize: this.props.fontSize,
		};
	}

	componentDidMount() {
		this.setState({
			bookContent: this.props.bookContent,
		});
		setTimeout(() => {
			this.setState({
				loading: false,
			});
		}, 4000);
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					justifyContent: 'center',
					alignItems: 'center',
					backgroundColor: 'rgba(52, 52, 52, 0.8)',
				}}>
				<View
					style={{
						width: screenWidth,
						// maxWidth: 650,
						height: screenHeight,
						marginTop: 50,
						// maxHeight: 1000,
						// backgroundColor: 'white',
						borderRadius: 20,
						justifyContent: 'center',
						alignItems: 'center',
						padding: 10,
					}}>
					<View
						style={{
							width: '100%',
							padding: 20,
							height: '100%',
						}}>
						<View
							style={{
								flex: 1,
								marginLeft: 10,
								alignItems: 'center',
								padding: 5,
								zIndex: 99,
							}}>
							<View
								style={{
									borderRadius: 30,
									backgroundColor: '#000',
									shadowColor: '#000',
									shadowOffset: {
										width: 0,
										height: 8,
									},
									shadowOpacity: 0.46,
									shadowRadius: 11.14,

									elevation: 17,
									height: '100%',
									maxHeight: 700,
									width: 320,
									paddingTop: 30,
									paddingBottom: 30,
									paddingLeft: 5,
									paddingRight: 5,
								}}>
								<View
									style={{
										position: 'absolute',
										zIndex: 99,
										top: -10,
										left: -10,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.props.closePreview()
										}>
										<Image
											source={close}
											style={{
												width: 35,
												height: 35,
												resizeMode: 'contain',
											}}
										/>
									</TouchableOpacity>
								</View>
								<Text
									style={{
										position: 'absolute',
										textAlign: 'center',
										top: 7,
										color: '#FFF',
										// left: 137,
										width: '100%',
										zIndex: 99,
										fontSize: 9,
									}}>
									Preview
								</Text>
								<View
									style={{
										width: '100%',
										textAlign: 'center',
										position: 'absolute',
										bottom: 5,
										color: '#FFF',
										zIndex: 99,
									}}>
									<Font
										family='Poppins'
										style={{ fontSize: 4 }}
										weight={900}>
										tetrice
									</Font>
								</View>

								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											backgroundColor: '#222B45',
											justifyContent: 'center',
											alignItems: 'center',
										}}>
										<Image
											source={tetriceLogo}
											style={{
												width: 125,
												height: 100,
												resizeMode: 'contain',
											}}
										/>
										<ActivityIndicator color='#FFF' />
									</View>
								) : null}

								<ScrollView
									style={{
										backgroundColor: '#FFF',
										borderRadius: 12,
										padding: 15,
										paddingBottom: 100,
										display: this.state.loading
											? 'none'
											: 'flex',
									}}>
									{this.state.bookContent.map((obj, i) => (
										<View
											key={i}
											style={{
												width: '100%',
												height: 'auto',
												zIndex: 999,
												paddingRight: 10,
												borderColor:
													this.state.selectedLine ==
													obj.uuid
														? APP_COLOURS.BLUE
														: 'transparent',
												borderStyle: 'dashed',
												borderWidth: this.props
													.linkerActive
													? 1
													: 0,
												borderRadius: 10,
											}}>
											<TouchableOpacity
												onPress={async () => {
													let obvJect = encodeURIComponent(
														JSON.stringify({
															line_num:
																obj.line_num,
															chapter:
																obj.chapter,
															uuid: obj.uuid,
														}),
													);
													console.log(obvJect);
													if (
														this.props.linkerActive
													) {
														await this.setState({
															selectedLine:
																obj.uuid,
														});
														await this.props.setBookLink(
															obvJect,
														);
														await this.props.closePreview();
													}
												}}>
												<View
													style={{
														flex: 1,
														flexDirection: 'row',
														maxWidth: 400,
													}}>
													{obj.object == 'H1' ? (
														<p
															style={{
																flex: 1,
																fontSize:
																	21 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,
																fontWeight:
																	'800',
																flexWrap:
																	'wrap',
																marginTop: 10,
																marginBottom: 10,
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}
													{obj.object == 'H2' ? (
														<p
															style={{
																fontSize:
																	19 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,
																fontWeight:
																	'600',
																flexWrap:
																	'wrap',
																marginTop: 10,
																marginBottom: 10,
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}
													{obj.object == 'H3' ? (
														<p
															style={{
																fontSize:
																	17 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,
																fontWeight:
																	'500',
																flexWrap:
																	'wrap',
																marginTop: 8,
																marginBottom: 8,
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}
													{obj.object == 'IMG' ? (
														<Image
															source={{
																uri: `data:image/png;base64,${obj.data}`,
															}}
															style={{
																width: '100%',
																height: 300,
																resizeMode:
																	'contain',
																marginTop: 8,
																marginBottom: 8,
															}}
														/>
													) : null}
													{obj.object == 'P' ? (
														<p
															style={{
																fontSize:
																	15 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,

																textAlign:
																	'justify',
																color: '#000',
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}

													{obj.object == 'MATH' ? (
														<MathFormula
															formula={obj.data}
														/>
													) : null}

													{obj.object == 'IMG_TAG' ? (
														<p
															style={{
																fontSize:
																	16 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,

																flexWrap:
																	'wrap',
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}
													{obj.object == 'UL' ? (
														<p
															style={{
																fontSize:
																	16 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,
																// fontWeight: '300',
																flexWrap:
																	'wrap',
																textAlign:
																	'justify',
																marginTop: 2,
																marginBottom: 2,
																paddingLeft: 10,
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}
													{obj.object == 'OL' ? (
														<p
															style={{
																fontSize:
																	16 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,
																// fontWeight: '300',
																flexWrap:
																	'wrap',
																textAlign:
																	'justify',
																marginTop: 2,
																marginBottom: 2,
																paddingLeft: 10,
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}
													{obj.object == 'LIST_P' ? (
														<p
															style={{
																fontSize:
																	16 +
																	this.state
																		.fontSize,
																color:
																	APP_COLOURS.TEXTCOLOR,
																// fontWeight: '300',
																flexWrap:
																	'wrap',
																textAlign:
																	'justify',
																marginTop: 2,
																marginBottom: 2,
																paddingLeft: 10,
															}}
															dangerouslySetInnerHTML={{
																__html:
																	obj.data,
															}}></p>
													) : null}

													{obj.object ==
													'OPEN_BOOK' ? (
														<View
															style={{
																backgroundColor:
																	APP_COLOURS.RED,
																borderRadius: 5,
																justifyContent:
																	'center',
																alignItems:
																	'center',
																padding: 5,
																height: 30,
															}}>
															<Text
																style={{
																	// fontFamily: 'Avenir',
																	fontSize:
																		16 +
																		this
																			.state
																			.fontSize,
																	color:
																		APP_COLOURS.WHITE,
																	fontWeight:
																		'600',
																	flexWrap:
																		'wrap',
																	flex: 1,
																}}>
																Open Book
																Assessment
															</Text>
														</View>
													) : null}

													{obj.object ==
													'CLOSED_BOOK' ? (
														<View
															style={{
																backgroundColor:
																	APP_COLOURS.RED,
																borderRadius: 5,
																justifyContent:
																	'center',
																alignItems:
																	'center',
																padding: 5,
																height: 30,
															}}>
															<Text
																style={{
																	// fontFamily: 'Avenir',
																	fontSize:
																		16 +
																		this
																			.state
																			.fontSize,
																	color:
																		APP_COLOURS.WHITE,
																	fontWeight:
																		'600',
																	flexWrap:
																		'wrap',
																}}>
																Closed Book
																Assessment
															</Text>
														</View>
													) : null}
												</View>
											</TouchableOpacity>
										</View>
									))}
								</ScrollView>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}
}

export default DevicePreviewer;
