import React, { Component } from 'react';

import { CSVReader } from 'react-papaparse';

export default class CSVUploader extends Component {
	handleOnDrop = (data) => {
		console.log('handleOnDrop---------------------------');
		console.log(data);
		console.log('---------------------------');
	};

	handleOnError = (err, file, inputElem, reason) => {
		console.log(err);
	};

	handleOnRemoveFile = (data) => {
		console.log('handleOnRemoveFile---------------------------');
		console.log(data);
		console.log('---------------------------');
	};

	render() {
		return (
			<CSVReader
				onDrop={this.handleOnDrop}
				onError={this.handleOnError}
				noDrag
				addRemoveButton
				onRemoveFile={this.handleOnRemoveFile}>
				<span>Click here to upload</span>
			</CSVReader>
		);
	}
}
