import React, { Component } from 'react';
import { View, Text } from 'react-native';

// import RichEditorExample from './Draft';
import ExampleEditor2 from './DraftJsSimple';

class HomeTESTPAGE extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View style={{}}>
				{/* <RichEditorExample /> */}
				<ExampleEditor2 />
			</View>
		);
	}
}

export default HomeTESTPAGE;
