import React, { Component } from 'react';
import { View, Text } from 'react-native';

import './Input.css';

class PSDUploader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: [],
			borderHighlight: false,
		};
	}

	async onDrop(e) {
		e.stopPropagation();
		e.preventDefault();

		// if (e.dataTransfer) {
		// 	e.dataTransfer.setData('text/plain', sendData);
		// 	e.dataTransfer.effectAllowed = 'copyMove';
		// }

		let PSD = require('psd');

		console.log(e);

		if (e.type === 'application/x-photoshop') {
			const pngBase64 = await PSD.fromEvent(e).then(function (psd) {
				// psd.image.toPng()
				let b64 = psd.image.toBase64();
				let replace_ = 'data:image/png;base64,';
				let cleanString = b64.replace(replace_, '');
				return cleanString;
			});

			await console.log(pngBase64);
			await this.props.base64String(pngBase64);
		} else {
			alert('Only PSD files accepted');
		}
	}

	render() {
		return (
			<View
				style={{
					zIndex: 99,
					borderColor: this.state.borderHighlight ? 'blue' : 'white',
					borderWidth: 1,
					borderRadius: 10,
					borderStyle: 'dotted',
				}}>
				<View
					style={{
						opacity: 0,
						zIndex: 99,
					}}>
					<input
						type='file'
						// onDragEnter={() =>
						// 	this.setState({
						// 		borderHighlight: true,
						// 	})
						// }
						// onDragLeave={() =>
						// 	this.setState({
						// 		borderHighlight: false,
						// 	})
						// }
						// onChange={(event) => this.onDrop(event.target.files[0])}
						onChange={(event) => this.onDrop(event)}
						// onChange={this.onDrop)}
					/>
				</View>
			</View>
		);
	}
}

export default PSDUploader;
