export const APP_COLOURS = {
	PRIMARY: '',
	SECONDARY: '#748969',
	BACKGROUND: '#F8FAFF',
	BG2: '#191D24',
	TEXTCOLOR: '#141D31',
	PLACEHOLDER: '#797979',
	TEXTINPUT: '#0720D5',
	DESELECTEDCOLOR: '#EEF2FC',
	OFFWHITE: '#F4F6FA',
	WHITE: '#FFF',
	GREEN: '#07D55D',
	ORANGE: '#FFD76E',
	RED: '#FF5F5F',
	BLUE: '#0720D5',
};
