import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import {
	Editor,
	EditorState,
	RichUtils,
	convertToRaw,
	ContentState,
	convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';
import './Draft.css';

class DrafJS extends React.Component {
	constructor(props) {
		super(props);
		this.state = { editorState: EditorState.createEmpty() };
		this.onChange = (editorState) => {
			let html = stateToHTML(editorState.getCurrentContent());
			this.setState({
				editorState,
				editorContentHtml: html,
			});

			const rawContentState = convertToRaw(
				editorState.getCurrentContent(),
			);

			const markup = draftToHtml(rawContentState, {
				trigger: '#',
				separator: ' ',
			});

			// console.log(markup);

			this.props.exportHTML(markup);
		};
		this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
	}

	clearEditorField = () => {
		const editorState = EditorState.push(
			this.state.editorState,
			ContentState.createFromText(''),
		);
		this.setState({ editorState });
	};

	handleKeyCommand(command) {
		const { editorState } = this.state;
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			this.onChange(newState);
			return true;
		}
		return false;
	}

	_toggleInlineStyle(inlineStyle) {
		this.onChange(
			RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle),
		);
	}

	componentDidMount() {
		if (typeof this.props.initialHtml != 'undefined') {
			const html = this.props.initialHtml;
			const blocksFromHTML = convertFromHTML(html);
			const content = ContentState.createFromBlockArray(blocksFromHTML);
			this.setState({
				editorState: EditorState.createWithContent(content),
			});
		}
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
					padding: 10,
					marginBottom: 5,
					marginTop: 0,

					borderRadius: 8,

					borderWidth: 0.3,
					// paddingBottom: 200,
				}}>
				<div
					className='editor-container'
					style={{ minHeight: 100, overflowY: 'scroll' }}>
					<InlineStyleControls
						editorState={this.state.editorState}
						onToggle={this.toggleInlineStyle}
					/>
					<Editor
						handleKeyCommand={this.handleKeyCommand.bind(this)}
						editorState={this.state.editorState}
						onChange={this.onChange}
						style={{
							minHeight: 100,
							overflowY: 'scroll',
						}}
						customStyleMap={{
							SUBSCRIPT: {
								fontSize: '0.6em',
								verticalAlign: 'sub',
							},
							SUPERSCRIPT: {
								fontSize: '0.6em',
								verticalAlign: 'super',
							},
						}}
					/>
				</div>
				{/* <h4>Editor content as HTML</h4>
				<pre>{this.state.editorContentHtml}</pre> */}
			</View>
		);
	}
}

//
//
// INLINE STYLE BUTTONS
var INLINE_STYLES = [
	{ label: 'Bold', style: 'BOLD' },
	{ label: 'Italic', style: 'ITALIC' },
	{ label: 'Underline', style: 'UNDERLINE' },
	{ label: 'Monospace', style: 'CODE' },
	{ label: 'SUPERSCRIPT', style: 'SUPERSCRIPT' },
	{ label: 'SUBSCRIPT', style: 'SUBSCRIPT' },
];

const InlineStyleControls = (props) => {
	const currentStyle = props.editorState.getCurrentInlineStyle();

	return (
		<div className='RichEditor-controls'>
			{INLINE_STYLES.map((type) => (
				<StyleButton
					key={type.label}
					active={currentStyle.has(type.style)}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			))}
		</div>
	);
};

class StyleButton extends React.Component {
	constructor() {
		super();
		this.onToggle = (e) => {
			e.preventDefault();
			this.props.onToggle(this.props.style);
		};
	}

	render() {
		let className = 'RichEditor-styleButton';
		if (this.props.active) {
			className += ' RichEditor-activeButton';
		}

		return (
			<span className={className} onMouseDown={this.onToggle}>
				{this.props.label}
			</span>
		);
	}
}
//
//
//

export default DrafJS;
