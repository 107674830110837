import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";

import logo from "./../../assets/images/logo.png";
import logo_tetrice from "./../../assets/images/logo_tetrice.png";
// import bg from "./../../assets/images/bg.png";
import tick from "./../../assets/images/tick_red.png";
import { APP_COLOURS } from "../APP_VARS";
import otp_sent from "../../assets/images/otp_sent.png";
import otp_valid from "../../assets/images/otp_valid.png";
import { api_login } from "../Api";
import * as animationData from "../../assets/lottie/lf30_editor_t3bgbljb.json";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			emailInput: "",
			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,

			otpPinSent: false,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	loginButton = async () => {
		this.setState({
			loading: true,
			otpPinSent: true,
			errorLogin: false,
		});
		await sessionStorage.setItem("email", this.state.emailInput);
		// await sessionStorage.setItem('password', this.state.password);
	};

	loginButton2 = async () => {
		this.setState({
			loading: true,
			otpPinSent: true,
			errorLogin: false,
		});

		await sessionStorage.setItem("email", this.state.emailInput);
		// await sessionStorage.setItem('password', this.state.password);
		// this.props.history.push("/dashboard/");
		// setTimeout(() => this.checkRSVP(), 3000);
		// this.loginFunc();
		this.loginFuncWithOTP();
	};

	getOTP = async () => {
		let url = api_login;
		let password = this.state.password;
		let email = this.state.emailInput;

		var raw = JSON.stringify({
			password: password,
			email: email,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
			},
			body: raw,
			redirect: "follow",
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);
							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify(responseData.data),
							);
							let auth_token = await sessionStorage.setItem(
								"auth_token",
								responseData.auth_token,
							);
							let refresh_token = await sessionStorage.setItem(
								"refresh_token",
								responseData.refresh_token,
							);

							await this.props.history.push("/dashboard/Home");
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								checkingRVP: false,
								password: "",
								emailInput: "",
								emailValid: false,
							});
						});
				} else {
					alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						emailInput: "",
						emailValid: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					emailInput: "",
					emailValid: false,
				});
			});
	};

	loginFuncWithOTP = async () => {
		let url = api_login;
		let password = this.state.password;
		let email = this.state.emailInput;

		var raw = JSON.stringify({
			password: password,
			email: email,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
			},
			body: raw,
			redirect: "follow",
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);
							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify(responseData.data),
							);
							let auth_token = await sessionStorage.setItem(
								"auth_token",
								responseData.auth_token,
							);
							let refresh_token = await sessionStorage.setItem(
								"refresh_token",
								responseData.refresh_token,
							);

							await this.props.history.push("/dashboard/Home");
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								password: "",
								emailInput: "",
								emailValid: false,
								otpPinSent: false,
							});
						});
				} else {
					alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						emailInput: "",
						emailValid: false,
						otpPinSent: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					emailInput: "",
					emailValid: false,
					otpPinSent: false,
				});
			});
	};

	componentDidMount() {
		console.log("componentDidMount");
		let auth_token = sessionStorage.getItem("auth_token");

		console.log("Approved User", auth_token);
		if (auth_token !== null) {
			this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		return (
			<div
				style={{
					backgroundColor: APP_COLOURS.OFFWHITE,
					flex: 1,
				}}>
				<View
					style={{
						flex: 1,
						alignItems: "center",
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						justifyContent: "center",
						position: "absolute",
						zIndex: 99,
						top: 0,
					}}>
					<ScrollView
						contentContainerStyle={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								height: "auto",
								alignItems: "center",
								padding: 10,
								borderRadius: 15,
								backgroundColor: this.state.otpPinSent
									? "#191D24"
									: "#FFF",
								// borderWidth: 0.3,
								// borderColor: "#C5C5C5",
								shadowColor: "#C5C5C5",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,

								elevation: 16,
							}}>
							{this.state.otpPinSent ? (
								<Image
									source={logo_tetrice}
									style={{
										width: 180,
										height: 100,
										resizeMode: "contain",
									}}
								/>
							) : (
								<Image
									source={logo}
									style={{
										width: 180,
										height: 100,
										resizeMode: "contain",
									}}
								/>
							)}

							{this.state.errorLogin ? (
								<View
									style={{
										width: 300,
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "red",
										}}>
										We are unable to verify your profile, please check
										your email again to confirm this is correct.
									</Text>
								</View>
							) : null}

							{!this.state.otpPinSent ? (
								<View
									style={{
										flex: 1,
										width: "100%",
										alignItems: "center",
										// justifyContent: 'center',
									}}>
									<View
										style={{
											flex: 1,
											width: "100%",
											alignItems: "center",
											// justifyContent: 'center',
										}}>
										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<View
													style={{
														width: "100%",
														height: 52,
														marginRight: "auto",
														marginLeft: "auto",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 20,
													}}>
													<TextInput
														style={{
															width: "100%",
															height: 52,
															paddingLeft: 40,
															marginBottom: 5,
															marginTop: 0,
															fontSize: 16,
															borderRadius: 20,
															// backgroundColor:
															// 	APP_COLOURS.WHITE,
															textAlign: "left",
															color: "#000",
															fontWeight: "200",
															fontFamily: "Avenir",
															letterSpacing: 0.26,
															borderWidth: 0.3,
															borderColor: APP_COLOURS.BLUE,
														}}
														placeholderTextColor={"#000"}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='username'
														autoCorrect={false}
														autoFocus={true}
														keyboardType='email-address'
														textContentType='emailAddress'
														placeholder={`Email address`}
														value={this.state.emailInput}
														onChangeText={(text) => {
															let validated =
																this.validateEmail(text);
															this.setState({
																emailInput: text,
															});
														}}
													/>
													{/* LINE UNDER INPUT */}
													{/* <View
												style={{
													position: 'absolute',
													width: '90%',
													bottom: 10,
													borderBottomColor: this
														.state.emailValid
														? APP_COLOURS.GREEN
														: APP_COLOURS.PLACEHOLDER,
													borderBottomWidth: 0.5,
												}}
											/> */}
													{this.state.emailValid ? (
														<Image
															source={tick}
															style={{
																width: 16,
																height: 16,
																resizeMode: "contain",
																position: "absolute",
																left: 15,
																top: 16,
															}}
														/>
													) : null}
												</View>
											</div>
										</Fade>

										{/* {this.state.emailValid ? ( */}
										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
													// display: this.state.emailValid
													// 	? 'flex'
													// 	: 'none',
												}}>
												<View
													style={{
														width: "100%",
														height: 52,
														marginRight: "auto",
														marginLeft: "auto",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 20,
													}}>
													<TextInput
														style={{
															width: "100%",
															height: 52,
															paddingLeft: 40,
															marginBottom: 5,
															marginTop: 0,
															fontSize: 16,
															borderRadius: 20,
															textAlign: "left",
															color: "#000",
															fontWeight: "200",
															fontFamily: "Avenir",
															letterSpacing: 0.26,
															borderWidth: 0.3,
															borderColor: this.state.otpPinSent
																? "#0676ED"
																: APP_COLOURS.BLUE,
														}}
														placeholderTextColor={"#797979"}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='password'
														secureTextEntry={true}
														textContentType='password'
														autoCorrect={false}
														placeholder={`Password`}
														value={this.state.password}
														// defaultValue={
														// 	this.state.password
														// }
														onChangeText={(text) => {
															this.setState({
																password: text,
															});
														}}
														onSubmitEditing={() =>
															this.loginButton()
														}
													/>
													{/* LINE UNDER INPUT */}
													{/* <View
													style={{
														position: 'absolute',
														width: '90%',
														bottom: 10,
														borderBottomColor:
															this.state
																.weddingCodeInput !==
															this.state
																.weddingCode
																? APP_COLOURS.PLACEHOLDER
																: APP_COLOURS.GREEN,
														borderBottomWidth: 0.5,
													}}
												/> */}

													{this.state.password.length <
													5 ? null : (
														<Image
															source={tick}
															style={{
																width: 16,
																height: 16,
																resizeMode: "contain",
																position: "absolute",
																left: 15,
																top: 16,
															}}
														/>
													)}
												</View>
											</div>
										</Fade>
										{/* ) : null} */}
									</View>

									<View
										style={{
											width: "100%",
											height: 60,
											// alignItems: "center",
											maxWidth: 350,
											marginTop: 30,
										}}>
										{this.state.emailValid &&
										this.state.password !== "" ? (
											<Fade>
												<div
													style={{
														height: "100%",
														width: "100%",
														alignItems: "center",
														justifyContent: "center",
													}}>
													<TouchableOpacity
														onPress={() => this.loginButton()}>
														<View
															style={{
																backgroundColor: this.state
																	.otpPinSent
																	? "#0676ED"
																	: APP_COLOURS.BLUE,
																flex: 1,
																padding: 13,
																justifyContent: "center",
																alignItems: "center",
																borderRadius: 10,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.WHITE,
																}}>
																Login
															</Text>
														</View>
													</TouchableOpacity>
												</div>
											</Fade>
										) : null}
									</View>
								</View>
							) : (
								<View
									style={{
										marginTop: 30,
										marginBottom: 5,
									}}>
									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
												// display: this.state.emailValid
												// 	? 'flex'
												// 	: 'none',
											}}>
											<View>
												{this.state.otpPin == "" ? (
													<Image
														source={otp_sent}
														style={{
															// width: 180,
															height: 100,
															resizeMode: "contain",
															marginBottom: 14,
														}}
													/>
												) : (
													<Image
														source={otp_valid}
														style={{
															// width: 180,
															height: 100,
															resizeMode: "contain",
															marginBottom: 14,
														}}
													/>
												)}
											</View>

											<View>
												<Text
													style={{
														fontSize: 20,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														marginBottom: 4,
													}}>
													One-Time PIN
												</Text>
												<Text
													style={{
														textAlign: "center",
														color: "#FFF",
														fontWeight: "300",
														fontFamily: "Avenir",
														fontSize: 14,
														marginBottom: 10,
													}}>
													we've emailed you a one-time pin.
												</Text>
											</View>
											<View
												style={{
													width: "100%",
													// height: 52,
													// marginRight: 'auto',
													// marginLeft: 'auto',
													// alignItems: 'center',
													// maxWidth: 350,
													marginTop: 20,
													flexDirection: "row",
													justifyContent: "space-between",
												}}>
												<TextInput
													ref={(ref) => {
														this.digit1 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,
														fontSize: 18,

														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													autoCorrect={false}
													autoFocus
													// placeholder={`_`}
													value={this.state.digit1}
													// defaultValue={
													// 	this.state.password
													// }
													maxLength={1}
													onChangeText={(text) => {
														// this.setState({
														// 	digit1: text,
														// });
														this.digit2.focus();
													}}
												/>

												<TextInput
													ref={(ref) => {
														this.digit2 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,

														fontSize: 18,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													// clearButtonMode='while-editing'
													autoCorrect={false}
													// placeholder={`_`}
													value={this.state.digit2}
													maxLength={1}
													onChangeText={(text) => {
														// this.setState({
														// 	digit2: text,
														// });
														this.digit3.focus();
													}}
												/>
												<TextInput
													ref={(ref) => {
														this.digit3 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,

														fontSize: 18,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													// clearButtonMode='while-editing'
													autoCorrect={false}
													placeholder={``}
													value={this.state.digit3}
													// maxLength={1}
													onChangeText={(text) => {
														// this.setState({
														// 	digit3: text,
														// });
														this.digit4.focus();
													}}
												/>
												<TextInput
													ref={(ref) => {
														this.digit4 = ref;
													}}
													style={{
														width: 46,
														height: 52,
														borderRadius: 9,

														fontSize: 18,
														textAlign: "center",
														color: "#FFF",
														fontWeight: "800",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLUE,
													}}
													placeholderTextColor={"#FFF"}
													// clearButtonMode='while-editing'
													autoCorrect={false}
													// placeholder={``}
													value={this.state.digit4}
													maxLength={1}
													onChangeText={(text) => {
														let otpPin = `${this.digit1.value}${this.digit2.value}${this.digit3.value}${this.digit4.value}`;

														// alert(otpPin);

														if (otpPin.length < 4) {
															otpPin = "";
														}

														this.setState({
															otpPin: otpPin,
														});
													}}
												/>
											</View>

											<View
												style={{
													width: "100%",
													height: 60,
													// alignItems: "center",
													// maxWidth: 350,
													marginTop: 30,
												}}>
												{this.state.otpPin !== "" ? (
													<Fade>
														<div
															style={{
																height: "100%",
																width: "100%",
																alignItems: "center",
																justifyContent: "center",
															}}>
															<TouchableOpacity
																onPress={() =>
																	this.loginButton2()
																}>
																<View
																	style={{
																		backgroundColor: this
																			.state.otpPinSent
																			? "#0676ED"
																			: APP_COLOURS.BLUE,
																		flex: 1,
																		padding: 13,
																		justifyContent: "center",
																		alignItems: "center",
																		borderRadius: 10,
																	}}>
																	<Text
																		style={{
																			color: APP_COLOURS.WHITE,
																		}}>
																		LOGIN
																	</Text>
																</View>
															</TouchableOpacity>
														</div>
													</Fade>
												) : null}
											</View>
										</div>
									</Fade>
								</View>
							)}
						</View>
					</ScrollView>
				</View>
			</div>
		);

		if (false) {
			return (
				<div
					style={{
						flex: 1,

						backgroundColor: APP_COLOURS.WHITE,
						width: screenWidth,
						height: screenHeight,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							position: "absolute",
						}}>
						<Lottie
							options={defaultOptions}
							height={screenHeight}
							width={screenWidth}
						/>
					</View>
					<View
						style={{
							flex: 1,
							height: screenHeight,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
							}}
						/>
						<Text>Checking RSVP...</Text>
					</View>
				</div>
			);
		} else if (false) {
			return (
				<div
					style={{
						flex: 1,

						backgroundColor: APP_COLOURS.WHITE,
						width: screenWidth,
						height: screenHeight,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							position: "absolute",
						}}>
						<Lottie
							options={defaultOptions}
							height={screenHeight}
							width={screenWidth}
						/>
					</View>
					<View
						style={{
							flex: 1,
							height: screenHeight,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<Image
							source={logo}
							style={{
								width: 100,
								height: 100,
								resizeMode: "contain",
							}}
						/>
						<Text
							style={{
								color: "red",
							}}>
							We've recieved your RSVP as 'Not Attending'
						</Text>
						<Text
							style={{
								color: "red",
							}}>
							If this is an error, please call Deidre/Clinton or email
							deidrecheia@gmail.com
						</Text>
					</View>
				</div>
			);
		} else
			return (
				<div
					style={{
						backgroundColor: APP_COLOURS.BACKGROUND,
						flex: 1,
					}}>
					<div
						style={{
							height: "100%",
							width: "100%",
							position: "fixed",
							bottom: 0,
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						{/* <Image
							source={bg}
							style={{
								width: '100%',
								height: '100%',
								resizeMode: 'cover',
							}}
						/> */}
					</div>

					<View
						style={{
							flex: 1,
							alignItems: "center",
							// marginTop:
							// height: this.state.screenHeight,
							padding: 10,
							justifyContent: "center",
						}}>
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								alignItems: "center",
								padding: 10,
								borderRadius: 15,
								backgroundColor: "#FFF",
								borderWidth: 0.3,
								borderColor: "#C5C5C5",
								shadowColor: "#ECF4FF",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,

								elevation: 16,
							}}>
							<Image
								source={logo}
								style={{
									width: 150,
									height: 100,
									resizeMode: "contain",
								}}
							/>

							{this.state.errorLogin ? (
								<View
									style={{
										width: 300,
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "red",
										}}>
										We are unable to verify your profile, please check
										your email again to confirm this is correct.
									</Text>
								</View>
							) : null}

							<View
								style={{
									flex: 1,
									width: "100%",
									alignItems: "center",
									// justifyContent: 'center',
								}}>
								<Fade>
									<div
										style={{
											height: "100%",
											width: "100%",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												width: "100%",
												height: 60,
												marginRight: "auto",
												marginLeft: "auto",
												alignItems: "center",
												maxWidth: 350,
												marginTop: 20,
											}}>
											<TextInput
												style={{
													width: "100%",
													height: 60,
													paddingLeft: 10,
													marginBottom: 5,
													marginTop: 0,
													fontSize: 16,
													borderRadius: 8,
													backgroundColor: APP_COLOURS.WHITE,
													textAlign: "center",
													color: "#000",
													fontWeight: "200",
													fontFamily: "Avenir",
													letterSpacing: 0.26,
													borderWidth: 0.3,
													borderColor: "#000",
												}}
												placeholderTextColor={"#000"}
												autoCapitalize='none'
												clearButtonMode='while-editing'
												autoCompleteType='email'
												autoCorrect={false}
												autoFocus
												keyboardType='email-address'
												textContentType='emailAddress'
												placeholder={`Email address`}
												value={this.state.emailInput}
												onChangeText={(text) => {
													let validated = this.validateEmail(text);
													this.setState({
														emailInput: text,
													});
												}}
											/>
											{/* LINE UNDER INPUT */}
											{/* <View
												style={{
													position: 'absolute',
													width: '90%',
													bottom: 10,
													borderBottomColor: this
														.state.emailValid
														? APP_COLOURS.GREEN
														: APP_COLOURS.PLACEHOLDER,
													borderBottomWidth: 0.5,
												}}
											/> */}
											{this.state.emailValid ? (
												<Image
													source={tick}
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														position: "absolute",
														left: 20,
														top: 19,
													}}
												/>
											) : null}
										</View>
									</div>
								</Fade>

								{this.state.emailValid ? (
									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
											}}>
											<View
												style={{
													width: "100%",
													height: 60,
													marginRight: "auto",
													marginLeft: "auto",
													alignItems: "center",
													maxWidth: 350,
													marginTop: 20,
												}}>
												<TextInput
													style={{
														width: "100%",
														height: 60,
														paddingLeft: 10,
														marginBottom: 5,
														marginTop: 0,
														fontSize: 16,
														borderRadius: 8,
														backgroundColor: APP_COLOURS.WHITE,
														textAlign: "center",
														color: "#000",
														fontWeight: "200",
														fontFamily: "Avenir",
														letterSpacing: 0.26,
													}}
													placeholderTextColor={"#000"}
													autoCapitalize='none'
													clearButtonMode='while-editing'
													autoCompleteType='password'
													secureTextEntry={true}
													textContentType='password'
													autoCorrect={false}
													placeholder={`Password`}
													value={this.state.password}
													// defaultValue={
													// 	this.state.password
													// }
													onChangeText={(text) => {
														this.setState({
															password: text,
														});
													}}
													onSubmitEditing={() =>
														this.loginButton()
													}
												/>
												{/* LINE UNDER INPUT */}
												{/* <View
													style={{
														position: 'absolute',
														width: '90%',
														bottom: 10,
														borderBottomColor:
															this.state
																.weddingCodeInput !==
															this.state
																.weddingCode
																? APP_COLOURS.PLACEHOLDER
																: APP_COLOURS.GREEN,
														borderBottomWidth: 0.5,
													}}
												/> */}

												{this.state.password.length < 5 ? null : (
													<Image
														source={tick}
														style={{
															width: 16,
															height: 16,
															resizeMode: "contain",
															position: "absolute",
															left: 20,
															top: 19,
														}}
													/>
												)}
											</View>
										</div>
									</Fade>
								) : null}
							</View>

							<View
								style={{
									width: "100%",
									height: 60,
									// alignItems: "center",
									maxWidth: 350,
									marginTop: 30,
								}}>
								{this.state.emailValid ? (
									<Fade>
										<div
											style={{
												height: "100%",
												width: "100%",
												alignItems: "center",
												justifyContent: "center",
											}}>
											<TouchableOpacity
												onPress={() => this.loginButton()}>
												<View
													style={{
														backgroundColor:
															APP_COLOURS.TEXTINPUT,
														flex: 1,
														padding: 13,
														justifyContent: "center",
														alignItems: "center",
														borderRadius: 10,
													}}>
													<Text
														style={{
															color: APP_COLOURS.WHITE,
														}}>
														Login
													</Text>
												</View>
											</TouchableOpacity>
										</div>
									</Fade>
								) : null}
							</View>
						</View>
					</View>

					{/* <View
					style={{
						width: "100%",
						height: 60,
						flexDirection: "row",
						justifyContent: "space-evenly",
					}}>
					<Text
						style={{
							color: PRIMARY,
						}}
						accessibilityRole='link'
						href={`/alternate`}>
						A universal link
					</Text>

					{this.state.pages.map((d, i) => (
						<View
							key={i}
							style={{
								flex: 1,
							}}>
							<TouchableOpacity
								onPress={() =>
									this.setState(
										{
											selectedPage:
												d,
										},
									)
								}>
								<View
									style={{
										width: "100%",
										height: 70,
										alignItems: "center",
										paddingTop: 15,
										backgroundColor:
											PRIMARY,
									}}>
									<Text
										style={{
											color: OFFWHITE, // OFFWHITE,
											fontWeight:
												this
													.state
													.selectedPage ==
												d
													? "900"
													: "400",
											fontSize: 13,
										}}>
										{
											d
										}
									</Text>
								</View>
							</TouchableOpacity>
						</View>
					))}
				</View> */}
				</div>
			);
	}
}

export default index;
